import { operatorGroups as apiOperatorGroups } from '@cooltra/auth-api';

import { getEnvVariable } from './environment';

export const operatorGroups =
  getEnvVariable('ENVIRONMENT') === 'production'
    ? apiOperatorGroups.prod
    : apiOperatorGroups.stage;

const { JOCKEY, JOCKEY_EXT, SWAPPER, SWAPPER_EXT, RECOLLECTOR, MECHANIC } =
  operatorGroups;

export const streetOperatorRoles = [
  JOCKEY.operatorGroupId,
  JOCKEY_EXT.operatorGroupId,
  SWAPPER.operatorGroupId,
  SWAPPER_EXT.operatorGroupId,
  RECOLLECTOR.operatorGroupId,
  MECHANIC.operatorGroupId,
];

import { defineMessages } from 'react-intl';

export default defineMessages({
  filters: {
    defaultMessage: 'Filters',
  },
  active: {
    defaultMessage: 'Active',
  },
});

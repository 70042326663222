import { useMyTakenVehicles } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import {
  ListItemErrorIcon,
  ListItemLink,
  MyTakenVehiclesCount,
} from '~/common';

import messages from './messages';

export const MyTakenVehiclesItem = () => {
  const { isLoading, data = [], isError } = useMyTakenVehicles();

  return (
    <li>
      <ListItemLink
        to="my-taken-vehicles"
        hasChevron
        data-testid="MY_TAKEN_VEHICLES_ITEM"
      >
        <div className="flex justify-between items-center gap-4">
          <span className="text-base font-semibold text-neutral-700">
            <FormattedMessage {...messages.myTakenVehicles} />
          </span>
          <span className="text-sm text-neutral-500 pl-2 truncate">
            {isError ? (
              <ListItemErrorIcon />
            ) : isLoading ? (
              <></>
            ) : (
              <MyTakenVehiclesCount totalTakenVehicles={data.length} />
            )}
          </span>
        </div>
      </ListItemLink>
    </li>
  );
};

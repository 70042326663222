import { prio } from './prio';
import { lessThanEight } from './less-than-eight';
import { lessThanFiveteen } from './less-than-fiveteen';
import { lessThanThirty } from './less-than-thirty';

export const felyxPresets = {
  prio,
  lessThanEight,
  lessThanFiveteen,
  lessThanThirty,
};

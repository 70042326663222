import { AxiosResponse } from 'axios';

import messages from './messages';

export const getErrorMessageForTakingVehicle = (
  errorResponse: AxiosResponse | undefined
): { defaultMessage: string; avoidTranslation?: boolean } => {
  switch (errorResponse?.status) {
    case 401:
      return messages.notAuthorizedToPerformTakeOnVehicle;
    case 422:
      return messages.cannotTake;
    default:
      return messages.somethingWentWrong;
  }
};

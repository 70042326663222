import { RentalListItem, useRentalsQuery } from '@cooltra/api';
import { List, TopLoadingBar } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Screen, TopNavigation, Error } from '~/common';

import { RentalCard } from '../RentalCard/RentalCard';

import messages from './messages';

export type LastRentalsProps = {
  onClose: () => void;
  vehicleId: string;
};

export const LastRentals = ({ onClose, vehicleId }: LastRentalsProps) => {
  const navigate = useNavigate();

  const onBack = () => navigate(`/vehicles/${vehicleId}`);

  const { isError, isLoading, data } = useRentalsQuery(
    { vehicleId, limit: 5, page: 1 },
    { enabled: !!vehicleId }
  );

  const rentals = data?.pages
    ? ([] as RentalListItem[]).concat.apply([], data.pages)
    : [];

  const renderContent = () => {
    if (isError) {
      return <Error />;
    }

    return (
      <div className="pb-16 overflow-x-hidden">
        <List
          className="gap-0"
          loadingRows={0}
          isLoading={isLoading}
          idProp="rentalId"
          data={rentals}
          renderRow={(rental) => <RentalCard rental={rental} />}
        />
      </div>
    );
  };

  return (
    <Screen
      data-testid="LAST_RENTALS_SCREEN"
      header={
        <>
          {isLoading && <TopLoadingBar />}
          <div className="bg-neutral-0 border-b border-neutral-100">
            <TopNavigation.Bar
              renderLeft={<TopNavigation.Back onClick={onBack} />}
              renderRight={<TopNavigation.Close onClick={onClose} />}
              title={
                <TopNavigation.Title>
                  <FormattedMessage {...messages.title} />
                </TopNavigation.Title>
              }
            />
          </div>
        </>
      }
      content={renderContent()}
    />
  );
};

import { HTMLAttributes } from 'react';
import { Vehicle } from '@cooltra/api';
import { classNames } from '@cooltra/utils';
import { chargePerLevelRanges } from '@cooltra/vehicle-utils';

export type BatteryIconProps = HTMLAttributes<HTMLSpanElement> &
  Pick<Vehicle, 'model'> & {
    charge: number;
  };

const mainPart = (11 / 12) * 100;
const tipPart = (1 / 12) * 100;

export const BatteryIcon = ({ charge, model }: BatteryIconProps) => {
  const unsignedCharge = Math.abs(charge);
  const chargePerLevelRange = chargePerLevelRanges[model];
  const mainBlockFill =
    unsignedCharge <= mainPart ? unsignedCharge / mainPart : 1;
  const tipFill =
    unsignedCharge > mainPart ? (unsignedCharge - mainPart) / tipPart : 0;

  let backgroundClassName;

  switch (true) {
    case charge <= chargePerLevelRange.low.max:
      backgroundClassName = 'bg-danger-400';
      break;
    case charge <= chargePerLevelRange.medium.max:
      backgroundClassName = 'bg-warning-600';
      break;
    case charge <= chargePerLevelRange.neutral.max:
      backgroundClassName = 'bg-neutral-alternative-800';
      break;
    default:
      backgroundClassName = 'bg-success-500';
      break;
  }

  return (
    <div className="h-full w-full bg-neutral-100 rounded-sm relative">
      <div
        className={classNames(
          'absolute left-0 h-full rounded-sm',
          backgroundClassName
        )}
        style={{ width: `${mainBlockFill * 100}%` }}
      />
      <div className="absolute h-1/2 w-1/12 right-0 translate-x-full">
        <div className="h-full bg-neutral-100 rounded-r-sm relative bottom-1/2 translate-y-full">
          <div
            className={classNames(
              'absolute w-full h-full left-0 rounded-r-sm',
              backgroundClassName
            )}
            style={{ width: `${tipFill * 100}%` }}
          />
        </div>
      </div>
    </div>
  );
};

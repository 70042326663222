import { CommentForm } from './CommentForm/CommentForm';
import { Comments } from './Comments/Comments';

export type TaskCommentsProps = {
  taskId: string;
};

export const TaskComments = ({ taskId }: TaskCommentsProps) => (
  <>
    <div className="border-t border-neutral-100 p-4 mb-20">
      <Comments taskId={taskId} />
    </div>
    <div className="border-t border-neutral-100 p-4 absolute bottom-0 inset-x-0 bg-neutral-0">
      <CommentForm taskId={taskId} />
    </div>
  </>
);

import {
  useTasksQuery,
  FetchTasksRequestParams,
  useTasksTotal,
} from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { FormattedMessage } from 'react-intl';

import { ListItemErrorIcon, ListItemLink, OpenTasksCount } from '~/common';
import { useRoleNames } from '~/hooks';

import messages from './messages';

export const MyGroupTasksItem = () => {
  const { data: claims } = useAuthClaimsQuery();

  const params: FetchTasksRequestParams = {
    open: 'true',
    operatorGroupId: claims?.roles,
  };
  const roleNames = useRoleNames();
  const hasAnyRole = !!roleNames.length;

  const { isLoading, isError } = useTasksQuery(params, {
    enabled: hasAnyRole,
  });

  const total = useTasksTotal(params);

  if (!hasAnyRole) {
    return <></>;
  }

  return (
    <li>
      <ListItemLink
        to="group-tasks"
        hasChevron
        data-testid="MY_GROUP_TASKS_ITEM"
      >
        <div className="flex justify-between items-center gap-4">
          <div>
            {roleNames.length === 1 ? (
              <span className="text-base font-semibold text-neutral-700">
                <FormattedMessage
                  {...messages.singleRoleLabel}
                  values={{ operatorGroupName: roleNames[0] }}
                />
              </span>
            ) : (
              <div className="flex flex-col gap-0.5">
                <span className="text-base font-semibold text-neutral-700">
                  <FormattedMessage {...messages.multipleRolesLabel} />
                </span>
                <span className="text-xs text-neutral-500">
                  {roleNames.join(', ')}
                </span>
              </div>
            )}
          </div>
          <span className="text-sm text-neutral-500 pl-2 truncate shrink-0">
            {isError ? (
              <ListItemErrorIcon />
            ) : isLoading || total === undefined ? null : (
              <OpenTasksCount totalTasks={total} />
            )}
          </span>
        </div>
      </ListItemLink>
    </li>
  );
};

import { SVGProps } from 'react';

export const Tag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <mask
      id="a"
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill="currentColor" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="currentColor"
        d="M12.575 21.4c-.4.4-.875.6-1.425.6s-1.025-.2-1.425-.6l-7.15-7.15A1.92 1.92 0 0 1 2 12.838c0-.559.192-1.03.575-1.413l8.8-8.825c.183-.183.4-.33.65-.438a1.99 1.99 0 0 1 .8-.162h7.15c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413v7.15c0 .283-.054.55-.163.8-.108.25-.254.467-.437.65l-8.8 8.8Zm4.9-13.4c.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.447 1.447 0 0 0 17.476 5c-.417 0-.77.146-1.063.438a1.446 1.446 0 0 0-.437 1.062c0 .417.146.77.438 1.063.291.291.645.437 1.062.437ZM11.15 20l8.825-8.85V4h-7.15L4 12.85 11.15 20Z"
      />
    </g>
  </svg>
);

import { forwardRef, ReactNode, useCallback, useState } from 'react';
import ReactMapGl, { MapProps, MapRef, ErrorEvent } from 'react-map-gl';
import { captureException } from '@sentry/react';

import { getEnvVariable } from '~/utils/environment';

import { MapError } from './MapError/MapError';

import 'mapbox-gl/dist/mapbox-gl.css';

export type BaseMapProps = Omit<MapProps, 'fog' | 'terrain'> & {
  children: ReactNode;
};

export const BaseMap = forwardRef<MapRef, BaseMapProps>(
  ({ initialViewState, ...rest }: BaseMapProps, ref) => {
    const [isError, setError] = useState(false);

    const onError = useCallback((error: ErrorEvent) => {
      setError(true);
      captureException(error, {
        tags: {
          map: true,
        },
      });
    }, []);

    if (isError) {
      return <MapError />;
    }

    return (
      <ReactMapGl
        ref={ref}
        mapboxAccessToken={getEnvVariable('MAPBOX_ACCESS_TOKEN')}
        mapStyle={getEnvVariable('MAPBOX_STYLE')}
        initialViewState={{ bearing: 0, pitch: 0, ...initialViewState }}
        minZoom={0}
        pitch={0}
        fadeDuration={0}
        dragRotate
        scrollZoom
        pitchWithRotate={false}
        attributionControl={false}
        touchPitch={false}
        onError={onError}
        reuseMaps
        {...rest}
      />
    );
  }
);

import { useState } from 'react';

import { useRoleNames } from './useRoleNames';

export type MapView = 'by-status' | 'by-battery';

const localStorageKey = 'map-view';

export const usePreferredMapView = (): [MapView, () => void] => {
  const roleNames = useRoleNames();

  const getDefaultMapViewByRole = (): MapView => {
    if (roleNames.includes('Swapper')) {
      return 'by-battery';
    }

    return 'by-status';
  };

  const getPreferredMapView = (): MapView => {
    const savedMapView: MapView | null = localStorage.getItem(
      localStorageKey
    ) as MapView;

    return savedMapView || getDefaultMapViewByRole();
  };

  const [preferredMapView, setPreferredMapView] = useState<MapView>(
    getPreferredMapView()
  );

  const toggleMapView = () => {
    const nextMapView: MapView =
      preferredMapView === 'by-battery' ? 'by-status' : 'by-battery';
    localStorage.setItem(localStorageKey, nextMapView);
    setPreferredMapView(nextMapView);
  };

  return [preferredMapView, toggleMapView];
};

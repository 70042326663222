import { useTaskCommentsQuery } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { Comment } from './Comment';
import messages from './messages';

export type CommentsProps = {
  taskId: string;
};

export const Comments = ({ taskId }: CommentsProps) => {
  const { data: taskComments = [] } = useTaskCommentsQuery(taskId);

  if (!taskComments.length) {
    return (
      <>
        <span className="block font-semibold text-sm mb-4">
          <FormattedMessage {...messages.comments} />
        </span>
        <p className="w-full text-sm text-neutral-500 mt-1.5">
          <FormattedMessage {...messages.noComments} />
        </p>
      </>
    );
  }

  const tasksSortedByCreationDate = taskComments.sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );

  return (
    <>
      <span className="block font-semibold text-sm mb-4">
        <FormattedMessage {...messages.comments} />
      </span>
      <div data-testid="TASK_COMMENTS" className="-ml-3.5">
        {tasksSortedByCreationDate.map(
          ({ createdAt, createdBy, comment }, index) => (
            <Comment
              operatorId={createdBy}
              comment={comment}
              createdAt={createdAt}
              key={createdAt}
              isLast={index === taskComments.length - 1}
            />
          )
        )}
      </div>
    </>
  );
};

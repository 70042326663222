import { defineMessages } from 'react-intl';

export default defineMessages({
  areYouSureYouWantToReleaseVehicles: {
    defaultMessage: 'Are you sure you want to release ALL taken vehicles?',
  },
  releaseVehicles: {
    defaultMessage: 'Release vehicles',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  vehiclesReleasedSuccessfully: {
    defaultMessage: 'The vehicles have been successfully released.',
  },
  userDoesNotHavePermissionsToReleaseVehicles: {
    defaultMessage:
      'You are not authorized to perform a release action on all vehicles.',
  },
});

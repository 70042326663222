import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Missing system permissions',
  },
  content: {
    defaultMessage: `Looks like you're missing home system permissions. If you're sure you have them, try logging out and logging back in.`,
  },
  logout: {
    defaultMessage: 'Log out',
  },
});

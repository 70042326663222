import { useIntl } from 'react-intl';
import { getKeys } from '@cooltra/utils';

import { telematicsLabels } from './messages';

export const useTelematicsOptions = () => {
  const { formatMessage } = useIntl();
  return getKeys(telematicsLabels)
    .map((telematics) => ({
      value: telematics,
      label: formatMessage(telematicsLabels[telematics]),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

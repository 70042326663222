import { useToggle } from '@cooltra/hooks';
import { Button, Modal } from '@cooltra/ui';
import { useCallback, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { stringifyParams } from '@cooltra/utils';

import {
  useFilteredVehiclesQuery,
  useVehiclesFilters,
} from '~/libs/vehicles-filters';

import { FiltersList } from '../FiltersList/FiltersList';
import { BatteryFilter } from '../BatteryFilter/BatteryFilter';
import { ModelFilter } from '../ModelFilter/ModelFilter';
import { TelematicsFilter } from '../TelematicsFilter/TelematicsFilter';
import { LocationFilter } from '../LocationFilter/LocationFilter';
import { TagFilter } from '../TagFilter/TagFilter';
import { AssigneeFilter } from '../AssigneeFilter/AssigneeFilter';
import { OperationalConditionFilter } from '../OperationalConditionFilter';
import { IdleFromFilter } from '../IdleFromFilter/IdleFromFilter';
import { Search } from '../Search';
import { FilterRouteProps } from '../types';

import messages from './messages';

type FilterRoute = {
  path: string;
  component: (props: FilterRouteProps) => JSX.Element;
};

const filterRoutes: FilterRoute[] = [
  { path: '/battery', component: BatteryFilter },
  { path: '/models', component: ModelFilter },
  { path: '/telematics', component: TelematicsFilter },
  { path: '/locations', component: LocationFilter },
  { path: '/tags', component: TagFilter },
  { path: '/idle-from', component: IdleFromFilter },
  { path: '/assignee', component: AssigneeFilter },
  { path: '/operational-conditions', component: OperationalConditionFilter },
  { path: '/search', component: Search },
];

export const Filters = () => {
  const navigate = useNavigate();
  const onBack = () => navigate('/filters');

  const { values } = useVehiclesFilters();
  const {
    data: { filteredVehiclesTotal },
  } = useFilteredVehiclesQuery({
    enabled: false,
  });

  const [open, { toggleOff, toggleOn }] = useToggle();

  const { pathname } = useLocation();

  const [isKeyboardVisible, toggleKeyboard] = useState(false);

  const keyboardListener = useCallback(() => {
    const minKeyboardHeight = 300;
    const isKeyboardOpen =
      !!window.visualViewport &&
      window.screen.height - minKeyboardHeight > window.visualViewport.height;
    toggleKeyboard(isKeyboardOpen);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', keyboardListener);
    return () => window.removeEventListener('resize', keyboardListener);
  }, [keyboardListener]);

  useEffect(() => {
    toggleOn();
  }, [toggleOn]);

  const handleOnAfterClose = () => {
    navigate(`/?${stringifyParams(values)}`);
  };

  return (
    <Modal
      hideHeader
      type="bottom"
      isOpen={open}
      onAfterClose={handleOnAfterClose}
      shouldReturnFocusAfterClose={false}
      shouldCloseOnOverlayClick={false}
      className="rounded-none"
      testId="FILTERS_MODAL"
    >
      <Routes>
        <Route path="/" element={<FiltersList onClose={toggleOff} />} />
        {filterRoutes.map(({ path, component: Component }) => (
          <Route
            key={path}
            path={path}
            element={<Component onClose={toggleOff} onBack={onBack} />}
          />
        ))}
      </Routes>
      {pathname !== '/filters/search' && !isKeyboardVisible && (
        <Button emphasis="high" className="fab-button" onClick={toggleOff}>
          <FormattedMessage {...messages.seeResults} />
          <span className="ml-1 font-light">{`(${filteredVehiclesTotal})`}</span>
        </Button>
      )}
    </Modal>
  );
};

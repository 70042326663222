import { useVehicleStatusQuery, useRentalQuery } from '@cooltra/api';
import { shortenId } from '@cooltra/utils';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import {
  TopNavigation,
  Screen,
  Error,
  TimeAgo,
  GoogleMapsLink,
} from '~/common';

import { CurrentlyRented } from '../CurrentlyRented/NoDataAvailable';
import { DropOffPhoto } from '../DropOffPhoto/DropOffPhoto';

import messages from './messages';

export type LastDropOffProps = {
  onClose: () => void;
  vehicleId: string;
};

export const LastDropOff = ({ onClose, vehicleId }: LastDropOffProps) => {
  const { rentalId = '' } = useParams<'rentalId'>();
  const navigate = useNavigate();

  const onBack = () => navigate(`/vehicles/${vehicleId}`);

  const { data: status } = useVehicleStatusQuery(vehicleId, {
    retry: false,
  });

  const {
    isError,
    isLoading,
    data: rental,
  } = useRentalQuery(rentalId, { enabled: !!vehicleId && !!rentalId });

  const renderContent = useCallback(() => {
    if (isError) {
      return <Error />;
    }
    if (isLoading) {
      return <></>;
    }
    if (!rental?.dropOff) {
      return <CurrentlyRented />;
    }
    if (!rental) {
      return <></>;
    }
    return (
      <div className="flex flex-col max-h-full px-4">
        <div className="py-5">
          <span className="block text-sm font-semibold text-neutral-800 mb-1">
            <TimeAgo ISODate={rental.dropOff.createdAt} />
          </span>
          {status && (
            <div>
              <GoogleMapsLink
                geoLocation={status.geoLocation}
                system={rental.system}
              />
            </div>
          )}
          <span className="text-xs">
            <FormattedMessage
              {...messages.rental}
              values={{ rentalId: shortenId(rental.rentalId) }}
            />
          </span>
        </div>
        <div className="flex-1">
          {rental.dropOff.parkingPhoto && (
            <DropOffPhoto name={rental.dropOff.parkingPhoto} />
          )}
        </div>
      </div>
    );
  }, [isError, isLoading, rental, status]);

  return (
    <Screen
      data-testid="VEHICLE_LAST_DROP_OFF_SCREEN"
      header={
        <div className="bg-neutral-0 border-b border-neutral-100">
          <TopNavigation.Bar
            renderLeft={<TopNavigation.Back onClick={onBack} />}
            renderRight={<TopNavigation.Close onClick={onClose} />}
            title={
              <TopNavigation.Title>
                <FormattedMessage {...messages.title} />
              </TopNavigation.Title>
            }
          />
        </div>
      }
      content={renderContent()}
    />
  );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  lock: {
    defaultMessage: 'Lock',
  },
  successOnLockVehicle: {
    defaultMessage: 'Lock action has been triggered successfully',
  },
});

import { defineMessages } from 'react-intl';
import { OperationalState } from '@cooltra/api';

export default defineMessages<OperationalState>({
  OPERATIONAL: {
    defaultMessage: 'Operational',
  },
  IN_MAINTENANCE: {
    defaultMessage: 'In maintenance',
  },
  NOT_OPERATIONAL: {
    defaultMessage: 'Not operational',
  },
});

import { ImgHTMLAttributes } from 'react';
import { useFetchDocument } from '@cooltra/api';
import { useIntl } from 'react-intl';

import { Photo } from '~/common';

import messages from './messages';

export type RentalPhotoProps = ImgHTMLAttributes<HTMLImageElement> & {
  name: string;
};

export const RentalPhoto = ({ name, ...rest }: RentalPhotoProps) => {
  const { formatMessage } = useIntl();
  const fetchedImageData = useFetchDocument(name);

  const imageData = fetchedImageData
    ? `data:image/jpeg;base64,${fetchedImageData}`
    : '';

  return (
    <Photo
      imageData={imageData}
      alt={formatMessage(messages.alt)}
      className="w-full rounded-none"
      loadingClassName="h-96 rounded-none"
      {...rest}
    />
  );
};

import { FormattedMessage } from 'react-intl';
import { FormInputField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';

import messages from './messages';

export const Title = () => (
  <FormInputField
    name="title"
    id="title"
    label={
      <InputLabel htmlFor="title">
        <FormattedMessage {...messages.title} />
      </InputLabel>
    }
  />
);

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Battery',
  },
  critical: {
    defaultMessage: 'Discharged',
  },
  low: {
    defaultMessage: 'From discharged to {max}%',
  },
  normal: {
    defaultMessage: 'From {min}% to {max}%',
  },
  full: {
    defaultMessage: 'From {min}% to {max}%',
  },
});

import { defineMessages } from 'react-intl';
import { ProblemReportTag } from '@cooltra/api';

export default defineMessages<ProblemReportTag>({
  bike_break: {
    defaultMessage: 'Bike breaks',
  },
  bike_damaged: {
    defaultMessage: 'Damaged bike',
  },
  bike_gps_problems: {
    defaultMessage: 'Bike GPS',
  },
  bike_handlebar: {
    defaultMessage: 'Bike handlebar',
  },
  bike_lights: {
    defaultMessage: 'Bike lights',
  },
  bike_ligths: {
    defaultMessage: 'Bike lights',
  },
  bike_lock: {
    defaultMessage: 'Bike lock',
  },
  bike_no_end_ride: {
    defaultMessage: 'Unfinished bike ride',
  },
  bike_no_resume_ride: {
    defaultMessage: 'Not resumed bike ride',
  },
  bike_others: {
    defaultMessage: 'Bike other',
  },
  bike_pedal: {
    defaultMessage: 'Bike pedal',
  },
  bike_seat: {
    defaultMessage: 'Bike seat',
  },
  bike_unlock: {
    defaultMessage: 'Bick unlock',
  },
  bike_wheel: {
    defaultMessage: 'Bike wheel',
  },
  damaged: {
    defaultMessage: 'Damaged',
  },
  dirty: {
    defaultMessage: 'Dirty',
  },
  flattires: {
    defaultMessage: 'Flat tire',
  },
  GorrosYaporFavor: {
    defaultMessage: 'GorrosYaporFavor',
  },
  gps: {
    defaultMessage: 'GPS',
  },
  gps_problems: {
    defaultMessage: 'GPS',
  },
  helmet: {
    defaultMessage: 'Helmet',
  },
  helmet_missing: {
    defaultMessage: 'Helmet missing',
  },
  Helmets: {
    defaultMessage: 'Helmets',
  },
  illegalparked: {
    defaultMessage: 'Illegal parking',
  },
  incorrect_import: {
    defaultMessage: 'Incorrect import',
  },
  left_mirror: {
    defaultMessage: 'Left mirror',
  },
  MechanicProblems: {
    defaultMessage: 'Mechanical problems',
  },
  no_end_resume: {
    defaultMessage: 'Not resumed',
  },
  no_end_ride: {
    defaultMessage: 'Unfinished ride',
  },
  nomirrors: {
    defaultMessage: 'No mirrors',
  },
  no_resume_ride: {
    defaultMessage: 'Not resumed',
  },
  notclean: {
    defaultMessage: 'Dirty',
  },
  other: {
    defaultMessage: 'Other',
  },
  others: {
    defaultMessage: 'Other',
  },
  Others: {
    defaultMessage: 'Other',
  },
  ride_rating_bad: {
    defaultMessage: 'Bad rating',
  },
  ride_rating_good: {
    defaultMessage: 'Good rating',
  },
  ride_rating_normal: {
    defaultMessage: 'Normal rating',
  },
  ride_rating_very_bad: {
    defaultMessage: 'Very bad rating',
  },
  ride_rating_very_good: {
    defaultMessage: 'Very good rating',
  },
  ScooterNotFound: {
    defaultMessage: 'Scooter not found',
  },
  top_case: {
    defaultMessage: 'Top case',
  },
  TopCase: {
    defaultMessage: 'Top case',
  },
  unstable: {
    defaultMessage: 'Unstable',
  },
  vehicle: {
    defaultMessage: 'Vehicle',
  },
  vehicle_no_start: {
    defaultMessage: 'Vehicle not starting',
  },
});

import { classNames } from '@cooltra/utils';

import { ListItem, NotAvailable } from '~/common';

export type TelematicsListItemVariant = 'neutral' | 'danger' | 'success';

export type TelematicsListItemProps = {
  'data-testid': string;
  title: JSX.Element;
  value?: JSX.Element;
  variant: TelematicsListItemVariant;
};

const telematicsListItemColorClasses: {
  [key in TelematicsListItemVariant]: string;
} = {
  neutral: 'text-neutral-800',
  danger: 'text-danger-500',
  success: 'text-success-500',
};

export const TelematicsListItem = ({
  title,
  value,
  variant,
  ...rest
}: TelematicsListItemProps) => (
  <ListItem {...rest}>
    <div className="flex justify-between items-center gap-4">
      <span className="text-base font-semibold text-neutral-700">{title}</span>
      <span className={classNames(telematicsListItemColorClasses[variant])}>
        {value ? value : <NotAvailable />}
      </span>
    </div>
  </ListItem>
);

import { classNames } from '@cooltra/utils';
import { ButtonHTMLAttributes } from 'react';

export type TextButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const TextButton = ({
  className,
  disabled,
  ...rest
}: TextButtonProps) => (
  <button
    className={classNames(
      'h-10 inline-flex items-center font-semibold',
      disabled ? 'text-neutral-300' : 'text-primary-500',
      className
    )}
    disabled={disabled}
    {...rest}
  />
);

import { Icon } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { ButtonHTMLAttributes } from 'react';

export type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const IconButton = ({
  children,
  className,
  ...rest
}: IconButtonProps) => (
  <button className={classNames('tap-highlight', className)} {...rest}>
    <Icon className="text-neutral-500 text-xl">{children}</Icon>
  </button>
);

import { defineMessages } from 'react-intl';

export default defineMessages({
  locationChangedSuccessfully: {
    defaultMessage: 'Location changed successfully!',
  },
  changeLocation: {
    defaultMessage: 'Change location',
  },
  userDoesNotHavePermissionsToMoveVehicle: {
    defaultMessage: 'You do not have permission to move the vehicle',
  },
  somethingWentWrong: {
    defaultMessage: 'Something went wrong!',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  moveVehicle: {
    defaultMessage:
      'Are you sure you want to move the vehicle {externalId} to {destination}?',
  },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Location',
  },
  changeLocation: {
    defaultMessage: 'Change location',
  },
  loading: {
    defaultMessage: 'Loading current location...',
  },
  search: {
    defaultMessage: 'Search',
  },
  placeholder: {
    defaultMessage: 'Search locations',
  },
});

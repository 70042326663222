import { defineMessages } from 'react-intl';

export default defineMessages({
  assignee: {
    defaultMessage: 'Assignee',
  },
  success: {
    defaultMessage: 'You have successfully updated the task!',
  },
  remove: {
    defaultMessage: 'Remove',
  },
  edit: {
    defaultMessage: 'Edit',
  },
});

import { usePatchTaskMutation, useTaskQuery } from '@cooltra/api';
import { useToggle } from '@cooltra/hooks';
import { Modal, TopLoadingBar } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';

import { useNotification } from '~/libs/notifications';
import { useVehicleQuery } from '~/hooks';
import { operatorGroups } from '~/utils/operator-groups';

import { Select, SelectItemText, SelectOption } from '../../../Select';
import { OperatorGroup } from '../../../Operator';
import { TaskTextButton } from '../TaskTextButton';

import messages from './messages';

export type OperatorGroupItemProps = {
  operatorGroupId: string;
  taskId: string;
};

export const OperatorGroupItem = ({
  operatorGroupId,
  taskId,
}: OperatorGroupItemProps) => {
  const { formatMessage } = useIntl();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const { data: task } = useTaskQuery(taskId);
  const { data: vehicle } = useVehicleQuery(task?.vehicleId || '', {
    enabled: !!task,
  });
  const { isPending, mutateAsync } = usePatchTaskMutation(taskId);

  const handleChange = async ({ value }: SelectOption) => {
    if (task && vehicle) {
      toggleOff();
      mutateAsync({
        blocking: task.blocking,
        description: task.description,
        operatorGroupId: value,
        operatorId: '',
        problemReportId: task.problemReportId,
        tagIds: task.tagIds,
        title: task.title,
        vehicleExternalId: vehicle.externalId,
        vehicleId: task.vehicleId,
      })
        .then(() => {
          addSuccessNotification(formatMessage(messages.success));
        })
        .catch(() => {
          addErrorNotification();
        });
    }
  };

  return (
    <>
      {isPending && <TopLoadingBar />}
      <div
        data-testid="OPERATOR_GROUP_ITEM"
        className="flex justify-between items-end"
      >
        <div>
          <span className="block text-sm text-neutral-300 mb-0.5">
            <FormattedMessage {...messages.operatorGroup} />
          </span>
          <span className="block text-sm">
            <OperatorGroup operatorGroupId={operatorGroupId} />
          </span>
        </div>
        <TaskTextButton
          variant="primary"
          onClick={toggleOn}
          disabled={isPending}
          className="px-4 -mr-3"
        >
          <FormattedMessage {...messages.edit} />
        </TaskTextButton>
      </div>
      <Modal
        hideHeader
        testId="TASK_OPERATOR_GROUP_MODAL"
        type="bottom"
        isOpen={isOpen}
        shouldReturnFocusAfterClose={false}
        shouldCloseOnOverlayClick={false}
        className="rounded-none"
      >
        <Select
          title={formatMessage(messages.operatorGroup)}
          value={operatorGroupId}
          onChange={handleChange}
          onClose={toggleOff}
          options={Object.values(operatorGroups).map(
            ({ name, operatorGroupId }) => ({
              label: name,
              value: operatorGroupId,
            })
          )}
          renderOption={({ label }) => <SelectItemText>{label}</SelectItemText>}
        />
      </Modal>
    </>
  );
};

import { FormattedMessage } from 'react-intl';
import { Model } from '@cooltra/api';

import { models } from './messages';

export type ModelNameProps = {
  model: Model;
};

export const ModelName = ({ model }: ModelNameProps) => (
  <FormattedMessage {...models[model]} />
);

import { MdChevronLeft } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { Icon } from '@cooltra/ui';

import { IconButton, IconButtonProps } from '../../InteractiveIcon';

import messages from './messages';

export type BackProps = Pick<IconButtonProps, 'onClick'>;

export const Back = (props: BackProps) => {
  const { formatMessage } = useIntl();
  return (
    <IconButton
      aria-label={formatMessage(messages.back)}
      className="h-full text-2xl px-4"
      {...props}
    >
      <Icon className="text-2xl">
        <MdChevronLeft />
      </Icon>
    </IconButton>
  );
};

import { useContext } from 'react';

import { MapCenterContext } from './MapCenterContext';

export const useMapCenter = () => {
  const mapCenterContext = useContext(MapCenterContext);

  if (!mapCenterContext) {
    throw new Error(
      'useMapCenter can be only used within <MapCenterProvider />'
    );
  }

  return mapCenterContext;
};

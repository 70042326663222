import { useToggle } from '@cooltra/hooks';
import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { ConfirmationModal } from './ConfirmationModal/ConfirmationModal';
import messages from './messages';

export type ReleaseAllProps = {
  onReleaseSuccessfullyAll: () => void;
};

export const ReleaseAll = ({ onReleaseSuccessfullyAll }: ReleaseAllProps) => {
  const [isConfirmationModalOpen, { toggleOn, toggleOff }] = useToggle(false);

  const handleOnFinishSuccessfully = () => {
    toggleOff();
    onReleaseSuccessfullyAll();
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        closeModal={toggleOff}
        onFinishSuccessfully={handleOnFinishSuccessfully}
      />
      <Button
        emphasis="medium"
        className="w-44 rounded-full shadow-xl z-30"
        onClick={toggleOn}
      >
        <FormattedMessage {...messages.releaseAll} />
      </Button>
    </>
  );
};

import { useFetchDocument } from '@cooltra/api';
import { useIntl } from 'react-intl';

import { Photo } from '~/common';

import messages from './messages';

export type DropOffPhotoProps = {
  name: string;
};

export const DropOffPhoto = ({ name, ...rest }: DropOffPhotoProps) => {
  const { formatMessage } = useIntl();
  const fetchedImageData = useFetchDocument(name);

  const imageData = fetchedImageData
    ? `data:image/jpeg;base64,${fetchedImageData}`
    : '';

  return (
    <Photo
      imageData={imageData}
      alt={formatMessage(messages.alt)}
      loadingClassName="h-96"
      {...rest}
    />
  );
};

import { IntlShape } from 'react-intl';

import messages from './messages';

export const validateRequired = (intl: IntlShape, value: any) =>
  value === '' ? intl.formatMessage(messages.required) : undefined;

export const createSetError =
  <ErrorKey extends string>(errors: Partial<Record<ErrorKey, string>>) =>
  (errorKey: ErrorKey, message: string | undefined) => {
    if (message) {
      errors[errorKey] = message;
    }
  };

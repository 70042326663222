import { useFormContext } from '@cooltra/form';
import { Spinner } from '@cooltra/ui';
import { MdSend } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { classNames } from '@cooltra/utils';

import messages from './messages';

export const SendButton = () => {
  const { formatMessage } = useIntl();
  const { isSubmitting, values } = useFormContext<{ comment: string }>();

  const disabled = values.comment === '';
  return (
    <button
      type="submit"
      className="w-full h-full pr-1 py-1 "
      disabled={disabled}
      aria-label={formatMessage(messages.send)}
    >
      <span
        className={classNames(
          'flex items-center justify-center h-full w-full btn',
          'btn-variant-primary--high',
          (disabled || isSubmitting) && 'btn-disabled-primary--high'
        )}
      >
        {isSubmitting ? <Spinner size="sm" /> : <MdSend />}
      </span>
    </button>
  );
};

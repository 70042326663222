import { defineMessages } from 'react-intl';

export default defineMessages({
  noComments: {
    defaultMessage: 'There are no comments in this task yet',
  },
  comments: {
    defaultMessage: 'Comments',
  },
});

import { useContext } from 'react';

import { PollVehicleContext } from './PollVehicleContext';

export const useVehiclePolling = () => {
  const vehiclePollingContext = useContext(PollVehicleContext);

  if (!vehiclePollingContext) {
    throw new Error(
      'useVehiclePolling can be only used within <PollVehicleProvider />'
    );
  }

  return vehiclePollingContext;
};

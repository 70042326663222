import { getLanguageOptions } from '@cooltra/countries';
import { useToggle } from '@cooltra/hooks';
import { usePreferredLanguage } from '@cooltra/preferred-language';
import { Modal } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';

import { ListItemButton, Select, SelectItemText, SelectOption } from '~/common';

import messages from './messages';

export const LanguageItem = () => {
  const { formatMessage } = useIntl();

  const [isOpen, { toggleOff, toggleOn }] = useToggle();

  const { updateLanguage, language, loadingLanguage } = usePreferredLanguage();

  const preferredLanguage = getLanguageOptions(['ca', 'nl']).find(
    (option) => option.value === language
  )?.label;

  const handleChange = (option: SelectOption) => {
    updateLanguage(option.value);
    toggleOff();
  };

  return (
    <>
      <li>
        <ListItemButton onClick={toggleOn} hasChevron>
          <div className="flex justify-between items-center gap-4">
            <span className="text-base font-semibold text-neutral-700">
              <FormattedMessage {...messages.listItemTitle} />
            </span>
            <span className="text-sm text-neutral-500 pl-2">
              {preferredLanguage}
            </span>
          </div>
        </ListItemButton>
      </li>
      <Modal
        hideHeader
        type="bottom"
        isOpen={isOpen}
        shouldReturnFocusAfterClose={false}
        shouldCloseOnOverlayClick={false}
        className="rounded-none"
      >
        <Select
          title={formatMessage(messages.modalTitle)}
          value={language}
          onChange={handleChange}
          isLoading={loadingLanguage}
          onClose={toggleOff}
          options={getLanguageOptions(['ca', 'nl'])}
          renderOption={({ label }) => <SelectItemText>{label}</SelectItemText>}
        />
      </Modal>
    </>
  );
};

import {
  MdPersonOutline,
  MdSearch,
  MdOutlineSettings,
  MdOutlineLocationOn,
  MdWifiTethering,
  MdBattery4Bar,
  MdPause,
} from 'react-icons/md';
import { Icon } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  TopNavigation,
  Screen,
  IconLink,
  ListItemLink,
  CustomIcon,
} from '~/common';
import { useVehiclesFilters } from '~/libs/vehicles-filters';

import messages from './messages';
import { ClearFilters } from './ClearFilters/ClearFilters';
import { Presets } from './Presets';

export type FiltersListProps = {
  onClose: () => void;
};

type FilterLink = {
  to: string;
  icon: ReactNode;
  label: ReactNode;
  testId: string;
  isHighlighted: boolean;
};

export const FiltersList = ({ onClose }: FiltersListProps) => {
  const { formatMessage } = useIntl();

  const { values } = useVehiclesFilters();

  const links: FilterLink[] = [
    {
      to: 'assignee',
      icon: <MdPersonOutline />,
      label: <FormattedMessage {...messages.assignee} />,
      testId: 'ASSIGNEE_FILTER',
      isHighlighted:
        !!values.taskOperatorId.length || !!values.taskOperatorGroupId.length,
    },
    {
      to: 'operational-conditions',
      icon: <MdOutlineSettings />,
      label: <FormattedMessage {...messages.operationalCondition} />,
      testId: 'OPERATIONAL_CONDITION_FILTER',
      isHighlighted: !!values.operationalCondition.length,
    },
    {
      to: 'locations',
      icon: <MdOutlineLocationOn />,
      label: <FormattedMessage {...messages.locations} />,
      testId: 'LOCATION_FILTER',
      isHighlighted: !!values.location.length,
    },
    {
      to: 'models',
      icon: <CustomIcon.Motorcycle />,
      label: <FormattedMessage {...messages.model} />,
      testId: 'MODEL_FILTER',
      isHighlighted: !!values.model.length,
    },
    {
      to: 'battery',
      icon: <MdBattery4Bar />,
      label: <FormattedMessage {...messages.battery} />,
      testId: 'BATTERY_FILTER',
      isHighlighted: !!values.batteryChargeLevel.length || !!values.unbalanced,
    },
    {
      to: 'tags',
      icon: <CustomIcon.Tag />,
      label: <FormattedMessage {...messages.tags} />,
      testId: 'TAG_FILTER',
      isHighlighted: !!values.tagId.length,
    },
    {
      to: 'telematics',
      icon: <MdWifiTethering />,
      label: <FormattedMessage {...messages.telematics} />,
      testId: 'TELEMATICS_FILTER',
      isHighlighted: !!values.telematics.length,
    },
    {
      to: 'idle-from',
      icon: <MdPause />,
      label: <FormattedMessage {...messages.idleFrom} />,
      testId: 'IDLE_FROM_FILTER',
      isHighlighted: !!values.idleFrom.length,
    },
  ];

  return (
    <Screen
      data-testid="FILTERS_SCREEN"
      header={
        <>
          <TopNavigation.Bar
            renderLeft={
              <IconLink
                aria-label={formatMessage(messages.search)}
                className="h-full px-4"
                to="search"
              >
                <MdSearch />
              </IconLink>
            }
            renderRight={<TopNavigation.Close onClick={onClose} />}
            title={
              <TopNavigation.Title>
                <FormattedMessage {...messages.filters} />
              </TopNavigation.Title>
            }
          />
          <Presets rightContent={<ClearFilters />} />
        </>
      }
      content={
        <ul className="pb-20">
          {links.map(({ to, icon, label, testId, isHighlighted }) => (
            <li key={to}>
              <ListItemLink
                to={to}
                data-testid={testId}
                leadingIcon={
                  <Icon
                    className={classNames(
                      'text-2xl text-neutral-500 mr-2',
                      isHighlighted ? 'text-primary-500' : 'text-neutral-500'
                    )}
                  >
                    {icon}
                  </Icon>
                }
                hasChevron
                isSelected={isHighlighted}
              >
                <span className="text-base font-semibold text-neutral-700">
                  {label}
                </span>
              </ListItemLink>
            </li>
          ))}
        </ul>
      }
    />
  );
};

import { FormattedMessage } from 'react-intl';

import { SelectItemText, TopNavigation, useIdleFrom } from '~/common';

import { FiltersFormMultiSelect } from '../FiltersFormMultiSelect';
import { FilterRouteProps } from '../types';

import messages from './messages';

export const IdleFromFilter = ({ onClose, onBack }: FilterRouteProps) => {
  const telematicsOptions = useIdleFrom();

  return (
    <FiltersFormMultiSelect
      name="idleFrom"
      onClose={onClose}
      onBack={onBack}
      title={
        <TopNavigation.Title>
          <FormattedMessage {...messages.title} />
        </TopNavigation.Title>
      }
      renderOption={({ label }) => <SelectItemText>{label}</SelectItemText>}
      options={telematicsOptions}
    />
  );
};

import { RentalListItem, VehicleStatus } from '@cooltra/api';
import { MdLocationOn } from 'react-icons/md';

import { DateTime, GoogleMapsLink } from '~/common';

export type PickUpProps = Pick<RentalListItem, 'system' | 'pickUp'> & {
  vehicleStatus?: VehicleStatus;
};

export const PickUp = ({
  vehicleStatus,
  system,
  pickUp: { latitude, longitude, createdAt },
}: PickUpProps) => {
  if (!vehicleStatus) {
    return <></>;
  }

  return (
    <div className="flex flex-col">
      <span className="flex items-center gap-1 text-sm text-neutral-800 mb-1">
        <MdLocationOn className="opacity-40" />
        <DateTime date={createdAt} />
      </span>
      <div className="block break-words">
        <GoogleMapsLink
          className="block font-normal break-words"
          geoLocation={{
            latitude: latitude,
            longitude: longitude,
            updatedAt: createdAt,
          }}
          system={system}
        />
      </div>
    </div>
  );
};

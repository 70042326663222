import { FormattedMessage } from 'react-intl';
import { Telematics } from '@cooltra/api';

import { telematicsLabels } from './messages';

export type TelematicsNameProps = {
  telematics: Telematics;
};

export const TelematicsName = ({ telematics }: TelematicsNameProps) => (
  <FormattedMessage {...telematicsLabels[telematics]} />
);

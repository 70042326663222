import { Marker } from 'react-map-gl';

import { useCurrentPosition } from '~/libs/current-position';

export const CurrentPosition = () => {
  const currentPositon = useCurrentPosition();

  if (!currentPositon.length) {
    return <></>;
  }

  return (
    <Marker latitude={currentPositon[1]} longitude={currentPositon[0]}>
      <div className="relative w-5 h-5 border border-neutral-200 rounded-full">
        <div className="absolute w-full h-full rounded-full bg-primary-300 animate-ping" />
        <div className="relative w-full h-full rounded-full bg-primary-300 border-2 border-neutral-0" />
      </div>
    </Marker>
  );
};

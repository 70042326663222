import {
  Operator,
  operatorGroups as apiOperatorGroups,
  System,
} from '@cooltra/auth-api';

import { getEnvVariable } from './environment';

export const operatorGroups =
  getEnvVariable('ENVIRONMENT') === 'production'
    ? apiOperatorGroups.prod
    : apiOperatorGroups.stage;

export const isOperatorGroup =
  (operatorGroupIds: string[]) => (operator: Operator) => {
    if (!operatorGroupIds.length) {
      return true;
    }
    return operator.operatorGroupIds.some((operatorGroupId) =>
      operatorGroupIds.includes(operatorGroupId)
    );
  };

export const canAccessHomeSystem =
  (systems: System[]) => (operator: Operator) => {
    if (!systems.length) {
      return true;
    }
    return operator.systems.some((system) => systems.includes(system));
  };

export const isBlocked = (blocked?: boolean) => (operator: Operator) => {
  if (blocked === undefined) {
    return true;
  }
  return operator.blocked === blocked;
};

import { FormattedMessage } from 'react-intl';
import { ProblemReportTag } from '@cooltra/api';
import { Badge } from '@cooltra/ui';

import messages from './messages';

export type ProblemReportBadge = {
  tagId: ProblemReportTag;
  className?: string;
};

export const ProblemReportBadge = ({
  tagId,
  className,
}: ProblemReportBadge) => (
  <Badge className={className} variant="primary" emphasis="low">
    {messages[tagId] ? (
      <FormattedMessage {...messages[tagId]} />
    ) : (
      <>{tagId} </>
    )}
  </Badge>
);

import { defineMessages } from 'react-intl';

export default defineMessages({
  unlockTopCase: {
    defaultMessage: 'Unlock top case',
  },
  successOnUnlockTopCaseVehicle: {
    defaultMessage: 'Unlock top case action has been triggered successfully',
  },
});

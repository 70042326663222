import { operatorGroups } from '~/utils/operator-groups';

import { VehiclesFiltersExpression } from '../is-preset-match';

export const swapper: VehiclesFiltersExpression = {
  and: [
    { notContainOperationalCondition: 'IN_LOCATION' },
    { notContainOperationalCondition: 'RENTED' },
    { notContainOperationalCondition: 'RESERVED' },
    {
      or: [
        {
          isTaskMatch: {
            blocking: true,
            operatorGroupId: operatorGroups.SWAPPER.operatorGroupId,
          },
        },
        { batteryRange: [0, 30] },
      ],
    },
  ],
};

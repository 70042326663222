import { Expression } from 'mapbox-gl';

export const getCircleColorExpressionByBatteryChargeLevel = (): Expression => [
  'case',
  ['==', ['get', 'batteryChargeLevel'], 'low'],
  '#E12D39',
  ['==', ['get', 'batteryChargeLevel'], 'medium'],
  '#F0B428',
  ['==', ['get', 'batteryChargeLevel'], 'neutral'],
  '#764B23',
  ['==', ['get', 'batteryChargeLevel'], 'high'],
  '#189A1C',
  '#189A1C',
];

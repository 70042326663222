import { defineMessages } from 'react-intl';

export default defineMessages({
  blockingTasks: {
    defaultMessage: '{totalBlockingTasks} blocking',
  },
  totalTasks: {
    defaultMessage: '{totalTasks, plural, one {# task} other {# tasks}}',
  },
  noTasks: {
    defaultMessage: 'None',
  },
});

import { FormattedMessage } from 'react-intl';

import { TelematicsFieldProps } from '../types';
import { TelematicsListItem } from '../TelematicsListItem/TelematicsListItem';

import messages from './messages';

const LockStatusLabel = () => (
  <span className="text-base font-semibold">
    <FormattedMessage {...messages.lockStatus} />
  </span>
);

export const LockStatus = ({ vehicleStatus }: TelematicsFieldProps) => {
  if (vehicleStatus.locked === null) {
    return (
      <TelematicsListItem
        data-testid="LOCK_STATUS"
        variant="neutral"
        title={<LockStatusLabel />}
      />
    );
  }

  if (!vehicleStatus.locked.value) {
    return (
      <TelematicsListItem
        data-testid="LOCK_STATUS"
        variant="danger"
        title={<LockStatusLabel />}
        value={
          <span className="text-sm block">
            <FormattedMessage {...messages.unlocked} />
          </span>
        }
      />
    );
  }

  return (
    <TelematicsListItem
      data-testid="LOCK_STATUS"
      variant="success"
      title={<LockStatusLabel />}
      value={
        <span className="text-sm block">
          <FormattedMessage {...messages.locked} />
        </span>
      }
    />
  );
};

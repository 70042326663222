import { VehiclesFiltersExpression } from '../is-preset-match';

export const prio: VehiclesFiltersExpression = {
  or: [
    {
      and: [
        { batteryRange: [0, 3] },
        { containsAnyBlockingTasks: false },
        { isInALocation: false },
      ],
    },
    {
      and: [
        { batteryRange: [0, 3] },
        {
          or: [
            {
              isLocationIdMatch: 'a74ef12a-f8c5-44ff-82d7-9ae46e1b23a5',
            },
            {
              isLocationIdMatch: '06f33ca0-7b5f-48ae-86f2-2c09bcebff8d',
            },
            {
              isLocationIdMatch: '90bba318-b556-4f04-b908-2e7991d59bb3',
            },
            {
              isLocationIdMatch: 'd9982618-7aa8-4d95-8bcb-216359d8d562',
            },
            {
              isLocationIdMatch: 'ff9d5468-5e63-42c4-ad3a-50460fcb875d',
            },
            {
              isLocationIdMatch: '62aea2f4-017c-49d8-9543-eeeff67cc3a8',
            },
            {
              isLocationIdMatch: 'cd99bcf5-fcdd-417a-87a3-dfad53becb26',
            },
          ],
        },
      ],
    },
    {
      and: [
        { batteryRange: [0, 3] },
        { isInALocation: false },
        { containsAnyBlockingTasks: true },
        {
          noOtherTasksThan: [
            {
              title: 'B - V - NL - Helmet 03 - Helmet missing',
            },
            {
              title: 'B - V - NL - Helmet 04 - Helmets missing (Both)',
            },
            {
              title: 'B - V - NL - Helmet 06 - Helmet broken',
            },
            {
              title: 'B - V - NL - Helmet 07 - Helmet stolen',
            },
            {
              title: 'B - V - NL - Helmet 08 - Helmets broken (Both)',
            },
            {
              title: 'B - V - NL - Mirror 01 - Mirrors broken or missing',
            },
            {
              title: 'B - V - NL - Mirror 03 - Left mirror broken or missing',
            },
          ],
        },
      ],
    },
  ],
};

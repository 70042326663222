import { FormattedMessage, useIntl } from 'react-intl';
import { useTagsQuery } from '@cooltra/api';
import { useField, useFormContext } from '@cooltra/form';
import { Modal, Badge, Button } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';

import {
  FormMultiSelect,
  MultiSelectFieldButton,
  SelectItemText,
  TopNavigation,
} from '~/common';

import messages from './messages';

export const Tags = () => {
  const { formatMessage } = useIntl();
  const { isSubmitting } = useFormContext();

  const [isOpen, { toggleOff, toggleOn }] = useToggle();

  const { data: tags = [], isLoading } = useTagsQuery();
  const { value: tagIds, setValue } = useField('tagIds');

  const renderItem = (tagId: string) => {
    const tagName = tags.find((tag) => tag.tagId === tagId)?.name;
    return tagName ? <Badge>{tagName}</Badge> : <></>;
  };

  return (
    <>
      <MultiSelectFieldButton
        optional
        testId="TAGS_FIELD"
        disabled={isSubmitting}
        value={tagIds}
        renderItem={renderItem}
        label={<FormattedMessage {...messages.tags} />}
        showClearButton={!!tagIds.length}
        onClear={() => setValue([])}
        onClick={toggleOn}
      />
      <Modal
        hideHeader
        type="bottom"
        isOpen={isOpen}
        shouldReturnFocusAfterClose={false}
        shouldCloseOnOverlayClick={false}
        className="rounded-none"
      >
        <FormMultiSelect
          onClose={toggleOff}
          isSearchable
          name="tagIds"
          searchPlaceholder={formatMessage(messages.searchPlaceholder)}
          isLoading={isLoading}
          title={
            <TopNavigation.Title>
              <FormattedMessage {...messages.tags} />
            </TopNavigation.Title>
          }
          renderOption={({ label }) => <SelectItemText>{label}</SelectItemText>}
          options={tags
            .map(({ name, tagId }) => ({
              value: tagId,
              label: name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))}
        />
        <Button
          emphasis="high"
          onClick={toggleOff}
          className="rounded-full fab-button"
        >
          <FormattedMessage {...messages.apply} />
        </Button>
      </Modal>
    </>
  );
};

import { ReactNode } from 'react';

export type LabelAndValueProps = {
  label: ReactNode;
  value: ReactNode;
};

export const LabelAndValue = ({ label, value }: LabelAndValueProps) => (
  <div>
    <span className="block text-sm text-neutral-300 mb-0.5">{label}</span>
    <div className="text-sm">{value}</div>
  </div>
);

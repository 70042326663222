import { createContext } from 'react';

export type TrackUserContextShape = {
  isTrackingUser: boolean;
  stopTrackingUser: () => void;
  startTrackingUser: () => void;
};

export const TrackUserContext = createContext<
  TrackUserContextShape | undefined
>(undefined);

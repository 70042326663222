import { Vehicle } from '@cooltra/api';
import { useOperatorsQuery } from '@cooltra/auth-api';
import { FormattedMessage } from 'react-intl';
import { MdOutlineLocationOn } from 'react-icons/md';

import { ListItemLink } from '~/common';

import messages from './messages';

export type TakenByListItemProps = Pick<Vehicle, 'takenBy'>;

export const TakenByListItem = ({ takenBy }: TakenByListItemProps) => {
  const { data: operators } = useOperatorsQuery();

  const renderTakenBy = () => {
    const operatorName = operators?.find(
      ({ externalId }) => takenBy === externalId
    )?.name;

    return (
      <span className="text-sm text-neutral-500 pl-2 truncate">
        {operatorName}
      </span>
    );
  };

  return (
    <li data-testid="VEHICLE_TAKEN_BY_LIST_ITEM">
      <ListItemLink
        to="location"
        leadingIcon={
          <MdOutlineLocationOn className="text-2xl text-neutral-500 mr-2" />
        }
        hasChevron
      >
        <div className="flex justify-between items-center gap-4">
          <span className="text-base font-semibold text-neutral-700 flex-1 whitespace-nowrap">
            <FormattedMessage {...messages.location} />
          </span>
          {renderTakenBy()}
        </div>
      </ListItemLink>
    </li>
  );
};

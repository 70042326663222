import { useToggle } from '@cooltra/hooks';
import { Icon } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { MdCached } from 'react-icons/md';

import {
  useFilteredVehiclesQuery,
  useVehiclesFilters,
} from '~/libs/vehicles-filters';

import { MapButton } from '../MapButton';

import messages from './messages';

export const RefreshButton = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { values } = useVehiclesFilters();

  const [isLoading, { toggleOff, toggleOn }] = useToggle(false);

  const { isFetching } = useFilteredVehiclesQuery({
    enabled: false,
  });

  useEffect(() => {
    if (!isFetching && isLoading) {
      toggleOff();
    }
  }, [isFetching, isLoading, toggleOff]);

  const refresh = () => {
    toggleOn();
    queryClient.invalidateQueries({ queryKey: ['vehicles'] }).then(() => {
      queryClient.invalidateQueries({
        queryKey: ['preset-vehicles', values.preset],
      });
    });
    queryClient.invalidateQueries({ queryKey: ['vehicle'] });
  };

  return (
    <MapButton aria-label={formatMessage(messages.refresh)} onClick={refresh}>
      <Icon className={classNames(isLoading && 'animate-spin')}>
        <MdCached className="text-2xl" />
      </Icon>
    </MapButton>
  );
};

import { Spinner } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { ReactNode } from 'react';

export type ActionButtonType = 'success' | 'neutral';

const buttonClasses: { [key in ActionButtonType]: string } = {
  neutral:
    'text-neutral-500 bg-neutral-50 focus:bg-neutral-100 focus:ring-neutral-100',
  success:
    'text-neutral-0 bg-success-400 focus:bg-success-500 focus:ring-success-500',
};

export type VehicleActionButtonProps = {
  type?: ActionButtonType;
  onClick: () => void;
  loading: boolean;
  children: ReactNode;
  className?: string;
  icon?: ReactNode;
  ariaLabel: string;
};

export const VehicleActionButton = ({
  onClick,
  children,
  loading,
  type = 'neutral',
  className,
  icon,
  ariaLabel,
}: VehicleActionButtonProps) => (
  <div className="flex flex-col items-center justify-center mt-4">
    <button
      className={classNames(
        'btn',
        'btn-size-lg',
        'rounded-full',
        'focus:ring',
        'p-0',
        'w-16',
        'h-16',
        buttonClasses[type],
        className
      )}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {loading && (
        <span className="absolute inset-0 flex items-center justify-center">
          <Spinner size="md" />
        </span>
      )}
      <span
        className={classNames(
          'max-w-full',
          'inline-flex',
          'items-center',
          loading ? 'opacity-0' : 'opacity-100'
        )}
      >
        {icon}
      </span>
    </button>
    <span className="text-sm truncate text-neutral-0 mt-1">{children}</span>
  </div>
);

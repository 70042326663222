import { defineMessages } from 'react-intl';

export default defineMessages({
  powerOff: {
    defaultMessage: 'Power off',
  },
  successOnPowerOffVehicle: {
    defaultMessage: 'Power off action has been triggered successfully',
  },
});

import { FormattedDate, FormattedTime } from 'react-intl';

export type DateTimeProps = {
  date: string;
};

export const DateTime = ({ date }: DateTimeProps) => (
  <>
    <FormattedDate
      value={new Date(date)}
      day="2-digit"
      month="short"
      year="numeric"
    />
    {' - '}
    <FormattedTime
      value={new Date(date)}
      hour="2-digit"
      hourCycle="h23"
      minute="2-digit"
    />
  </>
);

import { defineMessages } from 'react-intl';

export default defineMessages({
  profile: {
    defaultMessage: 'Profile',
  },
  logOut: {
    defaultMessage: 'Log out',
  },
  logOutWithWorkUnit: {
    defaultMessage: 'Finish work in progress to log out',
  },
  language: {
    defaultMessage: 'Language',
  },
});

import { FormattedMessage } from 'react-intl';
import { Button, Icon } from '@cooltra/ui';
import { MdLock } from 'react-icons/md';
import { useAuth0 } from '@auth0/auth0-react';
import { captureMessage, setUser } from '@sentry/react';
import { useEffect } from 'react';

import messages from './messages';

export type InvalidDeviceProps = {
  device: string | undefined;
};

export const InvalidDevice = ({ device }: InvalidDeviceProps) => {
  const { logout, user } = useAuth0();

  useEffect(() => {
    captureMessage('Invalid device error page', {
      tags: {
        user: user?.sub,
        deviceModel: device,
      },
    });
  }, [device, user?.sub]);

  const handleLogOut = () => {
    localStorage.clear();
    setUser(null);

    logout({
      logoutParams: {
        returnTo: 'https://www.office.com/estslogout',
      },
    });
  };

  return (
    <div className="pt-32 px-4 text-center">
      <Icon className="mb-6 text-2xl p-4 rounded-full text-neutral-500 bg-neutral-50">
        <MdLock />
      </Icon>
      <h6 className="text-lg font-semibold mb-1">
        <FormattedMessage {...messages.title} />
      </h6>
      <p className="text-sm text-neutral-700 mb-6 px-8">
        <FormattedMessage {...messages.content} />
      </p>
      <Button emphasis="high" onClick={handleLogOut}>
        <FormattedMessage {...messages.logout} />
      </Button>
    </div>
  );
};

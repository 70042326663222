import { useProblemReportQuery } from '@cooltra/api';
import { RouterLink } from '@cooltra/navigation';
import { shortenId } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';

import { DateTime, LabelAndValue } from '~/common';

import messages from './messages';

export type TaskProblemReportProps = {
  problemReportId: string;
  to: string;
};

export const TaskProblemReport = ({
  problemReportId,
  to,
}: TaskProblemReportProps) => {
  const { data: problemReport, isError } =
    useProblemReportQuery(problemReportId);

  if (!problemReport || isError) {
    return <></>;
  }

  return (
    <LabelAndValue
      label={<FormattedMessage {...messages.fromProblemReport} />}
      value={
        <div className="flex items-center mt-1">
          <RouterLink to={to} className="tap-highlight">
            #{shortenId(problemReport.problemReportId)}
          </RouterLink>
          <span className="text-neutral-200 mx-3">|</span>
          <div>
            <DateTime date={problemReport.createdAt} />
          </div>
        </div>
      }
    />
  );
};

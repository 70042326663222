import { HTMLAttributes, ReactNode } from 'react';
import { classNames } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';

import { Screen } from '~/common';

import messages from './messages';

export type VehicleListSheetProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  isOpen: boolean;
};

export const VehicleListSheet = ({
  children,
  isOpen,
  className,
}: VehicleListSheetProps) => (
  <Screen
    data-testid="VEHICLE_LIST"
    className={classNames(
      'transition-all duration-300 absolute z-10 w-full bg-neutral-50',
      isOpen ? 'top-0' : 'top-full',
      className
    )}
    header={
      <div className="h-20 flex items-center justify-center py-4">
        <h2 className="text-lg font-semibold text-neutral-700">
          <FormattedMessage {...messages.vehicles} />
        </h2>
      </div>
    }
    contentId="scrollable-vehicle-list"
    content={<div className="pb-12 pt-1 overflow-x-hidden">{children}</div>}
  />
);

import { VehicleListItem } from '@cooltra/api';
import {
  isModelMatch,
  isTelematicsMatch,
  isLocationMatch,
  isTagsMatch,
  isOperationalConditionsMatch,
  isOperatorOrGroupMatch,
  isUnbalancedMatch,
  chargePerLevelRanges,
} from '@cooltra/vehicle-utils';

import { VehiclesFiltersFormValues } from '../vehicles-filters-form';

import { isIdleFromMatch } from './is-idle-from-match/is-idle-from-match';

const isChargeInRange = (
  charge: number,
  ranges: { min: number; max: number }[]
) => ranges.some(({ min, max }) => charge >= min && charge <= max);

const isBatteryMatch = (
  vehicle: VehicleListItem,
  { batteryChargeLevel }: VehiclesFiltersFormValues
): boolean => {
  if (!batteryChargeLevel.length) {
    return true;
  }

  const batteryCharge = vehicle.status.batteryCharge?.value;
  if (batteryCharge == null) {
    return false;
  }

  const chargePerLevelMap =
    chargePerLevelRanges[vehicle.model] ?? chargePerLevelRanges.ASKOLL_ES2;
  const chargeMinMax = batteryChargeLevel.map(
    (level) => chargePerLevelMap[level]
  );

  return isChargeInRange(batteryCharge, chargeMinMax);
};

export const isVehicleAMatch = (
  vehicle: VehicleListItem,
  filterValues: VehiclesFiltersFormValues
) =>
  [
    isModelMatch,
    isTelematicsMatch,
    isLocationMatch,
    isTagsMatch,
    isOperationalConditionsMatch,
    isOperatorOrGroupMatch,
    isBatteryMatch,
    isIdleFromMatch,
    isUnbalancedMatch,
  ].every((filter) => filter(vehicle, filterValues));

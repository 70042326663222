import { FormattedMessage } from 'react-intl';

import { TelematicsFieldProps } from '../types';
import { TelematicsListItem } from '../TelematicsListItem/TelematicsListItem';

import messages from './messages';

const PowerLabel = () => (
  <span className="text-base font-semibold">
    <FormattedMessage {...messages.power} />
  </span>
);

export const Power = ({ vehicleStatus }: TelematicsFieldProps) => {
  if (vehicleStatus.ignition === null) {
    return (
      <TelematicsListItem
        data-testid="POWER"
        variant="neutral"
        title={<PowerLabel />}
      />
    );
  }

  if (!vehicleStatus.ignition.value) {
    return (
      <TelematicsListItem
        data-testid="POWER"
        variant="success"
        title={<PowerLabel />}
        value={
          <span className="text-sm block">
            <FormattedMessage {...messages.powerOff} />
          </span>
        }
      />
    );
  }

  return (
    <TelematicsListItem
      data-testid="POWER"
      variant="danger"
      title={<PowerLabel />}
      value={
        <span className="text-sm block">
          <FormattedMessage {...messages.powerOn} />
        </span>
      }
    />
  );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  heading: {
    defaultMessage: 'Add new task',
  },
  title: {
    defaultMessage: 'Title',
  },
  assignee: {
    defaultMessage: 'Assignee',
  },
  addTask: {
    defaultMessage: 'Add task',
  },
  success: {
    defaultMessage: 'You have successfully created a new task!',
  },
});

import { operatorGroups } from '~/utils/operator-groups';

import { VehiclesFiltersExpression } from '../is-preset-match';

export const withoutQnrLocationId = '30fdb589-1001-4c05-8955-02e81bbb045b';

export const swapperWqnr: VehiclesFiltersExpression = {
  or: [
    {
      and: [
        { notContainOperationalCondition: 'IN_LOCATION' },
        { notContainOperationalCondition: 'RENTED' },
        { notContainOperationalCondition: 'RESERVED' },
        {
          or: [
            {
              isTaskMatch: {
                blocking: true,
                operatorGroupId: operatorGroups.SWAPPER.operatorGroupId,
              },
            },
            { batteryRange: [0, 30] },
            { containsOperationalCondition: 'OFFLINE' },
          ],
        },
      ],
    },
    {
      and: [
        { notContainOperationalCondition: 'RENTED' },
        { notContainOperationalCondition: 'RESERVED' },
        { locationId: withoutQnrLocationId },
        {
          or: [
            {
              isTaskMatch: {
                blocking: true,
                operatorGroupId: operatorGroups.SWAPPER.operatorGroupId,
              },
            },
            { batteryRange: [0, 30] },
            { containsOperationalCondition: 'OFFLINE' },
          ],
        },
      ],
    },
  ],
};

import { FormattedMessage } from 'react-intl';
import { equal } from '@cooltra/utils';

import {
  useVehiclesFilters,
  vehiclesFiltersInitialValues,
} from '~/libs/vehicles-filters';
import { TextButton } from '~/common';

import messages from './messages';

export const ClearFilters = () => {
  const { setValues, values } = useVehiclesFilters();

  const clearFilters = () => setValues(vehiclesFiltersInitialValues);

  return (
    <TextButton
      disabled={equal(values, vehiclesFiltersInitialValues)}
      className="text-sm"
      onClick={clearFilters}
    >
      <FormattedMessage {...messages.clearFilters} />
    </TextButton>
  );
};

import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type OpenTasksCountProps = {
  totalTasks: number;
  totalBlockingTasks?: number;
};

export const OpenTasksCount = ({
  totalTasks,
  totalBlockingTasks,
}: OpenTasksCountProps) => {
  if (totalBlockingTasks && totalBlockingTasks > 0) {
    return (
      <span>
        <FormattedMessage {...messages.totalTasks} values={{ totalTasks }} />
        {', '}
        <span className="text-danger-600">
          <FormattedMessage
            {...messages.blockingTasks}
            values={{ totalBlockingTasks }}
          />
        </span>
      </span>
    );
  }

  if (totalTasks > 0) {
    return (
      <FormattedMessage {...messages.totalTasks} values={{ totalTasks }} />
    );
  }

  return <FormattedMessage {...messages.noTasks} />;
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Average battery',
  },
  updated: {
    defaultMessage: 'Updated\u2000',
  },
  batteryA: {
    defaultMessage: 'Battery A',
  },
  batteryB: {
    defaultMessage: 'Battery B',
  },
  voltage: {
    defaultMessage: 'Average voltage',
  },
  boardVoltage: {
    defaultMessage: 'Board voltage',
  },
  range: {
    defaultMessage: 'Range',
  },
  lastSwap: {
    defaultMessage: 'Last swap',
  },
});

import {
  FieldButtonBase,
  FieldButtonBaseProps,
} from './FieldButtonBase/FieldButtonBase';

export type InputFieldButtonProps = Omit<FieldButtonBaseProps, 'children'> & {
  value?: string;
};

export const InputFieldButton = ({ value, ...rest }: InputFieldButtonProps) => (
  <FieldButtonBase showClearButton={!!value} {...rest}>
    {value}
  </FieldButtonBase>
);

import { useField } from '@cooltra/form';

import { MultiSelect, MultiSelectProps } from './MultiSelect';

export type FormMultiSelectProps<Value extends string> = Omit<
  MultiSelectProps<Value>,
  'value' | 'onChange'
> & {
  name: string;
};

export function FormMultiSelect<Value extends string>({
  name,
  ...rest
}: FormMultiSelectProps<Value>) {
  const { value, setValue } = useField<Value[]>(name);

  return (
    <MultiSelect
      value={value}
      onChange={(values) => setValue(values.map(({ value }) => value))}
      {...rest}
    />
  );
}

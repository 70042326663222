import { Marker } from 'react-map-gl';

import userDropOff from './assets/user-dropoff.svg';

export type UserDropOffMarkerProps = {
  latitude: number;
  longitude: number;
};

export const UserDropOffMarker = ({
  latitude,
  longitude,
}: UserDropOffMarkerProps) => (
  <Marker latitude={latitude} longitude={longitude}>
    <img className="w-7" src={userDropOff} />
  </Marker>
);

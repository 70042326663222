import { FormattedMessage } from 'react-intl';
import { Telematics } from '@cooltra/api';

import { TelematicsName, TimeAgo } from '~/common';

import { TelematicsFieldProps } from '../types';

import messages from './messages';

export type LastSyncProps = TelematicsFieldProps & {
  telematics: Telematics;
};

export const LastSync = ({ vehicleStatus, telematics }: LastSyncProps) => (
  <div className="w-full gap-2 flex items-center justify-center">
    <p className="text-sm text-neutral-800">
      <TelematicsName telematics={telematics} />
    </p>
    <span className="text-sm text-neutral-200">|</span>
    <p className="text-sm text-neutral-800">
      <FormattedMessage
        {...messages.lastSync}
        values={{
          timeInThePast: <TimeAgo ISODate={vehicleStatus?.updatedAt} />,
        }}
      />
    </p>
  </div>
);

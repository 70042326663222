import { MdClose, MdCheck } from 'react-icons/md';
import { Icon } from '@cooltra/ui';
import { ReactNode } from 'react';

import { ListItemButton } from '../ListItem';

export type SelectItemProps = {
  onClick: () => void;
  children: ReactNode;
  'data-testid'?: string;
  isSelected?: boolean;
  isDeselected?: boolean;
  hasTapHighlight?: boolean;
};

export const SelectItem = ({
  onClick,
  children,
  isSelected,
  isDeselected,
  hasTapHighlight = false,
  ...rest
}: SelectItemProps) => (
  <li>
    <ListItemButton
      onClick={onClick}
      hasTapHighlight={hasTapHighlight}
      isSelected={isSelected}
      isDeselected={isDeselected}
      trailingIcon={
        isSelected ? (
          <Icon className="text-xl text-primary-500">
            <MdCheck />
          </Icon>
        ) : isDeselected ? (
          <Icon className="text-xl text-danger-500">
            <MdClose />
          </Icon>
        ) : undefined
      }
      {...rest}
    >
      {children}
    </ListItemButton>
  </li>
);

import { defineMessages } from 'react-intl';

export default defineMessages({
  topCase: {
    defaultMessage: 'Top case',
  },
  closed: {
    defaultMessage: 'Closed',
  },
  open: {
    defaultMessage: 'Open',
  },
});

import { useTagsQuery } from '@cooltra/api';
import { classNames } from '@cooltra/utils';

import { TaskBadge } from '../TaskBadge';

export type TaskTagsProps = {
  tagIds: string[];
  blocking: boolean;
  className?: string;
};

export const TaskTags = ({ tagIds, blocking, className }: TaskTagsProps) => {
  const { data: tags } = useTagsQuery();

  if (!tags) {
    return <></>;
  }

  return (
    <ul
      className={classNames(
        'flex',
        'flex-wrap',
        'gap-2',
        (blocking || !!tagIds.length) && 'mt-2',
        className
      )}
    >
      {blocking && <TaskBadge.Blocking />}
      {tagIds.map((tagId) => (
        <TaskBadge.Regular key={tagId}>
          {tags.find((tag) => tag.tagId === tagId)?.name}
        </TaskBadge.Regular>
      ))}
    </ul>
  );
};

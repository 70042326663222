import { Icon } from '@cooltra/ui';
import { RouterBaseLink, RouterBaseLinkProps } from '@cooltra/navigation';
import { classNames } from '@cooltra/utils';

export const IconLink = ({
  children,
  className,
  ...rest
}: RouterBaseLinkProps) => (
  <RouterBaseLink className={classNames('tap-highlight', className)} {...rest}>
    <Icon className="text-neutral-500 text-xl">{children}</Icon>
  </RouterBaseLink>
);

import { System } from '@cooltra/auth-api';

export const getBonusZoneFillLayerId = (bonusZoneId: string) => {
  return `bonus-zone-fill-${bonusZoneId}`;
};

export const getBonusZoneBordersSourceId = (bonusZoneId: string) => {
  return `bonus-zone-borders-${bonusZoneId}`;
};

export const getBonusZoneSourceId = (bonusZoneId: string, system: System) => {
  return `bonus-zone-${system}-${bonusZoneId}`;
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Last drop-off',
  },
  rental: {
    defaultMessage: 'Rental #{rentalId}',
  },
});

import { PropsWithChildren, useEffect, useState } from 'react';

import { useVehicleQuery } from '~/hooks';
import { isTestingEnv } from '~/utils/e2e';

import { PollVehicleContext } from './PollVehicleContext';

export const PollVehicleProvider = ({ children }: PropsWithChildren) => {
  const [vehicleId, setVehicleId] = useState<string | undefined>(undefined);
  const { refetch } = useVehicleQuery(String(vehicleId), {
    enabled: !!vehicleId,
  });

  useEffect(() => {
    let attempts = 0;
    let timer: NodeJS.Timeout;

    if (vehicleId && !isTestingEnv()) {
      timer = setInterval(async () => {
        await refetch();

        if (attempts === 2) {
          setVehicleId(undefined);
          clearInterval(timer);
        }

        attempts++;
      }, 3000);
    }

    return () => clearInterval(timer);
  }, [refetch, vehicleId]);

  return (
    <PollVehicleContext.Provider
      value={{ activatePollingVehicle: setVehicleId }}
    >
      {children}
    </PollVehicleContext.Provider>
  );
};

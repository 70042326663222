import { Button, Modal, ModalProps } from '@cooltra/ui';
import {
  useTaskQuery,
  useTaskStatusMutation,
  useWorkUnitQuery,
} from '@cooltra/api';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { useNotification } from '~/libs/notifications';
import { useVehiclePolling } from '~/libs/polling-data';

import messages from './messages';

export type CloseTaskModalProps = Pick<
  ModalProps,
  'isOpen' | 'onAfterClose'
> & {
  taskId: string;
  onClose: () => void;
  onSuccess: () => void;
};

export const CloseTaskModal = ({
  isOpen,
  onClose,
  onAfterClose,
  onSuccess,
  taskId,
}: CloseTaskModalProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { activatePollingVehicle } = useVehiclePolling();

  const { vehicleId = '' } = useParams<'vehicleId'>();

  const { data: workUnit } = useWorkUnitQuery();
  const { mutate, isPending } = useTaskStatusMutation(taskId);
  const { data: task } = useTaskQuery(taskId, { enabled: !!taskId });

  const { addErrorNotification, addSuccessNotification } = useNotification();

  const transferVehicleToNewLocation = () => {
    const handleOnError = () => {
      addErrorNotification(formatMessage(messages.somethingWentWrong));
    };

    const handleOnSuccess = () => {
      addSuccessNotification(formatMessage(messages.taskClosedSuccessfully));
      if (task?.blocking) {
        activatePollingVehicle(vehicleId);
      }
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
      queryClient.invalidateQueries({ queryKey: ['vehicle-tasks', vehicleId] });
      queryClient.invalidateQueries({ queryKey: ['vehicles'] });
      onSuccess();
    };

    mutate(
      workUnit
        ? { open: !open, workUnitId: workUnit.workUnitId }
        : { open: !open },
      {
        onError: handleOnError,
        onSuccess: handleOnSuccess,
        onSettled: onClose,
      }
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterClose={onAfterClose}
      shouldReturnFocusAfterClose={false}
      hideHeader
      className="mx-10 rounded-3xl"
    >
      <div className="py-10 p-7" data-testid="CLOSE_TASK_MODAL">
        <p className="mb-7 text-center">
          <FormattedMessage
            {...messages.areYouSureToCloseVehicle}
            values={{
              taskTitle: <span className="font-semibold">{task?.title}</span>,
            }}
          />
        </p>
        <div className="flex flex-col gap-5">
          <Button
            loading={isPending}
            className="rounded-full"
            onClick={transferVehicleToNewLocation}
            emphasis="high"
          >
            <FormattedMessage {...messages.acceptClosingTask} />
          </Button>
          <Button className="rounded-full" onClick={onClose}>
            <FormattedMessage {...messages.cancel} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

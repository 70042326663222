import { useAuth0 } from '@auth0/auth0-react';
import { MdErrorOutline } from 'react-icons/md';
import { Button, Icon } from '@cooltra/ui';
import { setUser } from '@sentry/react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const CrashPage = () => {
  const { logout } = useAuth0();
  const reload = () => window.location.reload();

  const handleLogOut = () => {
    localStorage.clear();
    setUser(null);
    logout({
      logoutParams: {
        returnTo: 'https://www.office.com/estslogout',
      },
    });
  };

  return (
    <div className="pt-32 px-4 text-center">
      <Icon className="mb-6 text-2xl p-4 rounded-full text-neutral-500 bg-neutral-50">
        <MdErrorOutline />
      </Icon>
      <h6 className="text-lg font-semibold mb-1">
        <FormattedMessage {...messages.title} />
      </h6>
      <p className="text-sm text-neutral-700 mb-6 px-8">
        <FormattedMessage {...messages.content} />
      </p>
      <div className="flex gap-4 justify-center">
        <Button onClick={reload} emphasis="high">
          <FormattedMessage {...messages.reload} />
        </Button>
        <Button emphasis="high" onClick={handleLogOut}>
          <FormattedMessage {...messages.logout} />
        </Button>
      </div>
    </div>
  );
};

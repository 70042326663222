import { VehicleListItem } from '@cooltra/api';
import { useState, PropsWithChildren, useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useMap } from 'react-map-gl';

import { useUserSystems } from '~/hooks';

import { useTrackUser } from '../track-user';

import { SelectVehicleContext } from './SelectVehicleContext';

export const SelectVehicleProvider = ({ children }: PropsWithChildren) => {
  const map = useMap()?.home;
  const queryClient = useQueryClient();
  const systems = useUserSystems();
  const { stopTrackingUser } = useTrackUser();

  const allVehicles = queryClient.getQueryData<VehicleListItem[]>([
    'vehicles',
    { system: systems },
  ]);
  const [vehicleId, setVehicleId] = useState<string | undefined>(undefined);

  const selectVehicle = useCallback(
    (vehicleId: string) => {
      setVehicleId(vehicleId);
      stopTrackingUser();

      const geoLocation = allVehicles?.find(
        (vehicle) => vehicleId === vehicle.vehicleId
      )?.status.geoLocation;

      if (geoLocation && map) {
        const currentZoom = map.getZoom();
        map.flyTo({
          zoom: currentZoom < 16 ? 16 : currentZoom,
          center: {
            lat: geoLocation.latitude,
            lon: geoLocation.longitude,
          },
        });
      }
    },
    [allVehicles, map, stopTrackingUser]
  );

  const deselectVehicle = () => setVehicleId(undefined);

  const value = useMemo(
    () => ({
      vehicleId,
      selectVehicle,
      deselectVehicle,
    }),
    [selectVehicle, vehicleId]
  );

  return (
    <SelectVehicleContext.Provider value={value}>
      {children}
    </SelectVehicleContext.Provider>
  );
};

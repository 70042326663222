import { HTMLAttributes } from 'react';
import { classNames } from '@cooltra/utils';
import { VehicleStatus } from '@cooltra/api';

export type OngoingRentalTotalDistanceProps =
  HTMLAttributes<HTMLSpanElement> & {
    startMileage: number;
    vehicleStatus?: VehicleStatus;
  };

export const OngoingRentalTotalDistance = ({
  startMileage,
  className,
  vehicleStatus,
}: OngoingRentalTotalDistanceProps) => {
  if (!vehicleStatus?.mileage) {
    return <></>;
  }

  const { value: currentMileage } = vehicleStatus.mileage;
  const startMileageInKms = startMileage / 1000;

  const totalMileageInKms = currentMileage - startMileageInKms;

  if (totalMileageInKms < 1) {
    const totalMileageInMts = totalMileageInKms * 100;

    return (
      <span className={classNames('block tabular-nums', className)}>
        {totalMileageInMts} mts
      </span>
    );
  }

  return (
    <span className={classNames('block tabular-nums', className)}>
      {totalMileageInKms.toFixed(1)} kms
    </span>
  );
};

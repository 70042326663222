import { defineMessages } from 'react-intl';

export default defineMessages({
  ignitionOff: {
    defaultMessage: 'Ignition Off',
  },
  successOnIgnitionOffVehicle: {
    defaultMessage: 'Ignition Off action has been triggered successfully',
  },
});

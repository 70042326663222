import { TopLoadingBar } from '@cooltra/ui';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';

import { Screen } from '../Screen/Screen';
import { TopNavigation } from '../TopNavigation';
import { ClearableInput } from '../ClearableInput/ClearableInput';
import { NoSearchResults } from '../NoSearchResults/NoSearchResults';

import { MultiSelectBadge } from './MultiSelectBadge';
import { SelectItem } from './SelectItem';
import messages from './messages';

export type MultiSelectOption<Value extends string> = {
  label: string;
  value: Value;
};

export type GroupedMultiSelectOption<Value extends string> = {
  label: string;
  options: MultiSelectOption<Value>[];
};

export type MultiSelectProps<Value extends string> = {
  isLoading?: boolean;
  isSearchable?: boolean;
  searchPlaceholder?: string;
  onChange: (values: MultiSelectOption<Value>[]) => void;
  onClose: () => void;
  onBack?: () => void;
  options: MultiSelectOption<Value>[];
  renderOption: (option: MultiSelectOption<Value>) => ReactNode;
  title: ReactNode;
  value: Value[];
  extraOptions?: ReactNode;
};

export function MultiSelect<Value extends string>({
  isLoading = false,
  isSearchable = false,
  searchPlaceholder,
  onChange,
  onClose,
  onBack,
  options,
  renderOption,
  title,
  value,
  extraOptions,
}: MultiSelectProps<Value>) {
  const { formatMessage } = useIntl();

  const [searchInput, setSearchInput] = useState('');

  const selectedOptions = value
    .map((value) => options?.find((option) => option.value === value))
    .filter((option) => !!option) as MultiSelectOption<Value>[];

  const handleChange = (option: MultiSelectOption<Value>) => () => {
    const hasBeenSelected = !!selectedOptions.find(
      (selected) => selected.value === option.value
    );

    const updatedSelection = hasBeenSelected
      ? selectedOptions.filter(({ value }) => option.value !== value)
      : selectedOptions.concat(option);

    onChange(updatedSelection);
  };

  const filteredOptions = isSearchable
    ? options
        .filter((option) => !value.includes(option.value))
        .filter((option) =>
          option.label.toLowerCase().includes(searchInput.toLowerCase())
        )
    : options;

  const handleRemovingBadge = (badgeValue: string) => () => {
    const updatedSelection = selectedOptions.filter(
      ({ value }) => badgeValue !== value
    );
    onChange(updatedSelection);
  };

  return (
    <Screen
      header={
        <>
          {isLoading && <TopLoadingBar />}
          <div className="bg-neutral-0 border-b border-neutral-100">
            <TopNavigation.Bar
              renderLeft={onBack && <TopNavigation.Back onClick={onBack} />}
              renderRight={<TopNavigation.Close onClick={onClose} />}
              title={title}
            />
            {isSearchable && (
              <div className="px-4 pb-4 pt-1">
                <ClearableInput
                  aria-label={formatMessage(messages.search)}
                  autoFocus
                  placeholder={searchPlaceholder}
                  value={searchInput}
                  onChange={setSearchInput}
                />
              </div>
            )}
            {isSearchable && !isLoading && !!value.length && (
              <ul
                className="flex gap-3 flex-wrap px-4 pb-4"
                data-testid="MULTI_SELECT_VALUES"
              >
                {value.map((val) => (
                  <li key={val}>
                    <MultiSelectBadge onClick={handleRemovingBadge(val)}>
                      {options.find((option) => option.value === val)?.label}
                    </MultiSelectBadge>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </>
      }
      content={
        <>
          {!isLoading && isSearchable && !filteredOptions.length && (
            <NoSearchResults />
          )}
          <ul data-testid="MULTI_SELECT_OPTIONS" className="pb-20">
            {filteredOptions.map((option) => (
              <SelectItem
                hasTapHighlight={isSearchable}
                isSelected={value.includes(option.value)}
                onClick={handleChange(option)}
                key={option.value}
                data-testid={option.value}
              >
                {renderOption(option)}
              </SelectItem>
            ))}
            {extraOptions}
          </ul>
        </>
      }
    />
  );
}

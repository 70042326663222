import { RentalListItem } from '@cooltra/api';
import { shortenId } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';

import { LabelAndValue, ListItemLink, TimeAgo } from '~/common';

import { RentalDuration } from '../../RentalDuration/RentalDuration';

import messages from './messages';

export type RentalCardProps = {
  rental: RentalListItem;
};

export const RentalCard = ({
  rental: { pickUp, dropOff, rentalId },
}: RentalCardProps) => (
  <ListItemLink to={rentalId} className="h-auto px-4 py-5" hasChevron>
    <span className="block text-base mb-4">
      <FormattedMessage
        {...messages.rental}
        values={{
          rentalId: (
            <span className="font-semibold whitespace-normal">
              #{shortenId(rentalId)}
            </span>
          ),
        }}
      />
    </span>
    <div className="grid grid-cols-2 gap-2">
      <LabelAndValue
        label={<FormattedMessage {...messages.dropOff} />}
        value={
          dropOff ? (
            <TimeAgo ISODate={dropOff.createdAt} />
          ) : (
            <span className="font-semibold text-success-600">
              <FormattedMessage {...messages.inProgress} />
            </span>
          )
        }
      />
      <LabelAndValue
        label={<FormattedMessage {...messages.duration} />}
        value={
          <RentalDuration
            startDate={pickUp.createdAt}
            finishDate={dropOff?.createdAt || new Date()}
          />
        }
      />
    </div>
  </ListItemLink>
);

import { useAuth0 } from '@auth0/auth0-react';
import {
  useTasksQuery,
  FetchTasksRequestParams,
  useTasksTotal,
} from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { ListItemErrorIcon, ListItemLink, OpenTasksCount } from '~/common';

import messages from './messages';

export const MyTasksItem = () => {
  const { user } = useAuth0();

  const params: FetchTasksRequestParams = {
    open: 'true',
    operatorId: user?.sub || '',
  };

  const { isLoading, isError } = useTasksQuery(params);

  const total = useTasksTotal(params);

  return (
    <li>
      <ListItemLink to="tasks" hasChevron data-testid="MY_TASKS_ITEM">
        <div className="flex justify-between items-center gap-4">
          <span className="text-base font-semibold text-neutral-700">
            <FormattedMessage {...messages.myTasks} />
          </span>
          <span className="text-sm text-neutral-500 pl-2 truncate">
            {isError ? (
              <ListItemErrorIcon />
            ) : isLoading || total === undefined ? null : (
              <OpenTasksCount totalTasks={total} />
            )}
          </span>
        </div>
      </ListItemLink>
    </li>
  );
};

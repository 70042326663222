import { defineMessages } from 'react-intl';

export default defineMessages({
  topCase: {
    defaultMessage: 'Top case lock',
  },
  locked: {
    defaultMessage: 'Locked',
  },
  unlocked: {
    defaultMessage: 'Unlocked',
  },
});

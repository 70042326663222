import { defineMessages } from 'react-intl';

export default defineMessages({
  honk: {
    defaultMessage: 'Honk',
  },
  successOnHonkVehicle: {
    defaultMessage: 'Honk action has been triggered successfully',
  },
});

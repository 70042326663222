import { defineMessages } from 'react-intl';

export default defineMessages({
  endShift: {
    defaultMessage: 'End shift',
  },
  areYouSure: {
    defaultMessage: 'Are you sure you want to end your shift?',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
});

import { FormattedMessage } from 'react-intl';

import { TelematicsFieldProps } from '../types';
import { TelematicsListItem } from '../TelematicsListItem/TelematicsListItem';

import messages from './messages';

const HelmetLabel = () => (
  <span className="text-base font-semibold">
    <FormattedMessage {...messages.helmet} />
  </span>
);

export const Helmet = ({ vehicleStatus }: TelematicsFieldProps) => {
  if (vehicleStatus.helmetPresent === null) {
    return (
      <TelematicsListItem
        data-testid="HELMET"
        variant="neutral"
        title={<HelmetLabel />}
      />
    );
  }

  if (!vehicleStatus.helmetPresent.value) {
    return (
      <TelematicsListItem
        data-testid="HELMET"
        variant="danger"
        title={<HelmetLabel />}
        value={
          <span className="text-sm block">
            <FormattedMessage {...messages.missing} />
          </span>
        }
      />
    );
  }

  return (
    <TelematicsListItem
      data-testid="HELMET"
      variant="success"
      title={<HelmetLabel />}
      value={
        <span className="text-sm block">
          <FormattedMessage {...messages.inVehicle} />
        </span>
      }
    />
  );
};

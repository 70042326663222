import { defineMessages } from 'react-intl';

export default defineMessages({
  operatorGroup: {
    defaultMessage: 'Operator',
  },
  success: {
    defaultMessage: 'You have successfully updated the task!',
  },
  edit: {
    defaultMessage: 'Edit',
  },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'No data available',
  },
  content: {
    defaultMessage: "There is a problem with this vehicle's telematics",
  },
});

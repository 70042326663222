import { VehicleListItem } from '@cooltra/api';
import { RouterCardLink } from '@cooltra/navigation';

import {
  BatteryIcon,
  BatteryIconLabel,
  ModelImage,
  OpenTasksCount,
  OperationalStateStatusPoint,
} from '~/common';
import { useLocationName } from '~/hooks';

export type VehicleCardProps = {
  vehicle: VehicleListItem;
};

export const VehicleCard = ({
  vehicle: {
    vehicleId,
    model,
    externalId,
    locationId,
    identificationNumber,
    operationalState,
    status,
    openTasks,
  },
}: VehicleCardProps) => {
  const locationName = useLocationName(locationId);
  const blockingTasks = openTasks.filter(({ blocking }) => blocking);

  return (
    <RouterCardLink className="flex" to={`/vehicles/${vehicleId}`}>
      <div className="h-6 py-6 pl-4">
        <OperationalStateStatusPoint operationalState={operationalState} />
      </div>
      <div className="flex flex-col flex-1 items-start p-4 pl-3">
        <div className="flex items-center mb-1">
          <ModelImage
            model={model}
            identificationNumber={identificationNumber}
            className="h-6 mr-2"
          />
          <span className="text-sm text-neutral-1000 font-semibold truncate">
            {externalId}
          </span>
        </div>
        <div className="flex justify-between items-center gap-4 w-full">
          <div className="text-xs text-neutral-500 ml-1.5">
            <span>{locationName}</span>
            {locationName && !!openTasks.length && <span>{`, `}</span>}
            {!!openTasks.length && (
              <span>
                <OpenTasksCount
                  totalTasks={openTasks.length}
                  totalBlockingTasks={blockingTasks.length}
                />
              </span>
            )}
          </div>
          {status?.batteryCharge && (
            <div className="flex items-center">
              <BatteryIconLabel
                charge={status.batteryCharge.value}
                model={model}
                className="mr-1.5 mt-px text-xs"
              />
              <div className="w-6 h-3">
                <BatteryIcon
                  model={model}
                  charge={status.batteryCharge.value}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </RouterCardLink>
  );
};

import { FormattedMessage } from 'react-intl';
import { Button, Modal } from '@cooltra/ui';
import { useCloseWorkShiftMutation } from '@cooltra/api';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { useNotification } from '~/libs/notifications';

import messages from './messages';

export type EndShiftModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const EndShiftModal = ({ isOpen, onClose }: EndShiftModalProps) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isPending } = useCloseWorkShiftMutation();
  const { addErrorNotification } = useNotification();
  const navigate = useNavigate();

  const endShift = () => {
    return mutateAsync()
      .then(() => {
        queryClient.setQueryData(['current-work-shift'], null);
        onClose();
        navigate('/');
      })
      .catch(() => addErrorNotification());
  };

  return (
    <Modal isOpen={isOpen} hideHeader className="mx-10 rounded-3xl">
      <div className="p-6 w-80" data-testid="CONFIRM_END_SHIFT_MODAL">
        <h3 className="text-neutral-1000 font-semibold text-base text-center mb-4">
          <FormattedMessage {...messages.endShift} />
        </h3>
        <p className="text-sm text-neutral-500 text-center mb-6">
          <FormattedMessage {...messages.areYouSure} />
        </p>
        <div className="flex flex-col gap-2.5">
          <Button
            className="rounded-full"
            emphasis="high"
            onClick={endShift}
            loading={isPending}
            disabled={isPending}
          >
            <FormattedMessage {...messages.endShift} />
          </Button>
          <Button
            className="rounded-full"
            onClick={onClose}
            loading={isPending}
            disabled={isPending}
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

import { TopLoadingBar } from '@cooltra/ui';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';

import { Screen } from '../Screen/Screen';
import { TopNavigation } from '../TopNavigation';
import { ClearableInput } from '../ClearableInput/ClearableInput';
import { NoSearchResults } from '../NoSearchResults/NoSearchResults';

import { SelectItem } from './SelectItem';
import messages from './messages';

export type SelectOption = {
  label: string;
  value: string;
};

export type SelectProps = {
  isLoading?: boolean;
  isSearchable?: boolean;
  searchPlaceholder?: string;
  onChange: (value: SelectOption) => void;
  onClose: () => void;
  onBack?: () => void;
  options: SelectOption[];
  renderOption: (option: SelectOption) => ReactNode;
  title: ReactNode;
  value?: string;
};

export function Select({
  isLoading = false,
  isSearchable = false,
  searchPlaceholder,
  onChange,
  onClose,
  onBack,
  options,
  renderOption,
  title,
  value,
}: SelectProps) {
  const { formatMessage } = useIntl();

  const [searchInput, setSearchInput] = useState('');

  const handleChange = (option: SelectOption) => () => {
    onChange(option);
  };

  const filteredOptions = isSearchable
    ? options.filter((option) =>
        option.label.toLowerCase().includes(searchInput.toLowerCase())
      )
    : options;

  return (
    <Screen
      header={
        <>
          {isLoading && <TopLoadingBar />}
          <div className="bg-neutral-0 border-b border-neutral-100">
            <TopNavigation.Bar
              renderLeft={onBack && <TopNavigation.Back onClick={onBack} />}
              renderRight={<TopNavigation.Close onClick={onClose} />}
              title={title}
            />
            {isSearchable && (
              <div className="px-4 pb-4 pt-1">
                <ClearableInput
                  aria-label={formatMessage(messages.search)}
                  autoFocus
                  placeholder={searchPlaceholder}
                  value={searchInput}
                  onChange={setSearchInput}
                />
              </div>
            )}
          </div>
        </>
      }
      content={
        <>
          {!isLoading && isSearchable && !filteredOptions.length && (
            <NoSearchResults />
          )}
          <ul data-testid="MULTI_SELECT_OPTIONS" className="pb-20">
            {filteredOptions.map((option) => (
              <SelectItem
                hasTapHighlight={isSearchable}
                isSelected={!!value?.includes(option.value)}
                onClick={handleChange(option)}
                key={option.value}
                data-testid={option.value}
              >
                {renderOption(option)}
              </SelectItem>
            ))}
          </ul>
        </>
      }
    />
  );
}

import { classNames } from '@cooltra/utils';
import { isBike } from '@cooltra/vehicle-utils';
import { useWorkUnitQuery } from '@cooltra/api';
import { useIntl } from 'react-intl';
import { MdPedalBike } from 'react-icons/md';

import { useSelectVehicle } from '~/libs/select-vehicle';
import { useVehicleQuery } from '~/hooks';
import { CustomIcon } from '~/common';

import messages from './messages';

import './work-unit-vehicle-link.css';

export const WorkUnitVehicleLink = () => {
  const { formatMessage } = useIntl();
  const { selectVehicle, vehicleId } = useSelectVehicle();
  const { data: workUnit } = useWorkUnitQuery();
  const { data: vehicle } = useVehicleQuery(
    workUnit ? workUnit.vehicleId : '',
    { enabled: !!workUnit }
  );

  const isBicycle = !!vehicle && isBike(vehicle);

  const handleClick = () => {
    workUnit && selectVehicle(workUnit.vehicleId);
  };

  return (
    <button
      onClick={handleClick}
      aria-label={formatMessage(
        isBicycle ? messages.bikeWorkUnit : messages.scooterWorkUnit
      )}
      className={classNames(
        'bg-warning-500 shadow-lg shadow-warning-200 absolute rounded-tr-full z-20 w-16 h-16 pr-2 pt-2',
        'work-unit-vehicle-link',
        workUnit &&
          workUnit.vehicleId !== vehicleId &&
          'work-unit-vehicle-link--visible'
      )}
    >
      {isBicycle ? (
        <div className="flex flex-col">
          <span className="text-neutral-0 text-2xl -ml-0.5 block leading-none">
            <MdPedalBike />
          </span>
          {vehicle && (
            <span className="text-neutral-0 leading-none">
              ...{vehicle.externalId.slice(-4)}
            </span>
          )}
        </div>
      ) : (
        <div className="flex flex-col">
          <span className="text-neutral-0 text-2xl block leading-none">
            <CustomIcon.Motorcycle />
          </span>
          {vehicle && (
            <span className="text-neutral-0 leading-none">
              ...{vehicle?.externalId.slice(-4)}
            </span>
          )}
        </div>
      )}
    </button>
  );
};

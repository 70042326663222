import { useContext } from 'react';

import { TrackUserContext } from './TrackUserContext';

export const useTrackUser = () => {
  const trackUserContext = useContext(TrackUserContext);

  if (!trackUserContext) {
    throw new Error(
      'useTrackUser can be only used within <TrackUserProvider />'
    );
  }

  return trackUserContext;
};

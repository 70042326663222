import { HTMLAttributes, ReactNode, SVGProps } from 'react';
import {
  MdInfoOutline,
  MdOutlineCancel,
  MdCheckCircleOutline,
  MdErrorOutline,
} from 'react-icons/md';
import { Icon } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';

export type NotificationVariant =
  | 'neutral'
  | 'primary'
  | 'danger'
  | 'warning'
  | 'success';

export type NotificationProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  variant?: NotificationVariant;
};

const notificationIconClasses: { [key in NotificationVariant]: string } = {
  neutral: 'text-neutral-700',
  primary: 'text-primary-800',
  danger: 'text-danger-800',
  warning: 'text-warning-800',
  success: 'text-success-900',
};

const notificationBackgroundClasses: { [key in NotificationVariant]: string } =
  {
    neutral: 'bg-neutral-50',
    primary: 'bg-primary-50',
    danger: 'bg-danger-50',
    warning: 'bg-warning-100',
    success: 'bg-success-50',
  };

const notificationIcons: {
  [key in NotificationVariant]: (props: SVGProps<SVGSVGElement>) => JSX.Element;
} = {
  neutral: MdInfoOutline,
  primary: MdInfoOutline,
  danger: MdOutlineCancel,
  warning: MdErrorOutline,
  success: MdCheckCircleOutline,
};

export const Notification = ({
  variant = 'neutral',
  children,
  className,
  ...rest
}: NotificationProps) => {
  const NotificationIcon = notificationIcons[variant];
  return (
    <div
      role="alert"
      className={classNames(
        'w-full shadow-lg rounded-xl p-4 relative bg-neutral-0 mb-2',
        notificationBackgroundClasses[variant],
        className
      )}
      {...rest}
    >
      <div
        className={classNames(
          'flex items-center',
          notificationIconClasses[variant]
        )}
      >
        <Icon
          className={classNames(
            notificationIconClasses[variant],
            'self-start',
            'text-2xl',
            'pr-2'
          )}
        >
          <NotificationIcon />
        </Icon>
        <p
          className={classNames(
            'text-sm font-semibold',
            notificationIconClasses[variant]
          )}
        >
          {children}
        </p>
      </div>
    </div>
  );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  release: {
    defaultMessage: 'Release',
  },
  actions: {
    defaultMessage: 'Vehicle actions',
  },
  moreReasons: {
    defaultMessage: 'More reasons...',
  },
  recollection: {
    defaultMessage: 'For recollection',
  },
  rebalance: {
    defaultMessage: 'For rebalance',
  },
  reviewConnectivity: {
    defaultMessage: 'To review connectivity with vehicle',
  },
  poorlyParked: {
    defaultMessage: 'To move poorly parked vehicle',
  },
  workQueue: {
    defaultMessage: 'To create work queue',
  },
  other: {
    defaultMessage: 'Other...',
  },
});

import { useWorkUnitFutureStateQuery, Vehicle } from '@cooltra/api';
import { MdCached } from 'react-icons/md';
import { BadgePlaceholder, Button } from '@cooltra/ui';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  BatteryIcon,
  BatteryIconLabel,
  ModelImage,
  OperationalStateBadge,
} from '~/common';

import messages from './messages';

export type WorkUnitFutureStateProps = {
  workUnitId: string;
} & Pick<Vehicle, 'externalId' | 'model' | 'identificationNumber'>;

export const WorkUnitFutureState = ({
  workUnitId,
  model,
  externalId,
  identificationNumber,
}: WorkUnitFutureStateProps) => {
  const [loading, setLoading] = useState(false);

  const { formatMessage } = useIntl();
  const {
    data: workUnitFutureState,
    isError,
    refetch,
  } = useWorkUnitFutureStateQuery(workUnitId, {
    refetchOnWindowFocus: false,
  });

  const handleRefetch = () => {
    setLoading(true);
    return new Promise((resolve) => {
      setTimeout(() => resolve({}), 2000);
    })
      .then(() => refetch())
      .then(() => setLoading(false));
  };

  if (isError) {
    return (
      <p className="text-sm text-neutral-500 text-center mb-4">
        <FormattedMessage {...messages.weCouldNotCalculateFutureState} />
      </p>
    );
  }

  return (
    <>
      <p className="text-sm text-neutral-500 text-center mb-4">
        <FormattedMessage {...messages.statusLabel} />
      </p>
      <div className="flex justify-center mb-6">
        {workUnitFutureState ? (
          <OperationalStateBadge
            operationalState={workUnitFutureState.operationalState}
          />
        ) : (
          <BadgePlaceholder className="w-32" />
        )}
      </div>
      <div className="flex gap-2">
        <div className="h-14 rounded-lg flex items-center justify-between gap-4 bg-neutral-50 mb-3 px-2 flex-1">
          <div className="flex items-center -ml-2">
            <ModelImage
              className="h-6"
              model={model}
              identificationNumber={identificationNumber}
            />
            <span className="text-xs font-semibold text-neutral-1000">
              {externalId}
            </span>
          </div>
          <div className="flex items-center">
            {workUnitFutureState?.batteryCharge !== undefined && (
              <>
                <BatteryIconLabel
                  charge={workUnitFutureState.batteryCharge}
                  model={model}
                  className="mr-1.5 mt-px text-xs"
                />
                <div className="w-6 h-3">
                  <BatteryIcon
                    model={model}
                    charge={workUnitFutureState.batteryCharge}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <Button
          square
          size="lg"
          loading={loading}
          aria-label={formatMessage(messages.refresh)}
          onClick={handleRefetch}
        >
          <MdCached className="text-2xl" />
        </Button>
      </div>
    </>
  );
};

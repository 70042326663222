import { useContext } from 'react';

import { AutomaticHonkContext } from './AutomaticHonkContext';

export const useAutomaticHonk = () => {
  const automaticHonkContext = useContext(AutomaticHonkContext);

  if (!automaticHonkContext) {
    throw new Error(
      'useAutomaticHonk can be only used within <AutomaticHonkProvider />'
    );
  }

  return automaticHonkContext;
};

import { useCurrentWorkShiftQuery } from '@cooltra/api';
import { Button, TopLoadingBar } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { usePreferredLanguage } from '@cooltra/preferred-language';
import { useCallback } from 'react';
import { setUser } from '@sentry/react';
import { useAuth0 } from '@auth0/auth0-react';
import { classNames } from '@cooltra/utils';

import { Screen, TopNavigation } from '~/common';

import { MyTakenVehiclesItem } from '../MyTakenVehiclesItem/MyTakenVehiclesItem';
import { LanguageItem } from '../LanguageItem/LanguageItem';
import { MyTasksItem } from '../MyTasksItem/MyTasksItem';
import { MyGroupTasksItem } from '../MyGroupTasksItem/MyGroupTasksItem';
import { MyShift } from '../MyShift/MyShift';

import messages from './messages';

export type ProfileInfoProps = {
  onClose: () => void;
};

export const ProfileInfo = ({ onClose }: ProfileInfoProps) => {
  const { logout, user } = useAuth0();
  const { loadingLanguage } = usePreferredLanguage();
  const { data: currentShift } = useCurrentWorkShiftQuery();

  const handleLogOut = useCallback(() => {
    localStorage.clear();
    setUser(null);
    logout({
      logoutParams: {
        returnTo: 'https://www.office.com/estslogout',
      },
    });
  }, [logout]);

  return (
    <Screen
      data-testid="PROFILE_SCREEN"
      header={
        <div
          className={classNames(
            'border-b',
            currentShift ? 'border-success-500' : 'border-neutral-100'
          )}
        >
          {loadingLanguage && <TopLoadingBar />}
          <TopNavigation.Bar
            renderRight={<TopNavigation.Close onClick={onClose} />}
            title={
              <TopNavigation.Title>
                {user?.name || <FormattedMessage {...messages.profile} />}
              </TopNavigation.Title>
            }
          />
        </div>
      }
      content={
        <>
          <ul>
            {currentShift && <MyShift />}
            <MyTakenVehiclesItem />
            <MyGroupTasksItem />
            <MyTasksItem />
            <LanguageItem />
          </ul>
          <div className="p-4 flex flex-col justify-between">
            <Button hidden={!!currentShift} onClick={handleLogOut}>
              <FormattedMessage {...messages.logOut} />
            </Button>
          </div>
        </>
      }
    />
  );
};

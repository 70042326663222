import { defineMessages } from 'react-intl';

export default defineMessages({
  successOnReleasingTheVehicleFromLocation: {
    defaultMessage: 'The vehicle has been successfully released from location',
  },
  releaseFromLocation: {
    defaultMessage: 'Release from location',
  },
  userDoesNotHavePermissionsToReleaseVehicleFromLocation: {
    defaultMessage:
      'You do not have permission to release the vehicle from location',
  },
  somethingWentWrong: {
    defaultMessage: 'Something went wrong!',
  },
  theVehicleCouldNotBeReleasedFromLocation: {
    defaultMessage: "The vehicle couldn't be released from location",
  },
  cannotBeReleasedDueToElectricFeel: {
    defaultMessage:
      'Check in Electric Feel if the vehicle is in a reservation or rental',
  },
});

import { MdCheckCircleOutline } from 'react-icons/md';
import { Icon } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const NoVehiclesFound = () => (
  <div className="text-center py-20 px-16 text-neutral-400">
    <Icon className="text-2xl mb-2 text-neutral-600">
      <MdCheckCircleOutline />
    </Icon>
    <p className="text-neutral-600 text-base">
      <FormattedMessage {...messages.noVehiclesFound} />
    </p>
  </div>
);

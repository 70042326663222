import { System } from '@cooltra/auth-api';
import { Layer, Source } from 'react-map-gl';
import { hoursToMilliseconds } from 'date-fns';
import { MultiPolygon, useGeofenceQuery, VehicleType } from '@cooltra/api';

import { geofenceBorderLayer, geofenceLayer } from './layer';

type GeofencesMapSource = {
  id: string;
  layers: JSX.Element[];
  data: GeoJSON.Feature<any>;
};

export const getGeofencesMapSources = (
  geofences: MultiPolygon[],
  vehicleType: VehicleType
): GeofencesMapSource[] =>
  geofences.map((geofence) => ({
    id: 'geofence',
    data: {
      type: 'Feature',
      properties: {
        vehicleType,
      },
      geometry: geofence,
    },
    layers: [
      <Layer key="geofenceLayer" {...geofenceLayer} />,
      <Layer key="geofenceBorderLayer" {...geofenceBorderLayer} />,
    ],
  }));

export const Geofence = ({
  system,
  isBike,
}: {
  system: System;
  isBike: boolean;
}) => {
  const vehicleType = isBike ? 'BIKE' : 'SCOOTER';
  const { data: geofence } = useGeofenceQuery(
    system,
    {
      vehicleType,
    },
    {
      staleTime: hoursToMilliseconds(8),
    }
  );

  const finalGeofence = geofence?.final;

  if (!finalGeofence) {
    return <></>;
  }

  return (
    <>
      {getGeofencesMapSources([finalGeofence], vehicleType).map((source) => (
        <Source
          key={source.id}
          id={source.id}
          type="geojson"
          data={source.data}
        >
          {source.layers}
        </Source>
      ))}
    </>
  );
};

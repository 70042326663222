import { FormattedMessage, useIntl } from 'react-intl';
import { useReleaseVehicleMutation, Vehicle } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { AxiosError } from 'axios';
import { Button } from '@cooltra/ui';

import { useNotification } from '~/libs/notifications';

import messages from './messages';

export type ReleaseProps = Pick<Vehicle, 'vehicleId'>;

export const Release = ({ vehicleId }: ReleaseProps) => {
  const { formatMessage } = useIntl();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const { isPending, mutate } = useReleaseVehicleMutation(vehicleId, {
    onSuccess: () =>
      addSuccessNotification(formatMessage(messages.successfullyReleased)),
    onError: (error: AxiosError) => {
      let errorNotification;

      switch (getErrorStatus(error)) {
        case 400:
          errorNotification = formatMessage(messages.errorCouldNotBeReleased);
          break;
        case 401:
          errorNotification = formatMessage(
            messages.notAuthorizedToPerformReleaseOnVehicle
          );
          break;
        default:
          errorNotification = formatMessage(messages.somethingWentWrong);
          break;
      }

      addErrorNotification(errorNotification);
    },
  });

  const releaseVehicle = () => mutate();

  return (
    <Button
      className="rounded-full relative z-10"
      size="sm"
      loading={isPending}
      disabled={isPending}
      onClick={releaseVehicle}
    >
      <FormattedMessage {...messages.releaseVehicle} />
    </Button>
  );
};

import { Layer, LayerProps, Source } from 'react-map-gl';
import { useEffect, useState } from 'react';
import { insideCircle } from 'geolocation-utils';

import { useCurrentPosition } from '~/libs/current-position';
import { useWorkUnitVehicle } from '~/hooks';

import { useDirections } from './useDirections';

const directionsLayer: LayerProps = {
  id: 'directions',
  type: 'line',
  source: 'directions',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#0967d3',
    'line-width': 5,
    'line-opacity': 0.75,
  },
};

const detectDetour = (currentPosition: number[], route: number[][]) => {
  if (!currentPosition.length || !route.length) {
    return false;
  } else {
    const radius = 100;
    return !route.find(([longitude, latitude]) =>
      insideCircle(
        { latitude, longitude },
        {
          longitude: currentPosition[0],
          latitude: currentPosition[1],
        },
        radius
      )
    );
  }
};

export const Directions = () => {
  const currentPosition = useCurrentPosition();
  const [origin, setOrigin] = useState<number[]>([]);

  const workUnitVehicle = useWorkUnitVehicle();
  const destination = workUnitVehicle.geoLocation;

  const { data: directions = [] } = useDirections({ origin, destination });

  useEffect(() => {
    if (
      (destination.length && !origin.length) ||
      detectDetour(currentPosition, directions)
    ) {
      setOrigin(currentPosition);
    }
  }, [currentPosition, destination, directions, origin]);

  return !!destination.length && !!origin.length && !!directions.length ? (
    <Source
      id="directions"
      type="geojson"
      data={{
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: directions,
        },
      }}
    >
      <Layer {...directionsLayer} />
    </Source>
  ) : (
    <></>
  );
};

import { defineMessages } from 'react-intl';

export const errorMessages = defineMessages({
  notProperlyConfiguredVehicle: {
    defaultMessage: 'Vehicle is not properly configured',
  },
  conflictInAction: {
    defaultMessage: 'Another action is being processed, please try again',
  },
  permissionError: {
    defaultMessage: 'You do not have permission to perform this action',
  },
  issueWithTelematicsProvider: {
    defaultMessage: "There was an issue with the telematic's provider",
  },
  vehicleIsOffline: {
    defaultMessage: 'The vehicle is offline',
  },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  oneTakenVehicle: {
    defaultMessage: '1 vehicle',
  },
  totalTakenVehicles: {
    defaultMessage:
      '{totalTakenVehicles, plural, one {# vehicle} other {# vehicles}}',
  },
  noTakenVehicles: {
    defaultMessage: 'None',
  },
});

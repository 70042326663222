import { useToggle } from '@cooltra/hooks';
import { PropsWithChildren } from 'react';

import { TrackUserContext } from './TrackUserContext';

export const TrackUserProvider = ({ children }: PropsWithChildren) => {
  const [
    isTrackingUser,
    { toggleOff: stopTrackingUser, toggleOn: startTrackingUser },
  ] = useToggle(true);

  return (
    <TrackUserContext.Provider
      value={{ isTrackingUser, startTrackingUser, stopTrackingUser }}
    >
      {children}
    </TrackUserContext.Provider>
  );
};

import { classNames } from '@cooltra/utils';
import { useEffect, useState } from 'react';

import { useVehicleQuery } from '~/hooks';

import { MapCard } from './MapCard/MapCard';
import { usePrevious } from './usePrevious';

export type VehicleCardMapProps = {
  vehicleId?: string;
};

export const VehicleMapCard = ({ vehicleId }: VehicleCardMapProps) => {
  const [isMounted, mount] = useState(true);

  const previousVehicleId = usePrevious(vehicleId);

  const isOpen = !!vehicleId;

  const { data: vehicle } = useVehicleQuery(vehicleId || '', {
    enabled: !!vehicleId,
  });

  const { data: previousVehicle } = useVehicleQuery(previousVehicleId || '', {
    enabled: false,
  });

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (previousVehicleId && !vehicleId) {
      timer = setTimeout(() => mount(false), 1000);
    } else {
      mount(true);
    }

    return () => {
      timer && clearTimeout(timer);
    };
  }, [previousVehicleId, isMounted, vehicleId]);

  if (!isMounted) {
    return <></>;
  }

  return (
    <div
      data-testid="VEHICLE_MAP_CARD"
      className={classNames(
        'px-3 w-full absolute transition-all duration-300 mb-1',
        isOpen ? 'opacity-100' : 'opacity-0',
        isOpen ? 'bottom-20' : 'bottom-0'
      )}
    >
      {vehicle ? (
        <MapCard {...vehicle} />
      ) : previousVehicle ? (
        <MapCard {...previousVehicle} />
      ) : null}
    </div>
  );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Assignee',
  },
  searchPlaceholder: {
    defaultMessage: 'Search',
  },
  operators: {
    defaultMessage: 'Operators',
  },
  operatorGroups: {
    defaultMessage: 'Operator groups',
  },
});

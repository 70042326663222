import { FormattedMessage } from 'react-intl';
import { Button, Icon } from '@cooltra/ui';
import { MdLock } from 'react-icons/md';
import { useAuth0 } from '@auth0/auth0-react';
import { setUser } from '@sentry/react';

import messages from './messages';

export type NotAuthorizedProps = {
  title?: string;
};

export const NotAuthorized = ({ title }: NotAuthorizedProps) => {
  const { logout } = useAuth0();

  const handleLogOut = () => {
    localStorage.clear();
    setUser(null);

    logout({
      logoutParams: {
        returnTo: 'https://www.office.com/estslogout',
      },
    });
  };

  return (
    <div className="pt-32 px-4 text-center">
      <Icon className="mb-6 text-2xl p-4 rounded-full text-neutral-500 bg-neutral-50">
        <MdLock />
      </Icon>
      <h6 className="text-lg font-semibold mb-1">
        {title || <FormattedMessage {...messages.title} />}
      </h6>
      <p className="text-sm text-neutral-700 mb-6 px-8">
        <FormattedMessage {...messages.content} />
      </p>
      <Button emphasis="high" onClick={handleLogOut}>
        <FormattedMessage {...messages.logout} />
      </Button>
    </div>
  );
};

import { useIntl } from 'react-intl';
import { differenceInMinutes, format } from 'date-fns';

import messages from './messages';

export type TimeAgoProps = {
  ISODate: string;
};

export const TimeAgo = ({ ISODate }: TimeAgoProps) => {
  const { formatMessage } = useIntl();

  const lastSyncDate = new Date(ISODate);
  const minutesAgo = differenceInMinutes(new Date(), lastSyncDate);

  if (minutesAgo == 0) {
    return <>{formatMessage(messages.lessThanAMinuteAgo)}</>;
  }

  if (minutesAgo < 60) {
    return (
      <>
        {formatMessage(messages.minutesAgo, {
          minutes: minutesAgo,
        })}
      </>
    );
  }

  const hoursAgo = Math.floor(minutesAgo / 60);

  if (hoursAgo < 24) {
    return (
      <>
        {formatMessage(messages.hoursAgo, {
          hours: hoursAgo,
        })}
      </>
    );
  }

  const daysAgo = Math.floor(hoursAgo / 24);

  if (daysAgo < 7) {
    return (
      <>
        {formatMessage(messages.daysAgo, {
          days: daysAgo,
        })}
      </>
    );
  }

  const exactDate = format(lastSyncDate, 'dd/MM/yyyy HH:mm');

  return (
    <>
      {formatMessage(messages.onDate, {
        date: exactDate,
      })}
    </>
  );
};

import { VehiclesFiltersExpression } from '../is-preset-match';

import { helmetsTagId, helmetTagId } from './helmetTagIds';

export const lessThanFiveteen: VehiclesFiltersExpression = {
  and: [
    { batteryRange: [0, 15] },
    {
      or: [
        { containsOperationalCondition: 'AVAILABLE' },
        { containsOperationalCondition: 'BLOCKED' },
        { containsOperationalCondition: 'BATTERY_DISCHARGED' },
        { containsOperationalCondition: 'OFFLINE' },
        { containsOperationalCondition: 'NOT_LOCKED' },
        { containsOperationalCondition: 'WORK_IN_PROGRESS' },
        { containsTaskTags: { tagIds: [helmetTagId, helmetsTagId] } },
        {
          isTaskTitleMatch: {
            title: 'B - V - NL - Helmet 03 - Helmet missing',
          },
        },
        {
          isTaskTitleMatch: {
            title: 'B - V - NL - Helmet 04 - Helmets missing (Both)',
          },
        },
        {
          isTaskTitleMatch: { title: 'B - V - NL - Helmet 06 - Helmet broken' },
        },
        {
          isTaskTitleMatch: { title: 'B - V - NL - Helmet 07 - Helmet stolen' },
        },
        {
          isTaskTitleMatch: {
            title: 'B - V - NL - Helmet 08 - Helmets broken (Both)',
          },
        },
        {
          isTaskTitleMatch: {
            title: 'B - V - BE - Helmet 03 - Helmet missing',
          },
        },
        {
          isTaskTitleMatch: {
            title: 'B - V - BE - Helmet 04 - Helmets missing (Both)',
          },
        },
        {
          isTaskTitleMatch: { title: 'B - V - BE - Helmet 06 - Helmet broken' },
        },
        {
          isTaskTitleMatch: { title: 'B - V - BE - Helmet 07 - Helmet stolen' },
        },
        {
          isTaskTitleMatch: {
            title: 'B - V - BE - Helmet 08 - Helmets broken (Both)',
          },
        },
        {
          isTaskTitleMatch: {
            title: 'B - V - NL - Accessories 01 - Hairnets missing',
          },
        },
        {
          isTaskTitleMatch: {
            title: 'B - V - NL - Accessories 02 - Topcase innerliner missing',
          },
        },
        {
          isTaskTitleMatch: {
            title:
              'B - V - NL - Accessories 03 - Topcase innerliner dirty/mouly',
          },
        },
        {
          isTaskTitleMatch: {
            title: 'B - V - NL - Mirror 01 - Mirrors broken or missing',
          },
        },
        {
          isTaskTitleMatch: {
            title: 'B - V - NL - Mirror 03 - Left mirror broken or missing',
          },
        },
        {
          isTaskTitleMatch: {
            title: 'B - V - BE - Accessories 01 - Hairnets missing',
          },
        },
        {
          isTaskTitleMatch: {
            title: 'B - V - BE - Accessories 02 - Topcase innerliner missing',
          },
        },
        {
          isTaskTitleMatch: {
            title:
              'B - V - BE - Accessories 03 - Topcase innerliner dirty/mouly',
          },
        },
        {
          isTaskTitleMatch: {
            title: 'B - V - BE - Mirror 01 - Mirrors broken or missing',
          },
        },
        {
          isTaskTitleMatch: {
            title: 'B - V - BE - Mirror 03 - Left mirror broken or missing',
          },
        },
        {
          isTaskTitleMatch: {
            title: 'B - V - NL - Mirror 02 - Right mirror broken or missing',
          },
        },
        {
          isTaskTitleMatch: {
            title: 'B - V - NL - Phoneholder 01 _ Broken or missing',
          },
        },
        {
          isTaskTitleMatch: {
            title: 'B - V - BE - Mirror 02 - Right mirror broken or missing',
          },
        },
        {
          isTaskTitleMatch: {
            title: 'B - V - BE - Phoneholder 01 _ Broken or missing',
          },
        },
      ],
    },
  ],
};

import { VehicleStatus, Vehicle, OperationalState } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { Divider } from '@cooltra/ui';
import { AxiosError } from 'axios';
import { useRef } from 'react';

import { MiniMapImage } from '~/components/VehicleInfo/MiniMap/MiniMapImage';

export type MiniMapProps = {
  vehicleStatus: VehicleStatus;
  error: AxiosError<unknown, unknown> | null;
  onClick: () => void;
} & Pick<Vehicle, 'operationalState'>;

const bgClassNames: { [key in OperationalState]: string } = {
  OPERATIONAL: 'bg-success-600',
  NOT_OPERATIONAL: 'bg-danger-600',
  IN_MAINTENANCE: 'bg-warning-600',
};

export const MiniMap = ({
  vehicleStatus,
  operationalState,
  error,
  onClick,
}: MiniMapProps) => {
  const vehicleStatusRef = useRef<VehicleStatus>(vehicleStatus);

  if (getErrorStatus(error) === 404 || !bgClassNames[operationalState]) {
    return <></>;
  }

  const { geoLocation } = vehicleStatusRef.current;
  if (geoLocation === null) {
    return <></>;
  }

  return (
    <div className="relative">
      <button
        onClick={onClick}
        className="absolute z-20 inset-0 flex items-center justify-center"
      />
      <MiniMapImage />
      <Divider />
    </div>
  );
};

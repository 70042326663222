import { defineMessages } from 'react-intl';

export default defineMessages({
  map: {
    defaultMessage: 'Map view',
  },
  list: {
    defaultMessage: 'Vehicle list',
  },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  rental: {
    defaultMessage: 'Rental {rentalId}',
  },
  dropOff: {
    defaultMessage: 'Drop off',
  },
  inProgress: {
    defaultMessage: 'In progress',
  },
  duration: {
    defaultMessage: 'Duration',
  },
});

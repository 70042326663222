import { defineMessages } from 'react-intl';

export default defineMessages({
  assignee: {
    defaultMessage: 'Assignee',
  },
  updated: {
    defaultMessage: 'Updated',
  },
  close: {
    defaultMessage: 'Close',
  },
});

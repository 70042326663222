import { MdErrorOutline } from 'react-icons/md';
import { Icon } from '@cooltra/ui';
import { useIntl } from 'react-intl';

import messages from './messages';

export const ListItemErrorIcon = () => {
  const { formatMessage } = useIntl();
  return (
    <Icon
      className="text-danger-500 text-xl mt-1"
      aria-label={formatMessage(messages.error)}
    >
      <MdErrorOutline />
    </Icon>
  );
};

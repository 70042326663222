import { MdBattery4Bar } from 'react-icons/md';
import { useIntl } from 'react-intl';

import { MapButton, MapButtonProps } from '../MapButton';

import messages from './messages';

export const SwitchViewButton = (props: MapButtonProps) => {
  const { formatMessage } = useIntl();

  return (
    <MapButton aria-label={formatMessage(messages.switch)} {...props}>
      <MdBattery4Bar />
    </MapButton>
  );
};

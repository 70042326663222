import { FormattedMessage } from 'react-intl';
import { FormTextareaField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';

import messages from './messages';

export const Description = () => (
  <FormTextareaField
    name="description"
    id="task-description"
    rows={2}
    label={
      <InputLabel htmlFor="task-description">
        <span>
          <FormattedMessage {...messages.description} />
        </span>
        <span className="font-normal ml-1">
          (<FormattedMessage {...messages.optional} />)
        </span>
      </InputLabel>
    }
  />
);

import { defineMessages } from 'react-intl';

export default defineMessages({
  moveVehicle: {
    defaultMessage:
      'Are you sure you want to move {allTakenVehicles} to {destination}?',
  },
  allTakenVehicles: {
    defaultMessage: 'ALL taken vehicles',
  },
  changeLocation: {
    defaultMessage: 'Change location',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  locationChangedSuccessfully: {
    defaultMessage: "All vehicle's location has been successfully changed.",
  },
  userDoesNotHavePermissionsToMoveVehicles: {
    defaultMessage:
      'You are not authorized to perform a take action on all vehicles.',
  },
  someVehiclesHaveNotBeenMoved: {
    defaultMessage:
      'Some vehicles could not be moved. Check your taken vehicles and try again.',
  },
});

import { useAuth0 } from '@auth0/auth0-react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@cooltra/ui';
import { MdPlayArrow } from 'react-icons/md';
import { useStartWorkShiftMutation } from '@cooltra/api';

import { useNotification } from '~/libs/notifications';

import mapExample from './assets/map-example.png';
import messages from './messages';
import { RoundRouterLink } from './RoundRouterLink';

export const StartShift = () => {
  const { user } = useAuth0();
  const { mutateAsync, isPending } = useStartWorkShiftMutation();
  const { addErrorNotification } = useNotification();
  const userName = user?.name;

  const startShift = () => mutateAsync().catch(() => addErrorNotification());

  return (
    <div className="h-full relative">
      <img
        src={mapExample}
        alt="Start shift map"
        className="blur-sm w-full min-h-screen absolute top-0 left-0 -z-10"
      />
      <div className="w-full h-full">
        <div className="w-52 h-full text-center mx-auto flex flex-col items-center justify-center gap-4">
          <h2 className="flex flex-col text-center text-2xl font-bold">
            <span>
              <FormattedMessage {...messages.welcome} />
            </span>
            <span>{userName}!</span>
          </h2>
          <span className="text-md">
            <FormattedMessage {...messages.enter} />
          </span>
          <Button
            size="lg"
            variant="success"
            emphasis="high"
            className="mt-9 rounded-full"
            leadingIcon={<MdPlayArrow className="text-4xl text-success-200" />}
            loading={isPending}
            disabled={isPending}
            onClick={startShift}
          >
            <FormattedMessage {...messages.startShift} />
          </Button>
        </div>
        <RoundRouterLink className="top-4 right-4" to="/profile">
          <img
            src={user?.picture}
            className="max-w-full rounded-full"
            alt={user?.name}
          />
        </RoundRouterLink>
      </div>
    </div>
  );
};

import {
  usePatchTaskMutation,
  useTaskQuery,
  useVehicleQuery,
} from '@cooltra/api';
import { ToggleField } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';

import messages from '~/common/Tasks/TaskInfo/BlockingToggle/messages';
import { useNotification } from '~/libs/notifications';

export type BlockingToggleProps = {
  taskId: string;
};

export const BlockingToggle = ({ taskId }: BlockingToggleProps) => {
  const { formatMessage } = useIntl();
  const patchTaskMutation = usePatchTaskMutation(taskId);
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const { data: task } = useTaskQuery(taskId);
  const { data: vehicle } = useVehicleQuery(task?.vehicleId || '', {
    enabled: !!task,
  });
  const toggle = async () => {
    if (task && vehicle) {
      return patchTaskMutation
        .mutateAsync({
          blocking: !task.blocking,
          description: task.description,
          operatorGroupId: task.operatorGroupId,
          operatorId: task.operatorId,
          problemReportId: task.problemReportId,
          tagIds: task.tagIds,
          title: task.title,
          vehicleExternalId: vehicle?.externalId,
          vehicleId: task.vehicleId,
        })
        .then(() => {
          addSuccessNotification(formatMessage(messages.success));
        })
        .catch(() => {
          addErrorNotification();
        });
    }
  };

  const label = (
    <span className="block text-sm text-neutral-300 mb-0.5">
      <FormattedMessage {...messages.blocking} />
    </span>
  );

  return (
    <ToggleField
      id="toggle-blocking"
      label={label}
      checked={task?.blocking}
      data-testid="BLOCKING_TOGGLE"
      onChange={toggle}
    />
  );
};

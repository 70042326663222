import { useContext } from 'react';

import { SelectVehicleContext } from './SelectVehicleContext';

export const useSelectVehicle = () => {
  const selectVehicleContext = useContext(SelectVehicleContext);
  if (!selectVehicleContext) {
    throw new Error(
      'useSelectVehicle can be only used within <SelectVehicleProvider />'
    );
  }
  return selectVehicleContext;
};

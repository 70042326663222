import { Layer } from 'react-map-gl';

import { VehicleMapSource } from '~/common';

import {
  groupInMaintenanceVehiclesCounterLayer,
  groupInMaintenanceVehiclesLayer,
  groupNotOperationalVehiclesCounterLayer,
  groupNotOperationalVehiclesLayer,
  groupOperationalVehiclesCounterLayer,
  groupOperationalVehiclesLayer,
  groupRentedVehiclesCounterLayer,
  groupRentedVehiclesLayer,
  groupReservedVehiclesCounterLayer,
  groupReservedVehiclesLayer,
  singleInMaintenanceVehicleLayer,
  singleNotOperationalVehicleLayer,
  singleOperationalVehicleLayer,
  singleRentedVehicleLayer,
  singleReservedVehicleLayer,
  singleSelectedInMaintenanceVehicleLayer,
  singleSelectedNotOperationalVehicleLayer,
  singleSelectedOperationalVehicleLayer,
  singleSelectedRentedVehicleLayer,
  singleSelectedReservedVehicleLayer,
} from '../layers/higher-layers';
import {
  singleVehicleExpandedBackgroundLayer,
  singleVehicleExpandedBigBackgroundLayer,
  singleVehicleExpandedBigLayer,
  singleVehicleExpandedLayer,
} from '../layers/lower-layers';

export const vehiclesMapSources: VehicleMapSource[] = [
  {
    id: 'group-operational-vehicles',
    filter: [
      'all',
      ['==', ['get', 'vehicleOperationalState'], 'OPERATIONAL'],
      [
        'any',
        ['==', ['get', 'isMatchWithFilters'], true],
        ['==', ['get', 'isThereAnActiveWorkUnit'], true],
      ],
      ['==', ['get', 'isRented'], false],
      ['==', ['get', 'isReserved'], false],
    ],
    cluster: true,
    layers: [
      <Layer
        key="groupOperationalVehiclesLayer"
        {...groupOperationalVehiclesLayer}
      />,
      <Layer
        key="groupOperationalVehiclesCounterLayer"
        {...groupOperationalVehiclesCounterLayer}
      />,
    ],
  },
  {
    id: 'group-rented-vehicles',
    filter: [
      'all',
      [
        'any',
        ['==', ['get', 'isMatchWithFilters'], true],
        ['==', ['get', 'isThereAnActiveWorkUnit'], true],
      ],
      ['==', ['get', 'isRented'], true],
    ],
    cluster: true,
    layers: [
      <Layer key="groupRentedVehiclesLayer" {...groupRentedVehiclesLayer} />,
      <Layer
        key="groupRentedVehiclesCounterLayer"
        {...groupRentedVehiclesCounterLayer}
      />,
    ],
  },
  {
    id: 'group-reserved-vehicles',
    filter: [
      'all',
      [
        'any',
        ['==', ['get', 'isMatchWithFilters'], true],
        ['==', ['get', 'isThereAnActiveWorkUnit'], true],
      ],
      ['==', ['get', 'isReserved'], true],
    ],
    cluster: true,
    layers: [
      <Layer
        key="groupReservedVehiclesLayer"
        {...groupReservedVehiclesLayer}
      />,
      <Layer
        key="groupReservedVehiclesCounterLayer"
        {...groupReservedVehiclesCounterLayer}
      />,
    ],
  },
  {
    id: 'group-in-maintenance-vehicles',
    filter: [
      'all',
      ['==', ['get', 'vehicleOperationalState'], 'IN_MAINTENANCE'],
      [
        'any',
        ['==', ['get', 'isMatchWithFilters'], true],
        ['==', ['get', 'isThereAnActiveWorkUnit'], true],
      ],
      ['==', ['get', 'isRented'], false],
      ['==', ['get', 'isReserved'], false],
    ],
    cluster: true,
    layers: [
      <Layer
        key="groupInMaintenanceVehiclesLayer"
        {...groupInMaintenanceVehiclesLayer}
      />,
      <Layer
        key="groupInMaintenanceVehiclesCounterLayer"
        {...groupInMaintenanceVehiclesCounterLayer}
      />,
    ],
  },
  {
    id: 'group-not-operational-vehicles',
    filter: [
      'all',
      ['==', ['get', 'vehicleOperationalState'], 'NOT_OPERATIONAL'],
      [
        'any',
        ['==', ['get', 'isMatchWithFilters'], true],
        ['==', ['get', 'isThereAnActiveWorkUnit'], true],
      ],
      ['==', ['get', 'isRented'], false],
      ['==', ['get', 'isReserved'], false],
    ],
    cluster: true,
    layers: [
      <Layer
        key="groupNotOperationalVehiclesLayer"
        {...groupNotOperationalVehiclesLayer}
      />,
      <Layer
        key="groupNotOperationalVehiclesCounterLayer"
        {...groupNotOperationalVehiclesCounterLayer}
      />,
    ],
  },
  {
    id: 'high-view-of-operational-single-vehicles',
    filter: [
      'all',
      ['==', ['get', 'vehicleOperationalState'], 'OPERATIONAL'],
      [
        'any',
        ['==', ['get', 'isMatchWithFilters'], true],
        ['==', ['get', 'isThereAnActiveWorkUnit'], true],
      ],
      ['==', ['get', 'isRented'], false],
      ['==', ['get', 'isReserved'], false],
    ],
    cluster: true,
    layers: [
      <Layer
        key="singleSelectedOperationalVehicleLayer"
        {...singleSelectedOperationalVehicleLayer}
      />,
      <Layer
        key="singleOperationalVehicleLayer"
        {...singleOperationalVehicleLayer}
      />,
    ],
  },
  {
    id: 'high-view-of-rented-single-vehicles',
    filter: [
      'all',
      [
        'any',
        ['==', ['get', 'isMatchWithFilters'], true],
        ['==', ['get', 'isThereAnActiveWorkUnit'], true],
      ],
      ['==', ['get', 'isRented'], true],
    ],
    cluster: true,
    layers: [
      <Layer
        key="singleSelectedRentedVehicleLayer"
        {...singleSelectedRentedVehicleLayer}
      />,
      <Layer key="singleRentedVehicleLayer" {...singleRentedVehicleLayer} />,
    ],
  },
  {
    id: 'high-view-of-reserved-single-vehicles',
    filter: [
      'all',
      [
        'any',
        ['==', ['get', 'isMatchWithFilters'], true],
        ['==', ['get', 'isThereAnActiveWorkUnit'], true],
      ],
      ['==', ['get', 'isReserved'], true],
    ],
    cluster: true,
    layers: [
      <Layer
        key="singleSelectedReservedVehicleLayer"
        {...singleSelectedReservedVehicleLayer}
      />,
      <Layer
        key="singleReservedVehicleLayer"
        {...singleReservedVehicleLayer}
      />,
    ],
  },
  {
    id: 'high-view-of-in-maintenance-single-vehicles',
    filter: [
      'all',
      ['==', ['get', 'vehicleOperationalState'], 'IN_MAINTENANCE'],
      [
        'any',
        ['==', ['get', 'isMatchWithFilters'], true],
        ['==', ['get', 'isThereAnActiveWorkUnit'], true],
      ],
      ['==', ['get', 'isRented'], false],
      ['==', ['get', 'isReserved'], false],
    ],
    cluster: true,
    layers: [
      <Layer
        key="singleSelectedInMaintenanceVehicleLayer"
        {...singleSelectedInMaintenanceVehicleLayer}
      />,
      <Layer
        key="singleInMaintenanceVehicleLayer"
        {...singleInMaintenanceVehicleLayer}
      />,
    ],
  },
  {
    id: 'high-view-of-not-operational-single-vehicles',
    filter: [
      'all',
      ['==', ['get', 'vehicleOperationalState'], 'NOT_OPERATIONAL'],
      [
        'any',
        ['==', ['get', 'isMatchWithFilters'], true],
        ['==', ['get', 'isThereAnActiveWorkUnit'], true],
      ],
      ['==', ['get', 'isRented'], false],
      ['==', ['get', 'isReserved'], false],
    ],
    cluster: true,
    layers: [
      <Layer
        key="singleSelectedNotOperationalVehicleLayer"
        {...singleSelectedNotOperationalVehicleLayer}
      />,
      <Layer
        key="singleNotOperationalVehicleLayer"
        {...singleNotOperationalVehicleLayer}
      />,
    ],
  },
  {
    id: 'operational-state-view-single-vehicles',
    cluster: false,
    layers: [
      <Layer
        key="singleVehicleExpandedBackgroundLayer"
        {...singleVehicleExpandedBackgroundLayer}
      />,
      <Layer
        key="singleVehicleExpandedLayer"
        {...singleVehicleExpandedLayer}
      />,
      <Layer
        key="singleVehicleExpandedBigBackgroundLayer"
        {...singleVehicleExpandedBigBackgroundLayer}
      />,
      <Layer
        key="singleVehicleExpandedBigLayer"
        {...singleVehicleExpandedBigLayer}
      />,
    ],
  },
];

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Map error',
  },
  content: {
    defaultMessage:
      'There was a problem loading the map, try refreshing the page',
  },
  reload: {
    defaultMessage: 'Reload',
  },
});

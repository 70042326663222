import { defineMessages } from 'react-intl';

export default defineMessages({
  ignitionOn: {
    defaultMessage: 'Ignition On',
  },
  successOnIgnitionOnVehicle: {
    defaultMessage: 'Ignition On action has been triggered successfully',
  },
});

import { Model, Vehicle } from '@cooltra/api';
import { HTMLAttributes } from 'react';
import { isNewAskoll } from '@cooltra/vehicle-utils';

import { useModelLabel } from '../models';

import arcade from './assets/arcade.png';
import askollOld from './assets/askoll-old.png';
import askollNew from './assets/askoll-new.png';
import govecs from './assets/govecs.png';
import niuN1s from './assets/niu_n1s.png';
import niuN1s25 from './assets/niu_n1s_25.png';

const modelPhotos: { [key in Model]: string } = {
  ARCADE_X2C: arcade,
  ASKOLL_ES2: askollOld,
  ASKOLL_ES2_B2B: askollOld,
  GOVECS_DIRT: govecs,
  GOVECS_GOS: govecs,
  NIU_N1S: niuN1s,
  NIU_N1S_25: niuN1s25,
  SEGWAY_A300: govecs,
};

export type ModelImageProps = Omit<
  HTMLAttributes<HTMLImageElement>,
  'src' | 'alt'
> &
  Pick<Vehicle, 'model' | 'identificationNumber'>;

export const ModelImage = ({
  model,
  identificationNumber,
  ...rest
}: ModelImageProps) => {
  const { getModelLabel } = useModelLabel();
  const isNewAskol = isNewAskoll({ model, identificationNumber });

  return (
    <img
      alt={getModelLabel(model)}
      src={isNewAskol ? askollNew : modelPhotos[model]}
      {...rest}
    />
  );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  welcome: {
    defaultMessage: 'Welcome,',
  },
  startShift: {
    defaultMessage: 'Start shift',
  },
  enter: {
    defaultMessage: 'Start shift to see vehicles, tasks and start your work.',
  },
});

import { FormattedMessage } from 'react-intl';
import { VehicleListItem } from '@cooltra/api';
import { InfiniteList } from '@cooltra/ui';
import { useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { isKeywordMatch } from '@cooltra/vehicle-utils';

import { NoSearchResults, Screen, TopNavigation } from '~/common';
import { usePagination, useUserSystems } from '~/hooks';

import { SearchInput } from '../SearchInput/SearchInput';
import { SearchVehicleCard } from '../SearchVehicleCard';
import { FilterRouteProps } from '../../types';

import messages from './messages';

export const Search = ({ onClose, onBack }: FilterRouteProps) => {
  const { getPaginatedData, goToNextPage } = usePagination();
  const [keyword, setKeyword] = useState('');

  const queryClient = useQueryClient();
  const systems = useUserSystems();

  const allVehicles = useMemo(
    () =>
      queryClient.getQueryData<VehicleListItem[]>([
        'vehicles',
        { system: systems },
      ]) || [],
    [queryClient, systems]
  );

  const filteredVehicles = useMemo(
    () => allVehicles.filter((vehicle) => isKeywordMatch(vehicle, { keyword })),
    [allVehicles, keyword]
  );

  const data = useMemo(
    () => getPaginatedData(filteredVehicles),
    [filteredVehicles, getPaginatedData]
  );

  return (
    <Screen
      data-testid="VEHICLE_SEARCH_SCREEN"
      header={
        <div className="border-b border-neutral-100">
          <TopNavigation.Bar
            renderLeft={<TopNavigation.Back onClick={onBack} />}
            renderRight={<TopNavigation.Close onClick={onClose} />}
            title={
              <TopNavigation.Title>
                <FormattedMessage {...messages.title} />
              </TopNavigation.Title>
            }
          />
          <div className="px-4 mb-4">
            <SearchInput setValue={setKeyword} />
          </div>
        </div>
      }
      contentId="scrollable-vehicle-search-list"
      content={
        <div className="pt-4 pb-16 overflow-x-hidden">
          <InfiniteList
            className="gap-3 px-4"
            loadingRows={0}
            isLoading={false}
            isFetchingNextPage={false}
            fetchMore={goToNextPage}
            idProp="vehicleId"
            data={data}
            scrollableTarget="scrollable-vehicle-search-list"
            renderEmptyMessage={<NoSearchResults />}
            renderRow={(vehicle) => (
              <SearchVehicleCard onSelect={onClose} vehicle={vehicle} />
            )}
          />
        </div>
      }
    />
  );
};

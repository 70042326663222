import { Fragment, ReactNode } from 'react';

import {
  FieldButtonBase,
  FieldButtonBaseProps,
} from './FieldButtonBase/FieldButtonBase';

export type MultiSelectFieldButtonProps = Omit<
  FieldButtonBaseProps,
  'children'
> & {
  value: string[];
  renderItem: (item: string) => ReactNode;
};

export const MultiSelectFieldButton = ({
  value,
  renderItem,
  className,
  ...rest
}: MultiSelectFieldButtonProps) => (
  <FieldButtonBase showClearButton={!!value} {...rest}>
    <div className="flex flex-wrap gap-1">
      {value.map((item) => (
        <Fragment key={item}>{renderItem(item)}</Fragment>
      ))}
    </div>
  </FieldButtonBase>
);

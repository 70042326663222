import { defineMessages } from 'react-intl';

export default defineMessages({
  power: {
    defaultMessage: 'Power',
  },
  powerOn: {
    defaultMessage: 'On',
  },
  powerOff: {
    defaultMessage: 'Off',
  },
});

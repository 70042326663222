import {
  groupInMaintenanceVehiclesLayer,
  groupNotOperationalVehiclesLayer,
  groupOperationalVehiclesLayer,
  singleInMaintenanceVehicleLayer,
  singleNotOperationalVehicleLayer,
  singleOperationalVehicleLayer,
} from './higher-layers';
import {
  singleVehicleExpandedBigLayer,
  singleVehicleExpandedLayer,
} from './lower-layers';

const interactiveUnclusteredLayerIds = [
  String(singleVehicleExpandedLayer.id),
  String(singleVehicleExpandedBigLayer.id),
  String(singleOperationalVehicleLayer.id),
  String(singleInMaintenanceVehicleLayer.id),
  String(singleNotOperationalVehicleLayer.id),
];

const interactiveClusteredLayerIds = [
  String(groupOperationalVehiclesLayer.id),
  String(groupInMaintenanceVehiclesLayer.id),
  String(groupNotOperationalVehiclesLayer.id),
];

export const layers = [
  ...interactiveUnclusteredLayerIds,
  ...interactiveClusteredLayerIds,
];

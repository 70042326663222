import { classNames } from '@cooltra/utils';
import { HTMLAttributes } from 'react';

export type SelectItemTextProps = HTMLAttributes<HTMLSpanElement>;

export const SelectItemText = ({ className, ...rest }: SelectItemTextProps) => (
  <span
    className={classNames('text-neutral-600 text-sm', className)}
    {...rest}
  />
);

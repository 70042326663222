import { defineMessages } from 'react-intl';
import { Model } from '@cooltra/api';

export const models = defineMessages<Model>({
  ARCADE_X2C: {
    defaultMessage: 'Arcade X2C',
  },
  ASKOLL_ES2: {
    defaultMessage: 'Askoll eS2',
  },
  ASKOLL_ES2_B2B: {
    defaultMessage: 'Askoll eS2 B2B',
  },
  GOVECS_DIRT: {
    defaultMessage: 'Govecs Dirt',
  },
  GOVECS_GOS: {
    defaultMessage: 'Govecs GO!S',
  },
  NIU_N1S: {
    defaultMessage: 'NIU N1S',
  },
  NIU_N1S_25: {
    defaultMessage: 'NIU N1S 25',
  },
  SEGWAY_A300: {
    defaultMessage: 'Segway A300',
  },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'You have {myTakenVehiclesCount} vehicles taken',
  },
  areYouSure: {
    defaultMessage:
      'Are you sure you want to end shift and leave those vehicles?',
  },
  endShift: {
    defaultMessage: 'See taken vehicles',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
});

import { VehicleTaskListItem } from '@cooltra/api';
import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { DateTime } from '../../DateTime/DateTime';
import { LabelAndValue } from '../../LabelAndValue/LabelAndValue';
import { ListItemLink } from '../../ListItem';
import { OperatorOrGroup } from '../../Operator';
import { TaskTags } from '../TaskTags/TaskTags';

import messages from './messages';

type CardTask = Pick<
  VehicleTaskListItem,
  | 'tagIds'
  | 'operatorId'
  | 'operatorGroupId'
  | 'id'
  | 'updatedAt'
  | 'title'
  | 'blocking'
> & {
  createdAt?: string;
};

export type TaskCardProps = {
  task: CardTask;
  onCloseTask: (taskId: string) => void;
  to: string;
  testId?: string;
};

export const TaskCard = ({
  task: {
    createdAt,
    tagIds,
    operatorId,
    operatorGroupId,
    id,
    updatedAt,
    title,
    blocking,
  },
  onCloseTask,
  to,
  testId,
}: TaskCardProps) => {
  const closeTask = () => onCloseTask(id);

  const date = updatedAt || createdAt;

  return (
    <ListItemLink
      to={to}
      className="h-auto px-4 py-5"
      interactive
      testId={testId}
    >
      <div className="flex justify-between items-center mb-4">
        <p className="text-base font-semibold pr-4 whitespace-normal">
          {title}
        </p>
        <Button
          onClick={closeTask}
          emphasis="high"
          className="rounded-full m-1"
          size="sm"
        >
          <FormattedMessage {...messages.close} />
        </Button>
      </div>
      <div className="grid grid-cols-2 gap-2 mb-4">
        <LabelAndValue
          label={<FormattedMessage {...messages.assignee} />}
          value={
            <OperatorOrGroup
              operatorGroupId={operatorGroupId}
              operatorId={operatorId}
            />
          }
        />
        {date && (
          <LabelAndValue
            label={<FormattedMessage {...messages.updated} />}
            value={<DateTime date={date} />}
          />
        )}
      </div>
      <TaskTags tagIds={tagIds} blocking={blocking} />
    </ListItemLink>
  );
};

import {
  useTaskMutation,
  useTaskTemplatesQuery,
  useWorkUnitQuery,
  Vehicle,
} from '@cooltra/api';
import { Form, FormProvider } from '@cooltra/form';
import { useRouteState } from '@cooltra/navigation';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { Screen, TopNavigation } from '~/common';
import { useNotification } from '~/libs/notifications';
import { useVehiclePolling } from '~/libs/polling-data';
import { useUserSystems } from '~/hooks';

import {
  TaskFormValues,
  taskInitialFormValues,
  validateTaskForm,
} from './task-form';
import * as TaskField from './TaskFields';
import messages from './messages';
import { SubmitButton } from './SubmitButton/SubmitButton';

export type CreateTaskProps = {
  onClose: () => void;
  vehicle: Vehicle;
  comesFromMap: boolean;
};

export const CreateTask = ({
  onClose,
  vehicle: { externalId, vehicleId },
  comesFromMap,
}: CreateTaskProps) => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const routeState = useRouteState<'title'>();
  const { activatePollingVehicle } = useVehiclePolling();

  const { data: workUnit } = useWorkUnitQuery();

  const systems = useUserSystems();
  const { data: templates } = useTaskTemplatesQuery({
    // use the first home system because should be the same for the HomeSystems of the role
    system: systems[0],
    type: 'task',
  });

  const selectedTemplate = templates?.find(
    (template) => template.title === routeState?.title
  );

  const { mutateAsync } = useTaskMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['vehicles'] }),
  });

  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleSubmit = (values: TaskFormValues) =>
    mutateAsync({
      vehicleId,
      vehicleExternalId: externalId,
      problemReportId: null,
      workUnitId: workUnit ? workUnit.workUnitId : null,
      ...values,
    })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['vehicle-tasks', vehicleId],
        });
        if (values.blocking) {
          activatePollingVehicle(vehicleId);
        }
        addSuccessNotification(intl.formatMessage(messages.success));
        if (comesFromMap) {
          onClose();
        } else {
          navigate(`/vehicles/${vehicleId}`);
        }
      })
      .catch(() => addErrorNotification());

  const onBack = () => navigate(`/vehicles/${vehicleId}/tasks/templates`);

  return (
    <FormProvider
      initialValues={
        selectedTemplate || {
          ...taskInitialFormValues,
          title: routeState?.title || '',
        }
      }
      validate={validateTaskForm(intl)}
      onSubmit={handleSubmit}
    >
      <Screen
        header={
          <div className="bg-neutral-0 border-b border-neutral-100">
            <TopNavigation.Bar
              renderLeft={<TopNavigation.Back onClick={onBack} />}
              renderRight={<TopNavigation.Close onClick={onClose} />}
              title={
                <TopNavigation.Title>
                  <FormattedMessage {...messages.heading} />
                </TopNavigation.Title>
              }
            />
          </div>
        }
        content={
          <Form>
            <div className="flex flex-col gap-5 px-4 pt-4">
              <TaskField.Title />
              <TaskField.Assignee />
              <TaskField.Operator />
              <TaskField.Tags />
              <TaskField.Description />
              <TaskField.Blocking />
            </div>
            <SubmitButton />
          </Form>
        }
      />
    </FormProvider>
  );
};

import { LayerProps } from 'react-map-gl';
import { Expression } from 'mapbox-gl';

import { getCircleColorExpressionByOperationalState } from './layer-utils';

const circleRadiusForGroupVehicles: Expression = [
  'step',
  ['get', 'point_count'],
  10,
  3,
  12,
  5,
  14,
  8,
  17,
  10,
  20,
];

export const groupOperationalVehiclesLayer: LayerProps = {
  id: 'group-operational-vehicles',
  type: 'circle',
  source: 'group-operational-vehicles',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': '#189A1C',
    'circle-radius': circleRadiusForGroupVehicles,
  },
};

export const groupRentedVehiclesLayer: LayerProps = {
  id: 'group-rented-vehicles',
  type: 'circle',
  source: 'group-rented-vehicles',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': '#0967D3',
    'circle-radius': circleRadiusForGroupVehicles,
  },
};

export const groupReservedVehiclesLayer: LayerProps = {
  id: 'group-reserved-vehicles',
  type: 'circle',
  source: 'group-reserved-vehicles',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': '#49A4F3',
    'circle-radius': circleRadiusForGroupVehicles,
  },
};

export const groupInMaintenanceVehiclesLayer: LayerProps = {
  id: 'group-in-maintenance-vehicles',
  type: 'circle',
  source: 'group-in-maintenance-vehicles',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': '#F0B428',
    'circle-radius': circleRadiusForGroupVehicles,
  },
};

export const groupNotOperationalVehiclesLayer: LayerProps = {
  id: 'group-not-operational-vehicles',
  type: 'circle',
  source: 'group-not-operational-vehicles',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': '#E12D39',
    'circle-radius': circleRadiusForGroupVehicles,
  },
};

export const singleVehicleLayer: LayerProps = {
  type: 'circle',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': getCircleColorExpressionByOperationalState(),
    'circle-radius': 7,
    'circle-stroke-width': 0,
    'circle-stroke-color': '#111',
  },
};

export const singleOperationalVehicleLayer: LayerProps = {
  ...singleVehicleLayer,
  id: 'single-operational-vehicle',
  source: 'high-view-of-operational-single-vehicles',
};

export const singleRentedVehicleLayer: LayerProps = {
  ...singleVehicleLayer,
  id: 'single-rented-vehicle',
  source: 'high-view-of-rented-single-vehicles',
};

export const singleReservedVehicleLayer: LayerProps = {
  ...singleVehicleLayer,
  id: 'single-reserved-vehicle',
  source: 'high-view-of-reserved-single-vehicles',
};

export const singleInMaintenanceVehicleLayer: LayerProps = {
  ...singleVehicleLayer,
  id: 'single-in-maintenance-vehicle',
  source: 'high-view-of-in-maintenance-single-vehicles',
};

export const singleNotOperationalVehicleLayer: LayerProps = {
  ...singleVehicleLayer,
  id: 'single-not-operational-vehicle',
  source: 'high-view-of-not-operational-single-vehicles',
};

export const singleSelectedVehicleLayer: LayerProps = {
  type: 'circle',
  filter: [
    'all',
    ['!', ['has', 'point_count']],
    ['==', ['get', 'isSelected'], true],
  ],
  paint: {
    'circle-color': '#fff',
    'circle-radius': 10,
    'circle-stroke-width': 2,
    'circle-stroke-color': getCircleColorExpressionByOperationalState(),
  },
};

export const singleSelectedOperationalVehicleLayer: LayerProps = {
  ...singleSelectedVehicleLayer,
  id: 'single-selected-operational-vehicle',
  source: 'high-view-of-operational-single-vehicles',
};

export const singleSelectedRentedVehicleLayer: LayerProps = {
  ...singleSelectedVehicleLayer,
  id: 'single-selected-rented-vehicle',
  source: 'high-view-of-rented-single-vehicles',
};

export const singleSelectedReservedVehicleLayer: LayerProps = {
  ...singleSelectedVehicleLayer,
  id: 'single-selected-reserved-vehicle',
  source: 'high-view-of-reserved-single-vehicles',
};

export const singleSelectedInMaintenanceVehicleLayer: LayerProps = {
  ...singleSelectedVehicleLayer,
  id: 'single-selected-in-maintenance-vehicle',
  source: 'high-view-of-in-maintenance-single-vehicles',
};

export const singleSelectedNotOperationalVehicleLayer: LayerProps = {
  ...singleSelectedVehicleLayer,
  id: 'single-selected-not-operational-vehicle',
  source: 'high-view-of-not-operational-single-vehicles',
};

const groupVehiclesCounterGenericLayer: LayerProps = {
  type: 'symbol',
  filter: ['has', 'point_count'],
  paint: {
    'text-color': '#fff',
  },
  layout: {
    'icon-allow-overlap': true,
    'text-allow-overlap': true,
    'icon-ignore-placement': true,
    'text-ignore-placement': true,
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': [
      'step',
      ['get', 'point_count'],
      12,
      3,
      14,
      5,
      16,
      8,
      19,
      10,
      22,
    ],
  },
};

export const groupOperationalVehiclesCounterLayer: LayerProps = {
  ...groupVehiclesCounterGenericLayer,
  id: 'group-operational-vehicles-counter',
  source: 'group-operational-vehicles',
};

export const groupRentedVehiclesCounterLayer: LayerProps = {
  ...groupVehiclesCounterGenericLayer,
  id: 'group-rented-vehicles-counter',
  source: 'group-rented-vehicles',
};

export const groupReservedVehiclesCounterLayer: LayerProps = {
  ...groupVehiclesCounterGenericLayer,
  id: 'group-reserved-vehicles-counter',
  source: 'group-reserved-vehicles',
};

export const groupInMaintenanceVehiclesCounterLayer: LayerProps = {
  ...groupVehiclesCounterGenericLayer,
  id: 'group-in-maintenance-vehicles-counter',
  source: 'group-in-maintenance-vehicles',
};

export const groupNotOperationalVehiclesCounterLayer: LayerProps = {
  ...groupVehiclesCounterGenericLayer,
  id: 'group-not-operational-vehicles-counter',
  source: 'group-not-operational-vehicles',
};

import { createContext, Dispatch, SetStateAction } from 'react';
import { ViewState } from 'react-map-gl';

export type BaseViewState = Pick<ViewState, 'latitude' | 'longitude' | 'zoom'>;

export type MapCenterContextShape = {
  viewState: BaseViewState;
  updateViewState: Dispatch<SetStateAction<BaseViewState>>;
};

export const MapCenterContext = createContext<
  MapCenterContextShape | undefined
>(undefined);

import {
  FetchVehiclesRequestParams,
  useVehiclesQuery,
  VehicleListItem,
} from '@cooltra/api';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useMemo } from 'react';

import { useUserSystems } from '~/hooks';

import { createIsPresetMatch } from './presets/is-preset-match';
import { presets } from './presets/presets';
import { isVehicleAMatch } from './regular-filters/regular-filters';
import { useVehiclesFilters } from './useVehiclesFilters';

export type ExtendedVehicleListItem = VehicleListItem & {
  isMatch: boolean;
};

export const useFilteredVehiclesQuery = (
  options: Omit<
    UseQueryOptions<
      VehicleListItem[],
      AxiosError,
      VehicleListItem[],
      [string, FetchVehiclesRequestParams]
    >,
    'queryKey'
  > = {}
) => {
  const systems = useUserSystems();
  const { values } = useVehiclesFilters();

  const {
    data: allVehicles = [],
    isSuccess,
    ...vehiclesQuery
  } = useVehiclesQuery(
    {
      system: systems,
    },
    options
  );

  const { data: presetData } = useQuery({
    queryKey: ['preset-vehicles', values.preset],
    queryFn: () => {
      const isPresetMatch = createIsPresetMatch(
        values.preset ? presets[values.preset] : { and: [] }
      );
      return Promise.all(
        (allVehicles || []).map((vehicle) => {
          const { status, ...restVehicle } = vehicle;
          return isPresetMatch({
            ...restVehicle,
            batteryCharge: status?.batteryCharge?.value,
          }).then((isMatch) => ({
            ...vehicle,
            isMatch,
          }));
        })
      );
    },
    enabled: !!values.preset && isSuccess,
  });

  const filteredPresetData = useMemo(() => {
    const filteredVehicles: ExtendedVehicleListItem[] = (
      presetData || []
    ).filter(({ isMatch }) => isMatch);
    return {
      vehicles: presetData || [],
      filteredVehicles,
      filteredVehiclesTotal: filteredVehicles.length,
    };
  }, [presetData]);

  const filteredData = useMemo(() => {
    const vehicles: ExtendedVehicleListItem[] = (allVehicles || []).map(
      (vehicle) => ({
        ...vehicle,
        isMatch: isVehicleAMatch(vehicle, values),
      })
    );
    const filteredVehicles: ExtendedVehicleListItem[] = vehicles.filter(
      ({ isMatch }) => isMatch
    );
    return {
      vehicles,
      filteredVehicles,
      filteredVehiclesTotal: filteredVehicles.length,
    };
  }, [allVehicles, values]);

  return {
    data: values.preset ? filteredPresetData : filteredData,
    ...vehiclesQuery,
  };
};

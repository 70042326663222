import { useOperatorsQuery } from '@cooltra/auth-api';

export type OperatorProps = {
  operatorId?: string;
};

export const Operator = ({ operatorId }: OperatorProps) => {
  const { data } = useOperatorsQuery();

  if (!data) {
    return <></>;
  }

  return (
    <>{data.find(({ externalId }) => operatorId === externalId)?.name || '-'}</>
  );
};

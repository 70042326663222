import { MdChevronRight } from 'react-icons/md';
import { Icon } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type ListItemBaseProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  hasChevron?: boolean;
  isSelected?: boolean;
  isDeselected?: boolean;
  className?: string;
};

export const ListItemBase = ({
  children,
  leadingIcon,
  trailingIcon,
  hasChevron = false,
  isSelected = false,
  isDeselected = false,
  className,
  ...rest
}: ListItemBaseProps) => (
  <div
    className={classNames(
      'h-16 px-4 w-full transition-colors',
      !isSelected && !isDeselected && 'bg-neutral-0',
      isSelected && 'bg-primary-50',
      isDeselected && 'bg-danger-50',
      className
    )}
    {...rest}
  >
    <div
      className="flex items-center h-full"
      data-testid={
        isSelected
          ? 'SELECTED_LIST_ITEM'
          : isDeselected
          ? 'DESELECTED_LIST_ITEM'
          : undefined
      }
    >
      {leadingIcon}
      <div className="flex-1 text-left truncate">{children}</div>
      {hasChevron ? (
        <Icon className="text-neutral-500 text-2xl ml-2">
          <MdChevronRight />
        </Icon>
      ) : (
        trailingIcon
      )}
    </div>
  </div>
);

import { defineMessages } from 'react-intl';

export default defineMessages({
  openSaddle: {
    defaultMessage: 'Open saddle',
  },
  successOnOpenSaddle: {
    defaultMessage: 'Open saddle action has been triggered successfully',
  },
});

import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { CurrentPositionContext } from './CurrentPositionContext';

export const CurrentPositionProvider = ({ children }: PropsWithChildren) => {
  const [position, setPosition] = useState<number[]>([]);

  useEffect(() => {
    const geo = navigator.geolocation;

    if (!geo) {
      return;
    }
    const watcher = geo.watchPosition(
      ({ coords }) => setPosition([coords.longitude, coords.latitude]),
      undefined,
      {
        enableHighAccuracy: true,
      }
    );
    return () => geo.clearWatch(watcher);
  }, []);

  const value = useMemo(() => position, [position]);

  return (
    <CurrentPositionContext.Provider value={value}>
      {children}
    </CurrentPositionContext.Provider>
  );
};

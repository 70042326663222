import { Back } from './Back/Back';
import { Close } from './Close/Close';
import { Bar } from './Bar';
import { Title } from './Title';

export const TopNavigation = {
  Back,
  Bar,
  Close,
  Title,
};

import { classNames } from '@cooltra/utils';
import { ButtonHTMLAttributes } from 'react';

export type TaskTextButtonVariant = 'primary' | 'danger';

const variantClasses: { [key in TaskTextButtonVariant]: string } = {
  primary: 'text-primary-700',
  danger: 'text-danger-500',
};

export type TaskTextButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant: TaskTextButtonVariant;
};

export const TaskTextButton = ({
  className,
  disabled,
  variant,
  ...rest
}: TaskTextButtonProps) => (
  <button
    disabled={disabled}
    className={classNames(
      'flex h-10 items-end text-sm tap-highlight',
      disabled ? 'text-neutral-300' : variantClasses[variant],
      className
    )}
    {...rest}
  />
);

import { PropsWithChildren } from 'react';
import { FormProvider } from '@cooltra/form';
import { BatteryChargeLevel } from '@cooltra/vehicle-utils';

import {
  VehiclesFiltersFormValues,
  vehiclesFiltersInitialValues,
} from './vehicles-filters-form';
import {
  VehiclesFilterFormPersistor,
  localStorageKey,
} from './VehiclesFilterFormPersistor';

const batteryChargeLevelsList: BatteryChargeLevel[] = [
  'low',
  'medium',
  'neutral',
  'high',
];

export const VehiclesFiltersForm = ({ children }: PropsWithChildren) => {
  const persistedState = localStorage.getItem(localStorageKey);
  const parsedPersistedState: VehiclesFiltersFormValues =
    persistedState && JSON.parse(persistedState);
  //TODO temp fix for battery charge level
  const vehiclesFiltersInitialValuesWithCorrectBatteryChargeLevel = {
    ...parsedPersistedState,
    batteryChargeLevel:
      parsedPersistedState && parsedPersistedState.batteryChargeLevel
        ? parsedPersistedState.batteryChargeLevel.filter((level) =>
            batteryChargeLevelsList.includes(level)
          )
        : [],
  };

  const initialValues: VehiclesFiltersFormValues = persistedState
    ? {
        ...vehiclesFiltersInitialValues,
        ...vehiclesFiltersInitialValuesWithCorrectBatteryChargeLevel,
      }
    : {
        ...vehiclesFiltersInitialValues,
        operationalCondition: ['!RENTED', '!RESERVED'],
      };

  return (
    <FormProvider initialValues={initialValues}>
      <VehiclesFilterFormPersistor />
      {children}
    </FormProvider>
  );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Rental {rentalId}',
  },
  rental: {
    defaultMessage: 'Rental',
  },
  duration: {
    defaultMessage: 'Duration',
  },
  totalDistance: {
    defaultMessage: 'Total distance',
  },
  pickUp: {
    defaultMessage: 'Pick up',
  },
  dropOff: {
    defaultMessage: 'Drop off',
  },
  inProgress: {
    defaultMessage: 'In progress',
  },
  now: {
    defaultMessage: 'Now',
  },
  viewTimeline: {
    defaultMessage: 'View timeline',
  },
});

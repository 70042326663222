import { defineMessages } from 'react-intl';

export default defineMessages({
  singleRoleLabel: {
    defaultMessage: '{operatorGroupName} tasks',
  },
  multipleRolesLabel: {
    defaultMessage: 'Group tasks',
  },
});

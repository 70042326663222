import { defineMessages } from 'react-intl';

export default defineMessages({
  description: {
    defaultMessage: 'Description',
  },
  created: {
    defaultMessage: 'Created',
  },
  reportType: {
    defaultMessage: 'Report type',
  },
  attachedImages: {
    defaultMessage: 'Attached images',
  },
});

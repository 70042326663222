import { useTaskQuery } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { TopLoadingBar } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';

import { useVehicleQuery } from '~/hooks';
import {
  Error,
  Screen,
  TopNavigation,
  CloseTaskModal,
  TaskComments,
  TaskProblemReport,
  TaskHeader,
  TaskInfo,
} from '~/common';
import { useSelectVehicle } from '~/libs/select-vehicle';

import messages from './messages';

export type MyTaskProps = {
  onClose: () => void;
};

export const MyTask = ({ onClose }: MyTaskProps) => {
  const navigate = useNavigate();
  const { selectVehicle } = useSelectVehicle();
  const { taskId = '' } = useParams<'taskId'>();

  const [isModalOpen, { toggleOff, toggleOn }] = useToggle();

  const { data: task, isLoading, isError } = useTaskQuery(taskId);

  const vehicleId = task?.vehicleId || '';

  const { data: vehicle } = useVehicleQuery(vehicleId, {
    enabled: !!vehicleId,
  });

  const goBack = () => navigate(-1);

  const handleOnVehicleClick = () => {
    onClose();
    vehicle?.vehicleId && selectVehicle(vehicle.vehicleId);
  };

  if (isError) {
    return (
      <Screen
        data-testid="VEHICLE_TASK_SCREEN"
        header={
          <div className="border-b border-neutral-100">
            <TopNavigation.Bar
              renderLeft={<TopNavigation.Back onClick={goBack} />}
              renderRight={<TopNavigation.Close onClick={onClose} />}
              title={
                <TopNavigation.Title>
                  <FormattedMessage {...messages.title} />
                </TopNavigation.Title>
              }
            />
          </div>
        }
        content={<Error />}
      />
    );
  }

  return (
    <Screen
      data-testid="MY_TASK_SCREEN"
      header={
        <>
          <CloseTaskModal
            onSuccess={goBack}
            isOpen={isModalOpen}
            onClose={toggleOff}
            taskId={taskId}
          />
          <div className="border-b border-neutral-100">
            {isLoading && <TopLoadingBar />}
            <TopNavigation.Bar
              renderLeft={<TopNavigation.Back onClick={goBack} />}
              renderRight={<TopNavigation.Close onClick={onClose} />}
              title={
                <TopNavigation.Title>
                  <FormattedMessage {...messages.title} />
                </TopNavigation.Title>
              }
            />
          </div>
        </>
      }
      content={
        task &&
        vehicle && (
          <>
            <TaskHeader
              task={task}
              vehicle={vehicle}
              onCloseTask={toggleOn}
              onVehicleClick={handleOnVehicleClick}
            />
            <div className="flex flex-col gap-4 px-4 py-6">
              <TaskInfo task={task} />
              {task.problemReportId && (
                <TaskProblemReport
                  to={`/profile/problem-reports/${task.problemReportId}`}
                  problemReportId={task.problemReportId}
                />
              )}
            </div>
            <TaskComments taskId={task.taskId} />
          </>
        )
      }
    />
  );
};

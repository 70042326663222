import { memo } from 'react';
import { Source } from 'react-map-gl';

import { VehicleFeature, VehicleMapSource } from './types';

export type SourcesProps = {
  vehiclesMapSources: VehicleMapSource[];
  vehicleFeatures: VehicleFeature[];
};

export const Sources = memo(
  ({ vehicleFeatures, vehiclesMapSources }: SourcesProps) => {
    return (
      <>
        {vehiclesMapSources.map((source) => (
          <Source
            key={source.id}
            id={source.id}
            type="geojson"
            data={{
              type: 'FeatureCollection',
              features: vehicleFeatures,
            }}
            filter={source.filter}
            cluster={source.cluster}
            clusterMaxZoom={14}
            clusterMinPoints={10}
            clusterRadius={40}
          >
            {source.layers}
          </Source>
        ))}
      </>
    );
  }
);

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'You have open work in a vehicle',
  },
  beforeEnd: {
    defaultMessage: 'Before end the shift you need to finish the work unit',
  },
  seeWorkUnit: {
    defaultMessage: 'See open work unit',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
});

import { useState } from 'react';
import { useVehicleTasksQuery } from '@cooltra/api';
import { MdAdd } from 'react-icons/md';
import { TopLoadingBar } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useToggle } from '@cooltra/hooks';
import { RouterButton } from '@cooltra/navigation';

import {
  TopNavigation,
  Screen,
  Error,
  TaskCard,
  CloseTaskModal,
  NoTasksFound,
} from '~/common';

import messages from './messages';

export type TasksProps = {
  onClose: () => void;
  vehicleId: string;
};

export const Tasks = ({ onClose, vehicleId }: TasksProps) => {
  const navigate = useNavigate();

  const [isOpen, { toggleOff, toggleOn }] = useToggle();
  const [selectedTask, selectTask] = useState('');

  const {
    data: tasks = [],
    isLoading,
    isError,
  } = useVehicleTasksQuery(
    vehicleId,
    { open: 'true' },
    { enabled: !!vehicleId }
  );

  const handleDeselectingTask = () => {
    selectTask('');
  };

  const handleSelectingTask = (taskId: string) => {
    toggleOn();
    selectTask(taskId);
  };

  const goBack = () => navigate(`/vehicles/${vehicleId}`);

  const onTaskCloseSuccess = () => {
    navigate(`/vehicles/${vehicleId}/tasks`);
  };

  const renderContent = () => {
    if (isError) {
      return <Error />;
    }

    const noTasksFound = !isLoading && !tasks.length;
    if (noTasksFound) {
      return <NoTasksFound />;
    }

    return (
      <ul className="pb-24">
        {tasks
          .sort((task) => (task.blocking ? -1 : 1))
          .map((task) => (
            <li key={task.id}>
              <TaskCard
                to={`/vehicles/${vehicleId}/tasks/${task.id}`}
                onCloseTask={handleSelectingTask}
                task={task}
                testId={task.id}
              />
            </li>
          ))}
      </ul>
    );
  };

  return (
    <>
      <CloseTaskModal
        onSuccess={onTaskCloseSuccess}
        isOpen={isOpen}
        onClose={toggleOff}
        onAfterClose={handleDeselectingTask}
        taskId={selectedTask}
      />
      <Screen
        data-testid="TASKS_SCREEN"
        header={
          <>
            {isLoading && <TopLoadingBar />}
            <div className="bg-neutral-0 border-b border-neutral-100">
              <TopNavigation.Bar
                renderLeft={<TopNavigation.Back onClick={goBack} />}
                renderRight={<TopNavigation.Close onClick={onClose} />}
                title={
                  <TopNavigation.Title>
                    <FormattedMessage {...messages.title} />
                  </TopNavigation.Title>
                }
              />
            </div>
          </>
        }
        content={renderContent()}
      />
      <RouterButton
        to="templates"
        emphasis="high"
        className="whitespace-nowrap rounded-full fab-button"
        leadingIcon={<MdAdd className="text-lg -ml-1" />}
      >
        <FormattedMessage {...messages.addTask} />
      </RouterButton>
    </>
  );
};

import { Button, Modal, ModalProps } from '@cooltra/ui';
import { useLocationsQuery, useTransferVehicleMutation } from '@cooltra/api';
import { AxiosError } from 'axios';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useVehicleQuery, useUserSystems } from '~/hooks';
import { useVehiclePolling } from '~/libs/polling-data';
import { useNotification } from '~/libs/notifications';

import messages from './messages';
import { getErrorMessageForMovingVehicle } from './errors';

export type ChangeLocationModalProps = Pick<
  ModalProps,
  'isOpen' | 'onAfterClose'
> & {
  closeModal: () => void;
  newLocationId: string;
};

export const ChangeLocationModal = ({
  isOpen,
  onAfterClose,
  closeModal,
  newLocationId,
}: ChangeLocationModalProps) => {
  const { vehicleId = '' } = useParams<'vehicleId'>();
  const { activatePollingVehicle } = useVehiclePolling();
  const systems = useUserSystems();

  const { mutate, isPending } = useTransferVehicleMutation(vehicleId);
  const { data: vehicle } = useVehicleQuery(vehicleId, {
    enabled: false,
  });
  const { data: locations } = useLocationsQuery(
    { system: systems },
    { enabled: false }
  );

  const { formatMessage } = useIntl();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const selectedLocation = locations?.find(
    (location) => location.locationId === newLocationId
  );

  const transferVehicleToNewLocation = () => {
    const handleOnError = (error: AxiosError) => {
      const errorMessageFromStatusCode = getErrorMessageForMovingVehicle(
        error.response
      );

      addErrorNotification(
        errorMessageFromStatusCode.avoidTranslation
          ? errorMessageFromStatusCode.defaultMessage
          : formatMessage(errorMessageFromStatusCode)
      );
    };

    const handleOnSuccess = () => {
      addSuccessNotification(
        formatMessage(messages.locationChangedSuccessfully)
      );
      activatePollingVehicle(vehicleId);
    };

    mutate(
      {
        locationId: newLocationId,
      },
      {
        onError: handleOnError,
        onSuccess: handleOnSuccess,
        onSettled: closeModal,
      }
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      onAfterClose={onAfterClose}
      shouldReturnFocusAfterClose={false}
      hideHeader
      className="mx-10 rounded-3xl"
    >
      <div className="py-10 p-7" data-testid="CHANGE_VEHICLE_LOCATION_MODAL">
        <p className="mb-7 text-center">
          <FormattedMessage
            {...messages.moveVehicle}
            values={{
              externalId: (
                <span className="font-semibold">#{vehicle?.externalId}</span>
              ),
              destination: (
                <span className="font-semibold">{selectedLocation?.name}</span>
              ),
            }}
          />
        </p>
        <div className="flex flex-col gap-5">
          <Button
            loading={isPending}
            className="rounded-full"
            onClick={transferVehicleToNewLocation}
            emphasis="high"
          >
            <FormattedMessage {...messages.changeLocation} />
          </Button>
          <Button
            className="rounded-full"
            onClick={closeModal}
            emphasis="medium"
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

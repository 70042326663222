import { TopLoadingBar } from '@cooltra/ui';
import { Route, Routes } from 'react-router-dom';
import { useCurrentWorkShiftQuery } from '@cooltra/api';

import { getEnvVariable } from '~/utils/environment';
import { VehiclesFiltersForm } from '~/libs/vehicles-filters';
import { SelectVehicleProvider } from '~/libs/select-vehicle';
import { SystemPermissionCheck } from '~/libs/preferred-system';
import { PollVehicleProvider } from '~/libs/polling-data';
import { MapCenterProvider } from '~/libs/map-center';
import { TrackUserProvider } from '~/libs/track-user';
import { AutomaticHonkProvider } from '~/libs/automatic-honk';
import { CurrentPositionProvider } from '~/libs/current-position';
import Vehicles from '~/components/Vehicles';
import FilterRoutes from '~/components/Filters';
import Profile from '~/components/Profile/Profile';
import { ProfileWithoutWorkShiftStarted } from '~/components/Profile/ProfileWithoutWorkShiftStarted';
import { Vehicle } from '~/components/Vehicle/Vehicle';
import { StartShift } from '~/components/Shift';

import { CookiesModal } from './Cookies/CookiesModal';
import { PrefetchQueries } from './PrefetchQueries';

export const Landing = () => {
  const { data: currentShift, isLoading } = useCurrentWorkShiftQuery({
    retry: false,
    refetchInterval: 1000 * 60,
  });

  if (isLoading) {
    return <TopLoadingBar />;
  }

  if (!currentShift) {
    return (
      <SystemPermissionCheck>
        <StartShift />
        <Routes>
          <Route
            path="/profile/*"
            element={<ProfileWithoutWorkShiftStarted />}
          />
        </Routes>
      </SystemPermissionCheck>
    );
  }

  return (
    <SystemPermissionCheck>
      <PrefetchQueries />
      <MapCenterProvider>
        <VehiclesFiltersForm>
          <TrackUserProvider>
            <SelectVehicleProvider>
              <PollVehicleProvider>
                <AutomaticHonkProvider>
                  <CurrentPositionProvider>
                    <Routes>
                      <Route path="/" element={<Vehicles />}>
                        <Route path="/filters/*" element={<FilterRoutes />} />
                        <Route path="/profile/*" element={<Profile />} />
                        <Route
                          path="/vehicles/:vehicleId/*"
                          element={<Vehicle />}
                        />
                      </Route>
                    </Routes>
                  </CurrentPositionProvider>
                  {getEnvVariable('NODE_ENV') === 'production' && (
                    <CookiesModal />
                  )}
                </AutomaticHonkProvider>
              </PollVehicleProvider>
            </SelectVehicleProvider>
          </TrackUserProvider>
        </VehiclesFiltersForm>
      </MapCenterProvider>
    </SystemPermissionCheck>
  );
};

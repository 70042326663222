import { defineMessages } from 'react-intl';

export default defineMessages({
  actions: {
    defaultMessage: 'Actions',
  },
  transferAll: {
    defaultMessage: 'Transfer all',
  },
});

import { useTaskQuery, Vehicle } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { TopLoadingBar } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';

import {
  Error,
  Screen,
  TopNavigation,
  CloseTaskModal,
  TaskComments,
  TaskProblemReport,
  TaskHeader,
  TaskInfo,
} from '~/common';

import messages from './messages';

export type TaskProps = {
  onClose: () => void;
  vehicle: Vehicle;
};

export const Task = ({ onClose, vehicle }: TaskProps) => {
  const navigate = useNavigate();
  const { taskId = '' } = useParams<'taskId'>();

  const [isModalOpen, { toggleOff, toggleOn }] = useToggle();

  const { data: task, isLoading, isError } = useTaskQuery(taskId);

  const { vehicleId } = vehicle;

  const goBack = () => navigate(`/vehicles/${vehicleId}/tasks`);
  const onTaskCloseSuccess = () => navigate(`/vehicles/${vehicleId}/tasks`);
  const handleOnVehicleClick = () => navigate(`/vehicles/${vehicleId}`);

  if (isError) {
    return (
      <Screen
        data-testid="VEHICLE_TASK_SCREEN"
        header={
          <div className="border-b border-neutral-100">
            <TopNavigation.Bar
              renderLeft={<TopNavigation.Back onClick={goBack} />}
              renderRight={<TopNavigation.Close onClick={onClose} />}
              title={
                <TopNavigation.Title>
                  <FormattedMessage {...messages.title} />
                </TopNavigation.Title>
              }
            />
          </div>
        }
        content={<Error />}
      />
    );
  }

  return (
    <Screen
      data-testid="VEHICLE_TASK_SCREEN"
      header={
        <>
          <CloseTaskModal
            onSuccess={onTaskCloseSuccess}
            isOpen={isModalOpen}
            onClose={toggleOff}
            taskId={taskId}
          />
          <div className="border-b border-neutral-100">
            {isLoading && <TopLoadingBar />}
            <TopNavigation.Bar
              renderLeft={<TopNavigation.Back onClick={goBack} />}
              renderRight={<TopNavigation.Close onClick={onClose} />}
              title={
                <TopNavigation.Title>
                  <FormattedMessage {...messages.title} />
                </TopNavigation.Title>
              }
            />
          </div>
        </>
      }
      content={
        task && (
          <>
            <TaskHeader
              task={task}
              vehicle={vehicle}
              onCloseTask={toggleOn}
              onVehicleClick={handleOnVehicleClick}
            />
            <div className="flex flex-col gap-4 px-4 py-6">
              <TaskInfo task={task} />
              {task.problemReportId && (
                <TaskProblemReport
                  to={`/vehicles/${vehicleId}/problem-reports/${task.problemReportId}`}
                  problemReportId={task.problemReportId}
                />
              )}
            </div>
            <TaskComments taskId={task.taskId} />
          </>
        )
      }
    />
  );
};

import { LineLayout, LinePaint } from 'mapbox-gl';
import { Layer, Source } from 'react-map-gl';

export type MapLineProps = {
  sourceId: string;
  layerId?: string;
  paint?: LinePaint;
  layout?: LineLayout;
  coordinates: number[][];
};

export const MapLine = ({
  sourceId,
  layerId,
  paint = {},
  coordinates,
}: MapLineProps) => (
  <Source
    id={sourceId}
    type="geojson"
    data={{
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates,
      },
    }}
  >
    <Layer
      id={layerId}
      source={sourceId}
      type="line"
      paint={paint}
      layout={{
        'line-join': 'round',
        'line-cap': 'round',
      }}
    />
  </Source>
);

import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { shortenId } from '@cooltra/utils';
import { useRentalQuery, useVehicleStatusQuery } from '@cooltra/api';

import { Error, Screen, TopNavigation } from '~/common';

import messages from './messages';
import { RentalContent } from './RentalContent';

export type RentalProps = {
  onClose: () => void;
  vehicleId: string;
};

export const Rental = ({ onClose, vehicleId }: RentalProps) => {
  const navigate = useNavigate();
  const { rentalId = '' } = useParams<'rentalId'>();

  const onBack = () => navigate(`/vehicles/${vehicleId}/rentals`);

  const { data: vehicleStatus } = useVehicleStatusQuery(vehicleId, {
    retry: false,
  });

  const {
    isError,
    isLoading,
    data: rental,
  } = useRentalQuery(rentalId, { enabled: !!vehicleId && !!rentalId });

  const renderContent = useCallback(() => {
    if (isError) {
      return <Error />;
    }

    if (isLoading) {
      return <></>;
    }

    if (!rental) {
      return <></>;
    }

    return <RentalContent rental={rental} vehicleStatus={vehicleStatus} />;
  }, [isError, isLoading, rental, vehicleStatus]);

  return (
    <Screen
      data-testid="RENTAL_SCREEN"
      header={
        <>
          <div className="bg-neutral-0 border-b border-neutral-100">
            <TopNavigation.Bar
              renderLeft={<TopNavigation.Back onClick={onBack} />}
              renderRight={<TopNavigation.Close onClick={onClose} />}
              title={
                <TopNavigation.Title>
                  <FormattedMessage
                    {...messages.title}
                    values={{ rentalId: `#${shortenId(rentalId)}` }}
                  />
                </TopNavigation.Title>
              }
            />
          </div>
        </>
      }
      content={renderContent()}
    />
  );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  optional: {
    defaultMessage: 'optional',
  },
  clear: {
    defaultMessage: 'Clear',
  },
});

import { useField } from '@cooltra/form';

import { MultiSelect, MultiSelectOption, MultiSelectProps } from '~/common';
import { useVehiclesFilters } from '~/libs/vehicles-filters';

export type FiltersFormMultiSelectProps<Value extends string> = Omit<
  MultiSelectProps<Value>,
  'value' | 'onChange'
> & {
  name: string;
};

export function FiltersFormMultiSelect<Value extends string>({
  name,
  ...rest
}: FiltersFormMultiSelectProps<Value>) {
  const { setValues } = useVehiclesFilters();
  const { value } = useField(name);
  const handleChange = (values: MultiSelectOption<Value>[]) => {
    setValues({
      preset: undefined,
      [name]: values.map(({ value }) => value),
    });
  };
  return <MultiSelect value={value} onChange={handleChange} {...rest} />;
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  blocking: {
    defaultMessage: 'Blocking',
  },
  success: {
    defaultMessage: 'You have successfully updated the task!',
  },
});

import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const NoSearchResults = () => (
  <div className="text-center pt-20 pb-10 px-16 text-neutral-400">
    <h2 className="text-base mb-2">
      <FormattedMessage {...messages.title} />
    </h2>
    <p className="text-sm">
      <FormattedMessage {...messages.content} />
    </p>
  </div>
);

import { AxiosResponse } from 'axios';

import messages from './messages';

export const getErrorMessageForReleasingVehicles = (
  errorResponse: AxiosResponse | undefined
): { defaultMessage: string; avoidTranslation?: boolean } | undefined => {
  switch (errorResponse?.status) {
    case 401:
    case 403:
      return messages.userDoesNotHavePermissionsToReleaseVehicles;
    default:
      return undefined;
  }
};

import { useState } from 'react';
import { VehicleType } from '@cooltra/api';

const localStorageKey = 'geofence-vehicle-type';

export const useSwitchGeofence = (): [VehicleType, () => void] => {
  const getPreferredVehicleType = (): VehicleType => {
    const savedVehicleType: VehicleType | null = localStorage.getItem(
      localStorageKey
    ) as VehicleType;

    return savedVehicleType || 'SCOOTER';
  };

  const [vehicleType, setVehicleType] = useState<VehicleType>(
    getPreferredVehicleType()
  );

  const toggleVehicleType = () => {
    const nextVehicleType: VehicleType =
      vehicleType === 'SCOOTER' ? 'BIKE' : 'SCOOTER';

    localStorage.setItem(localStorageKey, nextVehicleType);
    setVehicleType(nextVehicleType);
  };

  return [vehicleType, toggleVehicleType];
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  releaseVehicle: {
    defaultMessage: 'Release',
  },
  somethingWentWrong: {
    defaultMessage: 'Something went wrong!',
  },
  successfullyReleased: {
    defaultMessage: 'The vehicle has been successfully released',
  },
  errorCouldNotBeReleased: {
    defaultMessage: "The vehicle couldn't be released",
  },
  notAuthorizedToPerformReleaseOnVehicle: {
    defaultMessage:
      'You are not authorized to perform a release action on a vehicle',
  },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Locations',
  },
  searchPlaceholder: {
    defaultMessage: 'Search locations',
  },
});

import { OperationalState } from '@cooltra/api';

type OperationalConditionStatusPointProps = {
  operationalState: OperationalState;
};

export const getStatusPointClassNameBgColor = (
  operationalState: OperationalState
) => {
  let variantBgColor = 'neutral';

  switch (operationalState) {
    case 'OPERATIONAL':
      variantBgColor = 'bg-success-200';
      break;
    case 'NOT_OPERATIONAL':
      variantBgColor = 'bg-danger-200';
      break;
    case 'IN_MAINTENANCE':
      variantBgColor = 'bg-warning-400';
      break;
  }

  return variantBgColor;
};

export const OperationalStateStatusPoint = ({
  operationalState,
}: OperationalConditionStatusPointProps) => {
  return (
    <div
      data-testid={`${operationalState}_STATUS_POINT`}
      className={`w-2 h-2 rounded-full ${getStatusPointClassNameBgColor(
        operationalState
      )}`}
    />
  );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  gpsReboot: {
    defaultMessage: 'Reset GPS',
  },
  successOnGpsRebootVehicle: {
    defaultMessage: 'Reset GPS action has been triggered successfully',
  },
});

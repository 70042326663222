import { AxiosError } from 'axios';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, ModalProps } from '@cooltra/ui';
import { useMyTakenVehicles, useReleaseVehiclesMutation } from '@cooltra/api';

import { useNotification } from '~/libs/notifications';

import { getErrorMessageForReleasingVehicles } from './errors';
import messages from './messages';

export type ConfirmationModalProps = Pick<ModalProps, 'isOpen'> & {
  closeModal: () => void;
  onFinishSuccessfully: () => void;
};

export const ConfirmationModal = ({
  isOpen,
  closeModal,
  onFinishSuccessfully,
}: ConfirmationModalProps) => {
  const { formatMessage } = useIntl();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const { data: myVehiclesTakenIds, isLoading: isMyTakenVehiclesLoading } =
    useMyTakenVehicles({
      enabled: false,
    });
  const { mutate, isPending } = useReleaseVehiclesMutation();

  const releaseVehicles = () => {
    const handleOnError = (error: AxiosError) => {
      const errorMessageFromStatusCode = getErrorMessageForReleasingVehicles(
        error.response
      );

      if (!errorMessageFromStatusCode) {
        addErrorNotification();
        return;
      }

      addErrorNotification(formatMessage(errorMessageFromStatusCode));
    };

    const handleOnSuccess = () => {
      addSuccessNotification(
        formatMessage(messages.vehiclesReleasedSuccessfully)
      );
      onFinishSuccessfully();
    };

    mutate(
      {
        vehicleIds: myVehiclesTakenIds || [],
      },
      {
        onError: handleOnError,
        onSuccess: handleOnSuccess,
        onSettled: closeModal,
      }
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      shouldReturnFocusAfterClose={false}
      hideHeader
      className="mx-10 rounded-3xl"
    >
      <div className="py-10 p-7" data-testid="RELEASE_ALL_CONFIRMATION_MODAL">
        <p className="mb-7 text-center">
          <FormattedMessage {...messages.areYouSureYouWantToReleaseVehicles} />
        </p>
        <div className="flex flex-col gap-5">
          <Button
            loading={isMyTakenVehiclesLoading || isPending}
            className="rounded-full"
            onClick={releaseVehicles}
            emphasis="high"
          >
            <FormattedMessage {...messages.releaseVehicles} />
          </Button>
          <Button
            className="rounded-full"
            onClick={closeModal}
            emphasis="medium"
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

import { Layer, Source } from 'react-map-gl';
import { MultiPolygon, VehicleType } from '@cooltra/api';

import {
  geofenceBorderLayer,
  geofenceLayer,
} from '~/common/Map/Geofence/layer';

type GeofencesMapSource = {
  id: string;
  layers: JSX.Element[];
  data: any[];
};

export const getGeofencesMapSource = (
  geofence: MultiPolygon,
  vehicleType: VehicleType
): GeofencesMapSource => ({
  id: 'geofences',
  data: [
    {
      type: 'Feature',
      properties: {
        vehicleType,
      },
      geometry: geofence,
    },
  ],
  layers: [
    <Layer key="geofenceLayer" {...geofenceLayer} />,
    <Layer key="geofenceBorderLayer" {...geofenceBorderLayer} />,
  ],
});

type GeofencesProps = {
  geofences: MultiPolygon;
  vehicleType: VehicleType;
};

export const Geofences = ({ geofences, vehicleType }: GeofencesProps) => {
  const source = getGeofencesMapSource(geofences, vehicleType);

  return (
    <Source
      key={source.id}
      id={source.id}
      type="geojson"
      buffer={10}
      data={{
        type: 'FeatureCollection',
        features: source.data,
      }}
    >
      {source.layers}
    </Source>
  );
};

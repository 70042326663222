import { VehicleListItem } from '@cooltra/api';
import { CardButton } from '@cooltra/ui';

import { ModelImage, OperationalStateStatusPoint } from '~/common';
import { useSelectVehicle } from '~/libs/select-vehicle';

export type SearchVehicleCardProps = {
  vehicle: VehicleListItem;
  onSelect: () => void;
};

export const SearchVehicleCard = ({
  vehicle: {
    vehicleId,
    model,
    identificationNumber,
    externalId,
    telematicsId,
    operationalState,
    licensePlate,
  },
  onSelect,
}: SearchVehicleCardProps) => {
  const { selectVehicle } = useSelectVehicle();

  const handleSelect = () => {
    selectVehicle(vehicleId);
    onSelect();
  };

  return (
    <CardButton
      onClick={handleSelect}
      className="flex gap-2 items-start justify-start text-left bg-neutral-0 p-4"
    >
      <div className="h-6 flex flex-col justify-center">
        <OperationalStateStatusPoint operationalState={operationalState} />
      </div>
      <ModelImage
        model={model}
        identificationNumber={identificationNumber}
        className="h-6"
      />
      <div>
        <span className="flex text-sm text-neutral-1000 font-semibold items-center mb-1">
          {externalId}
        </span>
        <span className="text-xs text-neutral-500 block mb-0.5">
          {licensePlate}
        </span>
        <span className="text-xs text-neutral-500 block">{telematicsId}</span>
      </div>
    </CardButton>
  );
};

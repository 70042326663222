import { Task } from '@cooltra/api';
import { useOperatorsQuery } from '@cooltra/auth-api';
import { Placeholder, Tooltip } from '@cooltra/ui';
import { forwardRef } from 'react';

import { RoundBackground } from './RoundBackground';

export type RoundOperatorInitialsProps = Pick<Task, 'operatorId'>;

export const RoundOperatorInitials = forwardRef<
  HTMLButtonElement,
  RoundOperatorInitialsProps
>(({ operatorId }, ref) => {
  const { data } = useOperatorsQuery();

  if (!data) {
    return <Placeholder className="w-8 h-8 rounded-full" />;
  }

  const operatorFullName = data.find(
    ({ externalId }) => operatorId === externalId
  )?.name;

  if (!operatorFullName) {
    return <RoundBackground>?</RoundBackground>;
  }

  const operatorNames = operatorFullName.split(' ');

  const operatorFirstName = operatorNames.shift() || '';
  const operatorInitialFirstName = operatorFirstName[0] || '';

  const operatorLastName = operatorNames.pop() || '';
  const operatorInitialLastName = operatorLastName[0] || '';

  return (
    <Tooltip
      button={
        <RoundBackground data-testid="OPERATOR_INITIAL_NAME" ref={ref}>
          {operatorInitialFirstName}
          {operatorInitialLastName}
        </RoundBackground>
      }
      content={
        <p className="py-2 px-3 text-sm text-neutral-600">{operatorFullName}</p>
      }
    />
  );
});

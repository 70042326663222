import { useToggle } from '@cooltra/hooks';
import { Modal } from '@cooltra/ui';
import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { ProfileInfoWithoutWorkShiftStarted } from '../ProfileInfo';

export const ProfileWithoutWorkShiftStarted = () => {
  const navigate = useNavigate();
  const [open, { toggleOff, toggleOn }] = useToggle();

  useEffect(() => {
    toggleOn();
  }, [toggleOn]);

  return (
    <Modal
      hideHeader
      type="bottom"
      isOpen={open}
      onRequestClose={toggleOff}
      onAfterClose={() => navigate('/')}
      shouldReturnFocusAfterClose={false}
      className="rounded-none"
    >
      <Routes>
        <Route
          path="/"
          element={<ProfileInfoWithoutWorkShiftStarted onClose={toggleOff} />}
        />
      </Routes>
    </Modal>
  );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  unlockBattery: {
    defaultMessage: 'Unlock battery',
  },
  batteryUnlockedSuccessfully: {
    defaultMessage: "Battery's vehicle has been successfully unlocked",
  },
  workUnitNotFound: {
    defaultMessage: 'You must start work unit before unlock battery',
  },
  workUnitStartedWithOtherVehicle: {
    defaultMessage:
      'You must start work unit on this vehicle before unlock battery',
  },
});

import { useTagsQuery } from '@cooltra/api';
import { FormattedMessage, useIntl } from 'react-intl';

import { SelectItemText, TopNavigation } from '~/common';

import { FiltersFormMultiSelect } from '../FiltersFormMultiSelect';
import { FilterRouteProps } from '../types';

import messages from './messages';

export const TagFilter = ({ onClose, onBack }: FilterRouteProps) => {
  const { formatMessage } = useIntl();
  const { data: tags = [], isLoading } = useTagsQuery();

  return (
    <FiltersFormMultiSelect
      onBack={onBack}
      onClose={onClose}
      name="tagId"
      isSearchable
      searchPlaceholder={formatMessage(messages.searchPlaceholder)}
      isLoading={isLoading}
      title={
        <TopNavigation.Title>
          <FormattedMessage {...messages.title} />
        </TopNavigation.Title>
      }
      renderOption={({ label }) => <SelectItemText>{label}</SelectItemText>}
      options={tags
        .map(({ name, tagId }) => ({
          value: tagId,
          label: name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))}
    />
  );
};

import { VehicleListItem } from '@cooltra/api';
import { isAfter, isBefore, subDays, subHours } from 'date-fns';

import { VehiclesFiltersFormValues } from '~/libs/vehicles-filters';

export const isIdleFromMatch = (
  vehicle: VehicleListItem,
  { idleFrom }: VehiclesFiltersFormValues
): boolean => {
  if (idleFrom.length === 0) {
    return true;
  }

  return idleFrom.some((idleFromItem) => {
    if (!vehicle.idleFrom) {
      return false;
    }

    const now = new Date();
    const vehicleIdleFromDate = new Date(vehicle.idleFrom);
    const timeAgo = {
      oneHour: subHours(now, 1),
      twentyHours: subHours(now, 20),
      twentyFourHours: subHours(now, 24),
      oneDay: subDays(now, 1),
      twoDays: subDays(now, 2),
      threeDays: subDays(now, 3),
      fourDays: subDays(now, 4),
      fiveDays: subDays(now, 5),
      tenDays: subDays(now, 10),
    };

    switch (idleFromItem) {
      case '1-20h':
        return (
          isBefore(vehicleIdleFromDate, timeAgo.oneHour) &&
          isAfter(vehicleIdleFromDate, timeAgo.twentyHours)
        );
      case '20-24h':
        return (
          isBefore(vehicleIdleFromDate, timeAgo.twentyHours) &&
          isAfter(vehicleIdleFromDate, timeAgo.twentyFourHours)
        );
      case '1-2d':
        return (
          isBefore(vehicleIdleFromDate, timeAgo.oneDay) &&
          isAfter(vehicleIdleFromDate, timeAgo.twoDays)
        );
      case '2-3d':
        return (
          isBefore(vehicleIdleFromDate, timeAgo.twoDays) &&
          isAfter(vehicleIdleFromDate, timeAgo.threeDays)
        );
      case '3-4d':
        return (
          isBefore(vehicleIdleFromDate, timeAgo.threeDays) &&
          isAfter(vehicleIdleFromDate, timeAgo.fourDays)
        );
      case '4-5d':
        return (
          isBefore(vehicleIdleFromDate, timeAgo.fourDays) &&
          isAfter(vehicleIdleFromDate, timeAgo.fiveDays)
        );
      case '5-10d':
        return (
          isBefore(vehicleIdleFromDate, timeAgo.fiveDays) &&
          isAfter(vehicleIdleFromDate, timeAgo.tenDays)
        );
      case '+10d':
        return isBefore(vehicleIdleFromDate, timeAgo.tenDays);
    }
  });
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  youHaveAnActiveShift: {
    defaultMessage: 'You have an active shift',
  },
  endShift: {
    defaultMessage: 'End shift',
  },
});

import { useToggle } from '@cooltra/hooks';
import { FormattedMessage } from 'react-intl';
import { Button } from '@cooltra/ui';

import { useVehicleQuery } from '~/hooks';
import { isTestingEnv } from '~/utils/e2e';

import { WorkUnit } from '../WorkUnit/WorkUnit';
import { Actions } from '../Actions/Actions';

import messages from './messages';

export type FloatingMenuProps = {
  vehicleId: string;
  onCloseWorkUnit?: () => void;
  onStartWorkUnit?: () => void;
};

export const FloatingMenu = ({
  vehicleId,
  onCloseWorkUnit,
  onStartWorkUnit,
}: FloatingMenuProps) => {
  const [isOpen, { toggleOff, toggle }] = useToggle();

  const staleMinutes = isTestingEnv() ? 0.05 : 2;

  const {
    data: vehicle,
    isStale,
    refetch,
  } = useVehicleQuery(vehicleId, {
    staleTime: staleMinutes * 60 * 1000,
  });

  if (!vehicle) {
    return <></>;
  }

  return (
    <>
      <Actions isOpen={isOpen} onClose={toggleOff} vehicle={vehicle} />
      <div className="bottom-5 absolute right-1/2 translate-x-1/2 flex gap-2">
        <WorkUnit
          vehicle={vehicle}
          onCloseWorkUnit={onCloseWorkUnit}
          onStartWorkUnit={onStartWorkUnit}
          isStale={isStale}
          refetch={refetch}
        />
        <Button
          emphasis="high"
          className="rounded-full shadow-xl z-30"
          onClick={toggle}
        >
          <FormattedMessage {...messages.actions} />
        </Button>
      </div>
    </>
  );
};

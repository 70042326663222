import { useIntl } from 'react-intl';
import { MdOutlineLayers } from 'react-icons/md';
import { VehicleType } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

import { MapButton, MapButtonProps } from '../MapButton';

import messages from './messages';

type SwitchGeofencesButtonProps = MapButtonProps & {
  vehicleType: VehicleType;
};

export const SwitchGeofencesButton = ({
  vehicleType,
  ...rest
}: SwitchGeofencesButtonProps) => {
  const { data: claims } = useAuthClaimsQuery();
  const { formatMessage } = useIntl();
  const operatorsInBarcelona = claims?.systems.includes('Barcelona');

  if (!operatorsInBarcelona) {
    return null;
  }

  if (vehicleType === 'SCOOTER') {
    return (
      <MapButton aria-label={formatMessage(messages.switch)} {...rest}>
        <MdOutlineLayers className="text-primary-400" />
      </MapButton>
    );
  }

  return (
    <MapButton aria-label={formatMessage(messages.switch)} {...rest}>
      <MdOutlineLayers className="text-accent-500" />
    </MapButton>
  );
};

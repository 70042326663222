import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

type MoreOptionsProps = {
  onSettled?: () => void;
  isMoreOptionsOpen: boolean;
};

export const MoreReasons = ({
  onSettled,
  isMoreOptionsOpen,
}: MoreOptionsProps) => {
  return (
    <>
      {isMoreOptionsOpen ? (
        <Button
          className={'rounded-full'}
          emphasis="high"
          // loading={isPending}
          // disabled={isPending}
          onClick={onSettled}
        >
          <FormattedMessage {...messages.moreReasons} />
        </Button>
      ) : (
        <Button
          className={
            'rounded-full text-neutral-500 bg-neutral-50 focus:bg-neutral-100 focus:ring-neutral-100'
          }
          // loading={isPending}
          // disabled={isPending}
          onClick={onSettled}
        >
          <FormattedMessage {...messages.moreReasons} />
        </Button>
      )}
    </>
  );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Tasks',
  },
  addTask: {
    defaultMessage: 'Add task',
  },
});

import { Button, TopLoadingBar } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { usePreferredLanguage } from '@cooltra/preferred-language';
import { useCallback } from 'react';
import { setUser } from '@sentry/react';
import { useAuth0 } from '@auth0/auth0-react';

import { Screen, TopNavigation } from '~/common';

import { LanguageItem } from '../LanguageItem/LanguageItem';

import messages from './messages';

export type ProfileInfoWithoutWorkShiftStartedProps = {
  onClose: () => void;
};

export const ProfileInfoWithoutWorkShiftStarted = ({
  onClose,
}: ProfileInfoWithoutWorkShiftStartedProps) => {
  const { logout } = useAuth0();
  const { loadingLanguage } = usePreferredLanguage();

  const handleLogOut = useCallback(() => {
    localStorage.clear();
    setUser(null);
    logout({
      logoutParams: {
        returnTo: 'https://www.office.com/estslogout',
      },
    });
  }, [logout]);

  return (
    <Screen
      data-testid="PROFILE_SCREEN_WITHOUT_WORK_SHIFT_STARTED"
      header={
        <div className="border-b border-neutral-100">
          {loadingLanguage && <TopLoadingBar />}
          <TopNavigation.Bar
            renderRight={<TopNavigation.Close onClick={onClose} />}
            title={
              <TopNavigation.Title>
                <FormattedMessage {...messages.profile} />
              </TopNavigation.Title>
            }
          />
        </div>
      }
      content={
        <>
          <ul>
            <LanguageItem />
          </ul>
          <div className="p-4 flex flex-col justify-between">
            <Button onClick={handleLogOut}>
              <FormattedMessage {...messages.logOut} />
            </Button>
          </div>
        </>
      }
    />
  );
};

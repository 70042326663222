import { ImgHTMLAttributes } from 'react';
import { classNames } from '@cooltra/utils';
import { Image } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';

export type PhotoProps = ImgHTMLAttributes<HTMLImageElement> & {
  imageData: string;
  loadingClassName: string;
};

export const Photo = ({
  className,
  imageData,
  loadingClassName,
  ...rest
}: PhotoProps) => {
  const [isLoading, { toggleOff }] = useToggle(true);
  return (
    <div
      className={classNames(
        'rounded-md overflow-hidden',
        isLoading && loadingClassName,
        className
      )}
    >
      <Image
        className="object-contain"
        src={imageData}
        onLoad={toggleOff}
        {...rest}
      />
    </div>
  );
};

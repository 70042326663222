import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { ClearableInput } from '~/common';

import messages from './messages';

export type SearchInputProps = {
  setValue: (value: string) => void;
};

export const SearchInput = ({ setValue }: SearchInputProps) => {
  const [internalValue, setInternalValue] = useState('');

  const { formatMessage } = useIntl();
  const timeout = useRef<NodeJS.Timeout>();

  const handleChange = (inputValue: string) => {
    setInternalValue(inputValue);
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      setValue(inputValue);
    }, 1000);
  };

  const handleBlur = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      setValue(target.value);
    }
  };

  useEffect(
    () => () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    },
    []
  );

  return (
    <ClearableInput
      autoFocus
      onChange={handleChange}
      placeholder={formatMessage(messages.placeholder)}
      aria-label={formatMessage(messages.search)}
      onBlur={handleBlur}
      value={internalValue}
    />
  );
};

import { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { Button, Icon } from '@cooltra/ui';
import {
  useCurrentWorkShiftQuery,
  useMyTakenVehicles,
  useWorkUnitQuery,
} from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import { EndShiftModal } from './EndShiftModal/EndShiftModal';
import { MyShiftDuration } from './MyShiftDuration';
import { EndShiftWithOngoingWorkUnitModal } from './EndShiftWithOngoingWorkUnitModal/EndShiftWithOngoingWorkUnitModal';
import { EndShiftWithOngoingTakenVehiclesModal } from './EndShiftWithOngoingTakenVehiclesModal/EndShiftWithOngoingTakenVehiclesModal';

export const MyShift = () => {
  const { data: currentShift, isLoading: isCurrentShiftLoading } =
    useCurrentWorkShiftQuery();
  const { data: currentWorkUnit, isLoading: isWorkUnitLoading } =
    useWorkUnitQuery();
  const { data: currentTakenVehicles, isLoading: isCurrentTakenVehicles } =
    useMyTakenVehicles();
  const [endShiftModalOpen, setEndShiftModalOpen] = useState<boolean>(false);

  if (
    !currentShift ||
    isCurrentShiftLoading ||
    isWorkUnitLoading ||
    isCurrentTakenVehicles
  ) {
    return <></>;
  }

  const openEndShiftModal = () => {
    setEndShiftModalOpen(true);
  };

  const closeEndShiftModal = () => {
    setEndShiftModalOpen(false);
  };

  const getEndShiftModal = () => {
    if (currentTakenVehicles && currentTakenVehicles?.length > 0) {
      return (
        <EndShiftWithOngoingTakenVehiclesModal
          isOpen={endShiftModalOpen}
          onClose={closeEndShiftModal}
          myTakenVehiclesCount={currentTakenVehicles?.length}
        />
      );
    }

    if (currentWorkUnit) {
      return (
        <EndShiftWithOngoingWorkUnitModal
          isOpen={endShiftModalOpen}
          onClose={closeEndShiftModal}
        />
      );
    }

    return (
      <EndShiftModal isOpen={endShiftModalOpen} onClose={closeEndShiftModal} />
    );
  };

  return (
    <>
      {getEndShiftModal()}
      <li className="flex flex-col justify-center items-center bg-success-50 gap-3 pb-8">
        <span className="w-full mb-1 h-1 block bg-success-500" />
        <p className="w-full text-center font-semibold text-success-700 text-sm">
          <FormattedMessage {...messages.youHaveAnActiveShift} />
        </p>
        <span className="w-full mt-2 block text-lg text-center text-neutral-400">
          <MyShiftDuration />
        </span>
        <Button
          size="md"
          variant="primary"
          className="mx-auto text-md rounded-full text-neutral-400 bg-neutral-0 shadow-lg"
          leadingIcon={
            <Icon className="text-xl text-neutral-400">
              <MdClose />
            </Icon>
          }
          onClick={openEndShiftModal}
        >
          <FormattedMessage {...messages.endShift} />
        </Button>
      </li>
    </>
  );
};

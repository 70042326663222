import { FormattedMessage, useIntl } from 'react-intl';
import { useReleaseVehicleMutation } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { AxiosError } from 'axios';
import { Button } from '@cooltra/ui';
import { MdCheckCircleOutline } from 'react-icons/md';

import { useNotification } from '~/libs/notifications';
import { useVehiclePolling } from '~/libs/polling-data';

import { ActionButtonProps } from '../types';

import messages from './messages';

export const Release = ({ vehicleId, onSettled }: ActionButtonProps) => {
  const { formatMessage } = useIntl();
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const { activatePollingVehicle } = useVehiclePolling();

  const { isPending, mutate } = useReleaseVehicleMutation(vehicleId, {
    onSuccess: () => {
      addSuccessNotification(formatMessage(messages.successfullyReleased));
      activatePollingVehicle(vehicleId);
    },
    onError: (error: AxiosError) => {
      let errorNotification;

      switch (getErrorStatus(error)) {
        case 400:
          errorNotification = formatMessage(messages.errorCouldNotBeReleased);
          break;
        case 401:
          errorNotification = formatMessage(
            messages.notAuthorizedToPerformReleaseOnVehicle
          );
          break;
        default:
          errorNotification = formatMessage(messages.somethingWentWrong);
          break;
      }

      addErrorNotification(errorNotification);
    },
    onSettled,
  });

  const releaseVehicle = () => mutate();

  return (
    <div className="w-1/3 flex flex-col items-center justify-center">
      <Button
        className="p-0 w-16 h-16 bg-primary-50 rounded-full btn-size-lg text-primary-500"
        loading={isPending}
        disabled={isPending}
        onClick={releaseVehicle}
        square
        aria-label={formatMessage(messages.releaseVehicle)}
      >
        <MdCheckCircleOutline className="w-7 h-7" />
      </Button>
      <span className="w-24 text-center text-sm text-neutral-0 mt-1">
        <FormattedMessage {...messages.releaseVehicle} />
      </span>
    </div>
  );
};

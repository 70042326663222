import { Telematics, Vehicle } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import { classNames } from '@cooltra/utils';
import { Button } from '@cooltra/ui';

import { TakeReason } from '~/common/FloatingMenu/ActionButtons/Take/TakeReason';
import { TakeOtherReasonModal } from '~/common/FloatingMenu/ActionButtons/Take/TakeOtherReasonModal';

import {
  ReleaseFromLocation,
  Honk,
  Sync,
  Unlock,
  UnlockTopCase,
  Lock,
  PowerOff,
  TakeMenu,
  Release,
  GpsReboot,
  IgnitionOn,
  IgnitionOff,
  EnableAlarm,
  DisableAlarm,
  OpenSaddle,
  UnlockBattery,
} from '../ActionButtons';
import { Overlay } from '../Overlay/Overlay';
import { MoreReasons } from '../ActionButtons/MoreReasons/MoreReasons';

import messages from './messages';

const actions = {
  askoll: [Lock, Sync, Unlock, Honk, UnlockTopCase],
  niu: [
    Lock,
    Sync,
    Unlock,
    Honk,
    UnlockTopCase,
    GpsReboot,
    IgnitionOn,
    IgnitionOff,
    OpenSaddle,
    EnableAlarm,
    DisableAlarm,
  ],
  arcade: [Sync, Unlock, Lock, PowerOff],
  segway: [Sync, Unlock, Lock, Honk, UnlockBattery],
};

const takeOptions = [
  { option: 'recollection', description: messages.recollection },
  { option: 'rebalance', description: messages.rebalance },
];

const additionalTakeOptions = [
  {
    option: 'review_connectivity',
    description: messages.reviewConnectivity,
  },
  { option: 'poorly_parked', description: messages.poorlyParked },
  { option: 'work_queue', description: messages.workQueue },
];

export type ActionsProps = {
  vehicle: Vehicle;
  onClose: () => void;
  isOpen: boolean;
};

export const Actions = ({
  vehicle: { vehicleId, locationId, takenBy, telematics },
  isOpen,
  onClose,
}: ActionsProps) => {
  const [
    isTakeMenuOpen,
    { toggle: toggleTakeMenu, toggleOff: toggleOffTakeMenu },
  ] = useToggle();
  const [
    isMoreOptionsMenuOpen,
    { toggle: toggleMoreOptionsMenu, toggleOff: toggleOffMoreOptionsMenu },
  ] = useToggle();
  const [
    isOtherReasonModalOpen,
    {
      toggleOn: toggleOnOtherReasonModal,
      toggleOff: toggleOffOtherReasonModal,
    },
  ] = useToggle();

  const handleCloseOverlay = () => {
    onClose();
    setTimeout(() => closeTakeMenu(), 200);
  };

  const handleToggleTakeMenu = () => {
    toggleTakeMenu();
    if (!isTakeMenuOpen) {
      toggleOffMoreOptionsMenu();
    }
  };

  const closeTakeMenu = () => {
    toggleOffOtherReasonModal();
    toggleOffMoreOptionsMenu();
    toggleOffTakeMenu();
  };

  const getActions = (telematics: Telematics) => {
    switch (telematics) {
      case 'INVERS':
      case 'ASTRA':
        return actions.askoll;
      case 'NIU':
        return actions.niu;
      case 'AXA':
        return actions.arcade;
      case 'SEGWAY':
        return actions.segway;
      default:
        return actions.askoll;
    }
  };

  const displayReleaseFromLocation = locationId && !isTakeMenuOpen;

  return (
    <Overlay isOpen={isOpen} onClick={handleCloseOverlay}>
      <div
        className="h-full flex flex-col justify-between items-center pb-36"
        data-testid="VEHICLE_ACTIONS"
      >
        <h3 className="text-lg font-semibold text-neutral-0 mt-4">
          <FormattedMessage {...messages.actions} />
        </h3>
        <TakeOtherReasonModal
          vehicleId={vehicleId}
          isOtherReasonModalOpen={isOtherReasonModalOpen}
          onClose={closeTakeMenu}
          onCancel={toggleOffOtherReasonModal}
        />
        <div className="flex flex-col items-center">
          {isTakeMenuOpen ? (
            <>
              {isMoreOptionsMenuOpen && !isOtherReasonModalOpen && (
                <ul className="flex flex-col gap-4 items-center mb-8">
                  <Button
                    className="rounded-full"
                    onClick={toggleOnOtherReasonModal}
                  >
                    <FormattedMessage {...messages.other} />
                  </Button>
                  {additionalTakeOptions.map(({ option, description }) => (
                    <TakeReason
                      key={`option-${option}`}
                      reason={option}
                      vehicleId={vehicleId}
                      onClose={closeTakeMenu}
                    >
                      <FormattedMessage {...description} />
                    </TakeReason>
                  ))}
                </ul>
              )}
              <ul className="flex flex-col gap-4 items-center mb-8">
                <MoreReasons
                  onSettled={toggleMoreOptionsMenu}
                  isMoreOptionsOpen={isMoreOptionsMenuOpen}
                />
                {takeOptions.map(({ option, description }) => (
                  <TakeReason
                    key={`option-${option}`}
                    className={classNames(isMoreOptionsMenuOpen && 'opacity-0')}
                    reason={option}
                    vehicleId={vehicleId}
                    onClose={closeTakeMenu}
                    disabled={isMoreOptionsMenuOpen}
                  >
                    <FormattedMessage {...description} />
                  </TakeReason>
                ))}
              </ul>
            </>
          ) : (
            <ul className="flex flex-wrap-reverse mb-8">
              {getActions(telematics).map((Action, i) => (
                <li className="w-1/3 mt-3" key={`action-${i}`}>
                  <Action vehicleId={vehicleId} />
                </li>
              ))}
            </ul>
          )}
          <div
            className={classNames(
              'w-full flex items-start',
              !displayReleaseFromLocation && 'justify-center'
            )}
          >
            {displayReleaseFromLocation && (
              <ReleaseFromLocation vehicleId={vehicleId} onSettled={onClose} />
            )}
            {takenBy ? (
              <Release vehicleId={vehicleId} onSettled={onClose} />
            ) : (
              <TakeMenu
                vehicleId={vehicleId}
                onSettled={handleToggleTakeMenu}
                isTakeMenuOpen={isTakeMenuOpen}
                className={classNames(isMoreOptionsMenuOpen && 'opacity-0')}
                disabled={isOtherReasonModalOpen}
                telematics={telematics}
              />
            )}
          </div>
        </div>
      </div>
    </Overlay>
  );
};

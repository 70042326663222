import { ReactNode, useState } from 'react';
import { Auth0Context, Auth0ContextInterface } from '@auth0/auth0-react';
import { authMock } from '@cooltra/auth-api';

export type MockAuth0ProviderProps = {
  children: ReactNode;
};

export const MockAuth0Provider = ({ children }: MockAuth0ProviderProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const token =
    localStorage.getItem('mock-auth-token') || authMock.createToken();

  const getAccessTokenSilently = (): Promise<any> =>
    new Promise((resolve) => {
      setIsLoaded(true);
      resolve(token);
    });

  const asyncMock = (): Promise<any> =>
    new Promise((resolve) => resolve('test'));

  const value: Auth0ContextInterface = {
    getAccessTokenSilently: getAccessTokenSilently,
    getAccessTokenWithPopup: asyncMock,
    getIdTokenClaims: asyncMock,
    loginWithRedirect: asyncMock,
    loginWithPopup: asyncMock,
    logout: asyncMock,
    handleRedirectCallback: asyncMock,
    isAuthenticated: true,
    isLoading: false,
    user: {
      sub: 'auth0|86c4-5875-9fa1-0a094a0f7381',
      picture:
        'https://s.gravatar.com/avatar/a343ac0a643d1797da7d7909ef0faed2?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fps.png',
      name: 'Test user',
    },
  };

  return (
    <Auth0Context.Provider value={value}>
      {isLoaded && <span data-testid="AUTH_CLAIMS_LOADED" />}
      {children}
    </Auth0Context.Provider>
  );
};

import { useToggle } from '@cooltra/hooks';
import { Modal } from '@cooltra/ui';
import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { ProfileInfo } from '../ProfileInfo';
import { MyTasks } from '../MyTasks/MyTasks';
import { MyGroupTasks } from '../MyGroupTasks/MyGroupTasks';
import { ProblemReport } from '../ProblemReport';
import { MyTask } from '../MyTask/MyTask';
import { MyTakenVehiclesList, TransferAll } from '../MyTakenVehicles';

const Profile = () => {
  const navigate = useNavigate();
  const [open, { toggleOff, toggleOn }] = useToggle();

  const handleCloseModal = () => {
    toggleOff();
    navigate('/');
  };

  useEffect(() => {
    toggleOn();
  }, [toggleOn]);

  return (
    <Modal
      hideHeader
      type="bottom"
      isOpen={open}
      onRequestClose={handleCloseModal}
      shouldReturnFocusAfterClose={false}
      className="rounded-none"
    >
      <Routes>
        <Route path="/" element={<ProfileInfo onClose={handleCloseModal} />} />
        <Route path="/tasks" element={<MyTasks onClose={handleCloseModal} />} />
        <Route
          path="/group-tasks"
          element={<MyGroupTasks onClose={handleCloseModal} />}
        />
        <Route
          path="/tasks/:taskId"
          element={<MyTask onClose={handleCloseModal} />}
        />
        <Route
          path="/my-taken-vehicles"
          element={<MyTakenVehiclesList onClose={handleCloseModal} />}
        />
        <Route
          path="/my-taken-vehicles/transfer-all"
          element={<TransferAll onClose={handleCloseModal} />}
        />
        <Route
          path="/problem-reports/:problemReportId"
          element={<ProblemReport onClose={handleCloseModal} />}
        />
      </Routes>
    </Modal>
  );
};

export default Profile;

import { useVehicleStatusQuery } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { FormattedMessage } from 'react-intl';
import { MdWifiTethering } from 'react-icons/md';

import { ListItemLink, NotAvailable } from '~/common';

import messages from './messages';
import { countIssues } from './count-issues';

export type TelematicsListItemProps = {
  vehicleId: string;
};

export const TelematicsListItem = ({ vehicleId }: TelematicsListItemProps) => {
  const { data: status, error } = useVehicleStatusQuery(vehicleId, {
    retry: false,
  });

  const renderTelematicsStatus = () => {
    if (getErrorStatus(error) === 404) {
      return <NotAvailable />;
    }

    if (!status) {
      return <></>;
    }

    if (status.offline === null) {
      return <NotAvailable />;
    }

    const issuesCount = countIssues(status);

    if (issuesCount === 0) {
      return (
        <span className="text-sm text-neutral-500 pl-2 truncate">
          <FormattedMessage {...messages.noIssues} />
        </span>
      );
    }

    return (
      <span className="text-sm text-danger-500 pl-2 truncate">
        <FormattedMessage {...messages.issues} values={{ issuesCount }} />
      </span>
    );
  };

  return (
    <li data-testid="VEHICLE_TELEMATICS_LIST_ITEM">
      <ListItemLink
        to="telematics"
        leadingIcon={
          <MdWifiTethering className="text-2xl text-neutral-500 mr-2" />
        }
        hasChevron
      >
        <div className="flex justify-between items-center gap-4">
          <span className="text-base font-semibold text-neutral-700">
            <FormattedMessage {...messages.telematics} />
          </span>
          {renderTelematicsStatus()}
        </div>
      </ListItemLink>
    </li>
  );
};

import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';
import Div100vh from 'react-div-100vh';

export type ScreenProps = Omit<HTMLAttributes<HTMLDivElement>, 'content'> & {
  header: ReactNode;
  content?: ReactNode;
  contentId?: string;
};

export const Screen = ({
  header,
  content,
  contentId,
  className,
  ...rest
}: ScreenProps) => (
  <Div100vh
    className={classNames('w-screen overflow-hidden', className)}
    {...rest}
  >
    <div className="flex flex-col h-full">
      <div className="shrink-0">{header}</div>
      <div className="flex-1 overflow-auto" id={contentId}>
        {content}
      </div>
    </div>
  </Div100vh>
);

import { useProblemReportImage } from '@cooltra/api';

import { Photo, PhotoProps } from '../../Photo/Photo';

export type ProblemReportPhotoProps = Omit<
  PhotoProps,
  'imageData' | 'loadingClassName'
> & {
  name: string;
};

export const ProblemReportPhoto = ({
  name,
  ...rest
}: ProblemReportPhotoProps) => {
  const fetchedImageData = useProblemReportImage(name);

  const imageData = fetchedImageData
    ? `data:image/jpeg;base64,${fetchedImageData}`
    : '';

  return (
    <Photo
      data-testid="PROBLEM_REPORT_PHOTO"
      imageData={imageData}
      loadingClassName="h-44"
      {...rest}
    />
  );
};

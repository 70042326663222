import { Placeholder, Icon } from '@cooltra/ui';
import { MdSearch } from 'react-icons/md';

import { isTestingEnv } from '~/utils/e2e';

import blurredMap from './assets/boca-juniors-stadium.png';

export const MiniMapImage = () => {
  if (isTestingEnv()) {
    return <Placeholder className="w-full h-full rounded-none" />;
  }

  return (
    <div className="w-full h-20">
      <Icon className="absolute h-full w-full flex justify-center align-middle z-10 text-3xl text-neutral-500 opacity-80">
        <MdSearch />
      </Icon>
      <img
        src={blurredMap}
        alt="Boca Junior's Stadium"
        className="w-full h-full object-cover z-0"
      />
    </div>
  );
};

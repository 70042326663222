import { defineMessages } from 'react-intl';

export default defineMessages({
  description: {
    defaultMessage: 'Description',
  },
  optional: {
    defaultMessage: 'optional',
  },
});

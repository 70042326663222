import { ProblemReport } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import {
  DateTime,
  LabelAndValue,
  ProblemReportPhoto,
  ProblemReportBadge,
} from '~/common';

import messages from './messages';

export type ProblemReportContentProps = {
  problemReport: ProblemReport;
};

export const ProblemReportContent = ({
  problemReport,
}: ProblemReportContentProps) => (
  <div className="px-4 py-6 flex flex-col gap-4">
    <LabelAndValue
      label={<FormattedMessage {...messages.reportType} />}
      value={
        <div className="mt-1 flex flex-wrap gap-2">
          {problemReport.tags.map((tagId) => (
            <ProblemReportBadge tagId={tagId} key={tagId} />
          ))}
        </div>
      }
    />
    <LabelAndValue
      label={<FormattedMessage {...messages.description} />}
      value={problemReport.description || '-'}
    />
    <LabelAndValue
      label={<FormattedMessage {...messages.created} />}
      value={<DateTime date={problemReport.createdAt} />}
    />

    <LabelAndValue
      label={<FormattedMessage {...messages.attachedImages} />}
      value={
        <div className="mt-2">
          {problemReport.imagePaths.length
            ? problemReport.imagePaths.map((imagePath) => (
                <ProblemReportPhoto
                  key={imagePath}
                  className="mb-4"
                  name={imagePath}
                />
              ))
            : '-'}
        </div>
      }
    />
  </div>
);

import { MdClose } from 'react-icons/md';
import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IconButton } from '../../InteractiveIcon';

import messages from './messages';

export type FieldButtonBaseProps = HTMLAttributes<HTMLDivElement> & {
  disabled?: boolean;
  label: ReactNode;
  onClear?: () => void;
  onClick: () => void;
  optional?: boolean;
  showClearButton?: boolean;
  testId?: string;
};

export const FieldButtonBase = ({
  children,
  disabled = false,
  label,
  onClear,
  onClick,
  optional = false,
  showClearButton = false,
  testId,
  ...rest
}: FieldButtonBaseProps) => {
  const { formatMessage } = useIntl();

  return (
    <div {...rest}>
      <div className="flex flex-row items-end mb-1">
        <span className="inline-block text-neutral-700 font-semibold text-base">
          {label}
          {optional && (
            <span className="font-normal ml-1">
              (<FormattedMessage {...messages.optional} />)
            </span>
          )}
        </span>
      </div>
      <div className="relative">
        <button
          data-testid={testId}
          className={classNames(
            'custom-input',
            'custom-input-base',
            'py-1',
            'tap-highlight',
            'border',
            'border-neutral-300',
            disabled && 'custom-input--disabled',
            showClearButton && 'pr-10'
          )}
          disabled={disabled}
          type="button"
          onClick={onClick}
        >
          {children}
        </button>
        {showClearButton && (
          <div className="h-full w-10 absolute right-0 inset-y-0 p-0.5">
            <IconButton
              onClick={onClear}
              type="button"
              className="h-full w-full"
              disabled={disabled}
              aria-label={formatMessage(messages.clear)}
            >
              <MdClose />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
};

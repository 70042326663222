import { PropsWithChildren } from 'react';

import { useUserSystems } from '~/hooks';

import { NoSystemPermission } from './NoSystemPermission/NoSystemPermission';

export const SystemPermissionCheck = ({ children }: PropsWithChildren) => {
  const systems = useUserSystems();

  if (!systems.length) {
    return <NoSystemPermission />;
  }

  return <>{children}</>;
};

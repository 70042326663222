import { defineMessages } from 'react-intl';

export default defineMessages({
  unlock: {
    defaultMessage: 'Unlock',
  },
  successOnUnlockVehicle: {
    defaultMessage: 'Unlock action has been triggered successfully',
  },
});

import { Spinner } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { ReactNode } from 'react';

export type ActionButtonType = 'success' | 'neutral';

const buttonClasses: { [key in ActionButtonType]: string } = {
  neutral:
    'text-neutral-500 bg-neutral-50 focus:bg-neutral-100 focus:ring-neutral-100',
  success:
    'text-neutral-0 bg-success-400 focus:bg-success-500 focus:ring-success-500',
};

export type ActionButtonProps = {
  type?: ActionButtonType;
  onClick: () => void;
  loading: boolean;
  children: ReactNode;
  className?: string;
};

export const ActionButton = ({
  onClick,
  children,
  loading,
  type = 'neutral',
  className,
}: ActionButtonProps) => (
  <button
    className={classNames(
      'btn',
      'btn-size-md',
      'rounded-full',
      'focus:ring',
      buttonClasses[type],
      className
    )}
    onClick={onClick}
  >
    {loading && (
      <span className="absolute inset-0 flex items-center justify-center">
        <Spinner size="md" />
      </span>
    )}
    <span
      className={classNames(
        'max-w-full',
        'inline-flex',
        'items-center',
        loading ? 'opacity-0' : 'opacity-100'
      )}
    >
      <span className="truncate">{children}</span>
    </span>
  </button>
);

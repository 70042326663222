import { useWorkUnitQuery } from '@cooltra/api';
import { useMemo } from 'react';

import { ExtendedVehicleListItem } from '~/libs/vehicles-filters';
import { useSelectVehicle } from '~/libs/select-vehicle';
import { useTrackUser } from '~/libs/track-user';
import { MapView, usePreferredMapView, useSwitchGeofence } from '~/hooks';
import {
  GeolocateButton,
  RefreshButton,
  SwitchViewButton,
  SwitchGeofencesButton,
  VehiclesMap,
  VehicleMapSource,
} from '~/common';

import { getFeaturesFromVehiclesList } from './tools/features';
import { VehicleFeatureImageProps } from './tools/types';
import * as batteryView from './views/battery-state-view';
import * as operationalView from './views/operational-state-view';

const featureImagePerMapView: { [key in MapView]: VehicleFeatureImageProps } = {
  'by-battery': new batteryView.BatteryLevelVehicleFeatureImage(),
  'by-status': new operationalView.OperationalVehicleFeatureImage(),
};

const mapSourcesPerMapView: { [key in MapView]: VehicleMapSource[] } = {
  'by-battery': batteryView.vehiclesMapSources,
  'by-status': operationalView.vehiclesMapSources,
};

const layersPerMapView: { [key in MapView]: string[] } = {
  'by-battery': batteryView.layers,
  'by-status': operationalView.layers,
};

export type VehiclesMultiViewMapProps = {
  vehicles: ExtendedVehicleListItem[];
};

export const VehiclesMultiViewMap = ({
  vehicles,
}: VehiclesMultiViewMapProps) => {
  const { isTrackingUser, startTrackingUser } = useTrackUser();
  const { data: workUnit } = useWorkUnitQuery();
  const { vehicleId } = useSelectVehicle();
  const [mapView, toggleMapView] = usePreferredMapView();
  const [vehicleType, toggleVehicleType] = useSwitchGeofence();

  const vehicleFeatures = useMemo(
    () =>
      getFeaturesFromVehiclesList(
        vehicles,
        featureImagePerMapView[mapView],
        workUnit ? workUnit.vehicleId : undefined,
        vehicleId
      ),
    [mapView, vehicleId, vehicles, workUnit]
  );

  return (
    <VehiclesMap
      interactiveLayerIds={layersPerMapView[mapView]}
      vehiclesMapSources={mapSourcesPerMapView[mapView]}
      vehicleFeatures={vehicleFeatures}
      vehicleType={vehicleType}
    >
      <div className="ml-0.5 left-4 absolute z-10 flex flex-col gap-4 top-24">
        <GeolocateButton
          onClick={startTrackingUser}
          isActive={isTrackingUser}
        />
        <RefreshButton />
        <SwitchViewButton
          onClick={toggleMapView}
          isActive={mapView === 'by-battery'}
        />
        <SwitchGeofencesButton
          vehicleType={vehicleType}
          onClick={toggleVehicleType}
        />
      </div>
    </VehiclesMap>
  );
};

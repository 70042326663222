import { defineMessages } from 'react-intl';

export default defineMessages({
  takeVehicle: {
    defaultMessage: 'Take vehicle',
  },
  somethingWentWrong: {
    defaultMessage: 'Something went wrong!',
  },
  successfullyTaken: {
    defaultMessage: 'The vehicle has been successfully taken',
  },
  notAuthorizedToPerformTakeOnVehicle: {
    defaultMessage:
      'You are not authorized to perform a take action on a vehicle',
  },
  cannotTake: {
    defaultMessage:
      'Check in Electric Feel if the vehicle is in a reservation or rental',
  },
  writeComment: {
    defaultMessage: 'Write the reason for taking vehicle',
  },
  writeCommentPlaceholders: {
    defaultMessage: 'Add a comment',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
});

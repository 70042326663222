import { useMyTakenVehicles, useVehiclesQuery } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { TopLoadingBar } from '@cooltra/ui';

import { Error, Screen, TopNavigation, NoVehiclesFound } from '~/common';
import { useSelectVehicle } from '~/libs/select-vehicle';
import { useUserSystems } from '~/hooks';

import messages from './messages';
import { VehicleCard } from './VehicleCard/VehicleCard';
import { FloatingMenu } from './FloatingMenu/FloatingMenu';

export type MyTakenVehiclesListProps = {
  onClose: () => void;
};

export const MyTakenVehiclesList = ({ onClose }: MyTakenVehiclesListProps) => {
  const navigate = useNavigate();
  const systems = useUserSystems();
  const { selectVehicle } = useSelectVehicle();

  const { data: vehicles = [], isSuccess } = useVehiclesQuery({
    system: systems,
  });

  const {
    data: myVehiclesTaken,
    isLoading,
    isError,
  } = useMyTakenVehicles({
    enabled: isSuccess,
  });

  const fullVehicles = vehicles.filter((vehicle) =>
    myVehiclesTaken?.includes(vehicle.vehicleId)
  );

  const myTakenVehiclesTotal = fullVehicles.length || 0;

  const goBack = () => navigate('/profile');

  const handleVehicleClick = (id: string) => () => {
    selectVehicle(id);
    onClose();
  };

  const renderContent = () => {
    if (isError) {
      return <Error />;
    }

    if (myTakenVehiclesTotal === 0) {
      return <NoVehiclesFound />;
    }

    return (
      <div className="pt-4 pb-16 px-4 flex flex-col gap-3 overflow-x-hidden">
        {fullVehicles.map((vehicle) => (
          <VehicleCard
            onClick={handleVehicleClick(vehicle.vehicleId)}
            key={vehicle.vehicleId}
            vehicle={vehicle}
          />
        ))}
        <FloatingMenu />
      </div>
    );
  };

  return (
    <Screen
      data-testid="MY_TAKEN_VEHICLES_SCREEN"
      header={
        <>
          {isLoading && <TopLoadingBar />}
          <div className="bg-neutral-0 border-b border-neutral-100">
            <TopNavigation.Bar
              renderLeft={<TopNavigation.Back onClick={goBack} />}
              renderRight={<TopNavigation.Close onClick={onClose} />}
              title={
                <TopNavigation.Title>
                  <FormattedMessage
                    {...messages.title}
                    values={{ total: myTakenVehiclesTotal }}
                  />
                </TopNavigation.Title>
              }
            />
          </div>
        </>
      }
      content={renderContent()}
    />
  );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Task',
  },
  description: {
    defaultMessage: 'Description',
  },
  assignee: {
    defaultMessage: 'Assignee',
  },
  updated: {
    defaultMessage: 'Updated',
  },
  created: {
    defaultMessage: 'Created',
  },
});

import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button } from '@cooltra/ui';

import messages from './messages';

export const TransferAll = () => {
  const navigate = useNavigate();

  const goToTransferAll = () =>
    navigate('/profile/my-taken-vehicles/transfer-all');

  return (
    <Button
      emphasis="medium"
      onClick={goToTransferAll}
      className="w-44 rounded-full shadow-xl z-30"
    >
      <FormattedMessage {...messages.transferAll} />
    </Button>
  );
};

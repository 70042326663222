import { FormattedMessage } from 'react-intl';

import {
  ModelImage,
  SelectItemText,
  TopNavigation,
  useModelOptions,
} from '~/common';

import { FiltersFormMultiSelect } from '../FiltersFormMultiSelect';
import { FilterRouteProps } from '../types';

import messages from './messages';

export const ModelFilter = ({ onClose, onBack }: FilterRouteProps) => {
  const models = useModelOptions();

  return (
    <FiltersFormMultiSelect
      onBack={onBack}
      onClose={onClose}
      name="model"
      title={
        <TopNavigation.Title>
          <FormattedMessage {...messages.title} />
        </TopNavigation.Title>
      }
      renderOption={({ label, value }) => (
        <div className="flex items-center">
          <div className="w-12">
            <ModelImage
              className="h-6"
              model={value}
              identificationNumber={''}
            />
          </div>
          <SelectItemText>{label}</SelectItemText>
        </div>
      )}
      options={models}
    />
  );
};

import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type BarProps = Omit<HTMLAttributes<HTMLDivElement>, 'title'> & {
  renderLeft?: ReactNode;
  renderRight?: ReactNode;
  title?: ReactNode;
};

export const Bar = ({
  renderLeft,
  renderRight,
  title,
  className,
  ...rest
}: BarProps) => (
  <div
    className={classNames('h-16 flex justify-between items-center', className)}
    {...rest}
  >
    <div className="w-16 h-full flex-justify-start">{renderLeft}</div>
    <div className="flex-1 text-center">{title}</div>
    <div className="w-16 h-full flex justify-end">{renderRight}</div>
  </div>
);

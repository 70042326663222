import { defineMessages } from 'react-intl';

export default defineMessages({
  heading: {
    defaultMessage: 'Task templates',
  },
  title: {
    defaultMessage: 'Title',
  },
  placeholder: {
    defaultMessage: 'Search templates or create a new task',
  },
  label: {
    defaultMessage: 'Search',
  },
  addTask: {
    defaultMessage: 'Add new task',
  },
});

import { Marker } from 'react-map-gl';

import userPickup from './assets/user-pickup.svg';

export type UserPickUpMarkerProps = {
  latitude: number;
  longitude: number;
};

export const UserPickUpMarker = ({
  latitude,
  longitude,
}: UserPickUpMarkerProps) => (
  <Marker latitude={latitude} longitude={longitude}>
    <img className="w-7" src={userPickup} />
  </Marker>
);

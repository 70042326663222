import { useCurrentWorkShiftQuery } from '@cooltra/api';
import { intervalToDuration } from 'date-fns';
import { useEffect, useState } from 'react';

export const MyShiftDuration = () => {
  const { data: currentShift } = useCurrentWorkShiftQuery();
  const [duration, setDuration] = useState<Duration | undefined>(undefined);

  useEffect(() => {
    setInterval(() => {
      if (currentShift) {
        setDuration(
          intervalToDuration({
            start: new Date(currentShift.createdAt),
            end: Date.now(),
          })
        );
      }
    }, 1000);
  }, [currentShift]);

  if (!duration) {
    return <>00h00m00s</>;
  }

  let hoursDuration = duration.hours ? duration.hours : 0;

  if (duration.days) {
    hoursDuration += duration.days * 24;
  }

  const minutesDuration = duration.minutes ? duration.minutes : 0;
  const secondsDuration = duration.seconds ? duration.seconds : 0;

  return (
    <>
      {hoursDuration.toString().padStart(2, '0')}h
      {minutesDuration.toString().padStart(2, '0')}m
      {secondsDuration.toString().padStart(2, '0')}s
    </>
  );
};

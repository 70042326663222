const OPPO_53S = 'CPH2135';
const OnePlus6T = 'ONEPLUS A6013';

const validModels = [
  'M2006C3',
  'RMX326',
  'M2004J',
  'M1805D',
  'SM-A236B',
  '23028RN4DG',
  'WP5',
  'WP20',
  'SM-G525F',
  OPPO_53S,
  OnePlus6T,
];

export const isValidModel = (model: string) =>
  model && validModels.some((validModel) => model.includes(validModel));

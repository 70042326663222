import { Expression } from 'mapbox-gl';

export const getOpacityForExcludedVehicles = (): Expression => [
  'case',
  [
    'any',
    ['==', ['get', 'isMatchWithFilters'], true],
    ['==', ['get', 'isThereAnActiveWorkUnit'], true],
  ],
  1,
  0.2,
];

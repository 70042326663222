import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'No results found',
  },
  content: {
    defaultMessage: 'There is no data matching your search criteria',
  },
});

import { classNames } from '@cooltra/utils';
import { forwardRef, HTMLAttributes } from 'react';

export type RoundBackgroundProps = HTMLAttributes<HTMLButtonElement>;

export const RoundBackground = forwardRef<
  HTMLButtonElement,
  RoundBackgroundProps
>(({ className, children, ...rest }, ref) => (
  <button
    className={classNames(
      'w-8 h-8 inline-block rounded-full bg-neutral-100 relative tap-highlight',
      className
    )}
    ref={ref}
    {...rest}
  >
    <span className="absolute inset-0 flex items-center justify-center text-neutral-400 text-xs uppercase font-medium">
      {children}
    </span>
  </button>
));

import { useIntl } from 'react-intl';
import { useNotificationContext } from '@cooltra/notifications';

import messages from './messages';

export const useNotification = () => {
  const { formatMessage } = useIntl();
  const { addNotification, removeNotification } = useNotificationContext();

  const addSuccessNotification = (text: string) =>
    addNotification({
      variant: 'success',
      text,
    });

  const addErrorNotification = (text?: string) =>
    addNotification({
      variant: 'danger',
      text: text || formatMessage(messages.errorMessage),
    });

  return {
    addErrorNotification,
    addSuccessNotification,
    removeNotification,
  };
};

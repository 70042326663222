import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { getEnvVariable } from '~/utils/environment';

type FetchDirectionsParams = {
  origin: number[];
  destination: number[];
};

type Geometry = {
  coordinates: number[][];
  type: string;
};

type Step = {
  distance: number;
  geometry: Geometry;
};

type Leg = {
  steps: Step[];
};

type DirectionsResponse = {
  routes: {
    geometry?: {
      coordinates?: number[][];
    };
    legs: Leg[];
  }[];
};

const fetchDirections = ({ origin, destination }: FetchDirectionsParams) => {
  const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${[
    origin.join(','),
    destination.join(','),
  ].join(';')}?geometries=geojson&steps=true&&access_token=${getEnvVariable(
    'MAPBOX_ACCESS_TOKEN'
  )}`;

  return axios
    .get<DirectionsResponse>(url)
    .then((result: { data: DirectionsResponse }) => {
      const coordinates: number[][] = [];

      result.data.routes[0].legs.forEach((leg) => {
        const { steps } = leg;

        //TODO If the performance goes wrong we can check the `distance` attribute to make sure its bigger than the double of the radius.
        steps.forEach(({ geometry }) => {
          coordinates.push(...geometry.coordinates);
        });
      });

      return coordinates;
    });
};

export const useDirections = ({ origin, destination }: FetchDirectionsParams) =>
  useQuery({
    queryKey: ['directions', origin, destination],
    queryFn: () => fetchDirections({ origin, destination }),
    enabled: !!origin.length && !!destination.length,
    staleTime: 1000 * 30,
  });

export type Environment = 'development' | 'stage' | 'production';

type NodeEnvironment = 'development' | 'production' | 'test';

type EnvironmentVariables = {
  NODE_ENV: NodeEnvironment;
  ENVIRONMENT: Environment;
  API: string;
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_AUDIENCE: string;
  MAPBOX_USERNAME: string;
  MAPBOX_ACCESS_TOKEN: string;
  MAPBOX_STYLE: string;
  SENTRY_DSN: string;
  IS_PLAYWRIGHT: string;
};

const environmentVariables = {
  NODE_ENV: process.env.NODE_ENV,
  ENVIRONMENT: process.env.ENVIRONMENT,
  API: process.env.API,
  AUTH0_DOMAIN: process.env.AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE: process.env.AUTH0_AUDIENCE,
  MAPBOX_USERNAME: process.env.MAPBOX_USERNAME,
  MAPBOX_ACCESS_TOKEN: process.env.MAPBOX_ACCESS_TOKEN,
  MAPBOX_STYLE: process.env.MAPBOX_STYLE,
  SENTRY_DSN: process.env.SENTRY_DSN,
  IS_PLAYWRIGHT: process.env.IS_PLAYWRIGHT,
} as EnvironmentVariables;

export const getEnvVariable = <T extends keyof EnvironmentVariables>(
  name: T
) => {
  const value = environmentVariables[name];
  if (!value) {
    throw new Error(`process.env[${name}] is not set`);
  }
  return value;
};

import { Task } from '@cooltra/api';
import { useOperatorsQuery } from '@cooltra/auth-api';

import { operatorGroups } from '~/utils/operator-groups';

export type OperatorOrGroupProps = Pick<Task, 'operatorId' | 'operatorGroupId'>;

export const OperatorOrGroup = ({
  operatorId,
  operatorGroupId,
}: OperatorOrGroupProps) => {
  const { data } = useOperatorsQuery();

  const operatorGroupIdName = Object.values(operatorGroups).find(
    ({ operatorGroupId: operatorGroupIdToCompare }) =>
      operatorGroupIdToCompare === operatorGroupId
  )?.name;

  if (!data) {
    return <></>;
  }

  return (
    <>
      {operatorId
        ? data.find(({ externalId }) => operatorId === externalId)?.name
        : operatorGroupIdName}
    </>
  );
};

import { classNames } from '@cooltra/utils';

import { ListItemBase, ListItemBaseProps } from './ListItemBase';

export type ListItemButtonProps = ListItemBaseProps & {
  onClick: () => void;
  hasTapHighlight?: boolean;
  disabled?: boolean;
};

export const ListItemButton = ({
  onClick,
  hasTapHighlight,
  disabled = false,
  ...rest
}: ListItemButtonProps) => (
  <div className="border-b border-neutral-100">
    <button
      onClick={onClick}
      className={classNames(hasTapHighlight && 'tap-highlight', 'w-full')}
      disabled={disabled}
    >
      <ListItemBase {...rest} />
    </button>
  </div>
);

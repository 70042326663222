import { MdErrorOutline } from 'react-icons/md';
import { Button, Icon } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const MapError = () => {
  const reload = () => window.location.reload();

  return (
    <div className="pt-32 px-4 text-center">
      <Icon className="mb-6 text-2xl p-4 rounded-full text-neutral-500 bg-neutral-50">
        <MdErrorOutline />
      </Icon>
      <h6 className="text-lg font-semibold mb-1">
        <FormattedMessage {...messages.title} />
      </h6>
      <p className="text-sm text-neutral-700 mb-6 px-8">
        <FormattedMessage {...messages.content} />
      </p>
      <div className="flex justify-center">
        <Button onClick={reload} emphasis="high">
          <FormattedMessage {...messages.reload} />
        </Button>
      </div>
    </div>
  );
};

import { FormattedMessage } from 'react-intl';
import { Badge } from '@cooltra/ui';

import messages from './messages';

export const Blocking = () => (
  <Badge className="text-xs" emphasis="high">
    <FormattedMessage {...messages.blocking} />
  </Badge>
);

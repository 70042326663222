import { Model } from '@cooltra/api';
import { useIntl } from 'react-intl';
import { getKeys } from '@cooltra/utils';

import { models } from './messages';

export const useModelLabel = () => {
  const { formatMessage } = useIntl();
  const getModelLabel = (model: Model) => formatMessage(models[model]);
  return { getModelLabel };
};

export const useModelOptions = () => {
  const { formatMessage } = useIntl();
  return getKeys(models)
    .map((model) => ({
      value: model,
      label: formatMessage(models[model]),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

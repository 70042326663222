import { useToggle } from '@cooltra/hooks';
import { Modal, TopLoadingBar } from '@cooltra/ui';
import { useEffect } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';

import { Screen, TopNavigation, Error } from '~/common';
import { useVehicleQuery } from '~/hooks/useVehicleQuery';

import { VehicleInfo } from '../VehicleInfo';
import { VehicleTasks } from '../VehicleTasks/VehicleTasks';
import { Battery } from '../Battery/Battery';
import { Telematics } from '../Telematics/Telematics';
import { Location } from '../Location/Location';
import { ProblemReport } from '../ProblemReport';
import { LastDropOff } from '../LastDropOff';
import { Rentals } from '../Rentals/Rentals';

export const Vehicle = () => {
  const navigate = useNavigate();
  const [open, { toggleOff, toggleOn }] = useToggle();

  const { vehicleId = '' } = useParams<'vehicleId'>();

  const { data: vehicle, isLoading, isError } = useVehicleQuery(vehicleId);

  useEffect(() => {
    toggleOn();
  }, [toggleOn]);

  const renderContent = () => {
    if (isError) {
      return (
        <Screen
          header={
            <TopNavigation.Bar
              renderRight={<TopNavigation.Close onClick={toggleOff} />}
            />
          }
          content={<Error />}
        />
      );
    }

    if (!vehicle) {
      return <></>;
    }

    return (
      <Routes>
        <Route
          path="/"
          element={<VehicleInfo vehicle={vehicle} onClose={toggleOff} />}
        >
          <Route
            path="/battery"
            element={
              <Battery
                vehicleId={vehicle.vehicleId}
                model={vehicle.model}
                telematics={vehicle.telematics}
                onClose={toggleOff}
              />
            }
          />
          <Route
            path="/telematics"
            element={<Telematics vehicle={vehicle} onClose={toggleOff} />}
          />
          <Route
            path="/location"
            element={<Location vehicle={vehicle} onClose={toggleOff} />}
          />
          <Route
            path="/tasks/*"
            element={<VehicleTasks vehicle={vehicle} onClose={toggleOff} />}
          />
          <Route
            path="/problem-reports/:problemReportId"
            element={<ProblemReport onClose={toggleOff} />}
          />
          <Route
            path="/rentals/*"
            element={<Rentals onClose={toggleOff} vehicleId={vehicleId} />}
          />
          <Route
            path="/rentals/:rentalId/drop-off"
            element={
              <LastDropOff vehicleId={vehicle.vehicleId} onClose={toggleOff} />
            }
          />
        </Route>
      </Routes>
    );
  };

  return (
    <Modal
      hideHeader
      type="bottom"
      isOpen={open}
      onAfterClose={() => navigate('/')}
      shouldReturnFocusAfterClose={false}
      shouldCloseOnOverlayClick={false}
      className="rounded-none"
    >
      {isLoading && <TopLoadingBar />}
      {renderContent()}
    </Modal>
  );
};

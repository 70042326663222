import { useLocationsQuery, useTagsQuery } from '@cooltra/api';
import { useOperatorsQuery } from '@cooltra/auth-api';
import { hoursToMilliseconds } from 'date-fns';

import { useUserSystems } from '~/hooks';

export const PrefetchQueries = () => {
  const systems = useUserSystems();

  useLocationsQuery({ system: systems }, { staleTime: hoursToMilliseconds(8) });
  useTagsQuery();
  useOperatorsQuery();

  return null;
};

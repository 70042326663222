import { PropsWithChildren, useState } from 'react';

import { AutomaticHonkContext } from './AutomaticHonkContext';

export const AutomaticHonkProvider = ({ children }: PropsWithChildren) => {
  const [hasHonked, setHasHonked] = useState(false);

  return (
    <AutomaticHonkContext.Provider value={{ hasHonked, setHasHonked }}>
      {children}
    </AutomaticHonkContext.Provider>
  );
};

import { RouterBaseLink, RouterBaseLinkProps } from '@cooltra/navigation';

import { ListItemBase, ListItemBaseProps } from './ListItemBase';

export type ListItemLinkProps = Pick<RouterBaseLinkProps, 'to'> &
  ListItemBaseProps & {
    interactive?: boolean;
  } & {
    testId?: string;
  };

export const ListItemLink = ({
  to,
  interactive = false,
  testId,
  ...rest
}: ListItemLinkProps) => (
  <div className="border-b border-neutral-100 relative">
    {interactive ? (
      <>
        <RouterBaseLink
          to={to}
          className="absolute inset-0 tap-highlight"
          data-testid={testId}
        />
        <ListItemBase {...rest} />
      </>
    ) : (
      <RouterBaseLink
        to={to}
        className="block tap-highlight"
        data-testid={testId}
      >
        <ListItemBase {...rest} />
      </RouterBaseLink>
    )}
  </div>
);

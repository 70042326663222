import { TimelineItem } from '@cooltra/ui';

import { DateTime, RoundOperatorInitials } from '~/common';

export type CommentProps = {
  comment: string;
  createdAt: string;
  operatorId: string;
  isLast: boolean;
};

export const Comment = ({
  createdAt,
  comment,
  operatorId,
  isLast,
}: CommentProps) => (
  <TimelineItem
    header={
      <span className="flex items-center gap-2 text-neutral-500 text-xs">
        {operatorId && <RoundOperatorInitials operatorId={operatorId} />}
        <DateTime date={createdAt} />
      </span>
    }
    isLast={isLast}
    timelineClassName="translate-y-2.5"
  >
    <div className="pt-2 mb-4">
      <p className="text-sm">{comment}</p>
    </div>
  </TimelineItem>
);

import { defineMessages } from 'react-intl';

export default defineMessages({
  helmet: {
    defaultMessage: 'Helmet',
  },
  inVehicle: {
    defaultMessage: 'In vehicle',
  },
  missing: {
    defaultMessage: 'Missing',
  },
});

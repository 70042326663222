import { classNames } from '@cooltra/utils';
import { ButtonHTMLAttributes } from 'react';

export type MapButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isActive?: boolean;
};

export const MapButton = ({
  className,
  isActive = false,
  ...rest
}: MapButtonProps) => (
  <button
    className={classNames(
      'w-11 h-11 bg-neutral-0 shadow-md text-2xl rounded-full tap-highlight border-2 transition-colors',
      isActive
        ? 'text-primary-500 border-primary-500'
        : 'text-neutral-500 border-neutral-0',
      className
    )}
    {...rest}
  />
);

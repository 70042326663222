export type Language = 'es' | 'en' | 'fr' | 'it' | 'pt';

export const defaultLanguage: Language = 'en';

export const translationLanguages: Language[] = ['es', 'fr', 'it', 'pt'];

export const supportedLanguages: Language[] = [
  defaultLanguage,
  ...translationLanguages,
];

import { defineMessages } from 'react-intl';

export default defineMessages({
  scooterWorkUnit: {
    defaultMessage: 'Scooter with a started work-unit',
  },
  bikeWorkUnit: {
    defaultMessage: 'Bike with a started work-unit',
  },
});

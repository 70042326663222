import { AxiosResponse } from 'axios';

import messages from './messages';

export const getErrorMessageForMovingVehicle = (
  errorResponse: AxiosResponse | undefined
): { defaultMessage: string; avoidTranslation?: boolean } => {
  switch (errorResponse?.status) {
    case 403:
      return messages.userDoesNotHavePermissionsToMoveVehicle;
    default:
      return messages.somethingWentWrong;
  }
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Application crashed',
  },
  content: {
    defaultMessage:
      'There was a problem, try refreshing the page or logging back in again',
  },
  reload: {
    defaultMessage: 'Reload',
  },
  logout: {
    defaultMessage: 'Log out',
  },
});

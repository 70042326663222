import { Task, Vehicle } from '@cooltra/api';
import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { TextButton } from '../../TextButton/TextButton';
import { ModelImage } from '../../Model';
import { TaskTags } from '../TaskTags/TaskTags';

import messages from './messages';

export type TaskHeaderProps = {
  vehicle: Vehicle;
  task: Task;
  onCloseTask: () => void;
  onVehicleClick: () => void;
};

export const TaskHeader = ({
  vehicle,
  task,
  onCloseTask,
  onVehicleClick,
}: TaskHeaderProps) => (
  <div className="border-b border-neutral-100 pt-5">
    <div className="px-4 text-center">
      <span className="block font-semibold text-center text-lg">
        {task.title}
      </span>
      <TextButton className="flex justify-center" onClick={onVehicleClick}>
        <div className="flex items-center justify-center my-4">
          <ModelImage
            model={vehicle.model}
            identificationNumber={vehicle.identificationNumber}
            className="h-6 mr-1"
          />
          <span className="text-sm font-semibold">{vehicle.externalId}</span>
        </div>
      </TextButton>
      <TaskTags
        tagIds={task.tagIds}
        blocking={task.blocking}
        className="justify-center mb-6"
      />
      <Button
        onClick={onCloseTask}
        emphasis="high"
        className="rounded-full w-full mb-6"
        size="sm"
      >
        <FormattedMessage {...messages.closeTask} />
      </Button>
    </div>
  </div>
);

import { OperationalCondition, OperationalState } from '@cooltra/api';
import { ReactNode } from 'react';

import { ExtendedOperationalCondition } from '~/libs/vehicles-filters';
import { Screen, TopNavigation, SelectItem } from '~/common';

export type OperationalConditionSelectGroupOption = {
  label: string;
  value: OperationalCondition;
  group: OperationalState;
};

export type OperationalConditionSelectGroup = {
  label: string;
  group: OperationalState;
  options: OperationalConditionSelectGroupOption[];
};

export type OperationalConditionSelectProps = {
  onChange: (values: ExtendedOperationalCondition[]) => void;
  onClose: () => void;
  onBack?: () => void;
  options: OperationalConditionSelectGroup[];
  renderOption: (option: OperationalConditionSelectGroupOption) => ReactNode;
  renderGroupLabel: (groupLabel: OperationalConditionSelectGroup) => ReactNode;
  title: ReactNode;
  values: ExtendedOperationalCondition[];
};

export const OperationalConditionSelect = ({
  onChange,
  onClose,
  onBack,
  options,
  renderOption,
  renderGroupLabel,
  title,
  values,
}: OperationalConditionSelectProps) => {
  const isSelectedOption = (option: OperationalConditionSelectGroupOption) =>
    !!values.find((val) => option.value === val);

  const isDeselectedOption = (option: OperationalConditionSelectGroupOption) =>
    !!values.find((val) => `!${option.value}` === val);

  const handleChange =
    (option: OperationalConditionSelectGroupOption) => () => {
      const newValues = isSelectedOption(option)
        ? values
            .filter((val) => option.value !== val)
            .concat(`!${option.value}`)
        : isDeselectedOption(option)
        ? values.filter((val) => `!${option.value}` !== val)
        : values.concat(option.value);

      onChange(newValues);
    };

  return (
    <Screen
      header={
        <>
          <div className="bg-neutral-0 border-b border-neutral-100">
            <TopNavigation.Bar
              renderLeft={onBack && <TopNavigation.Back onClick={onBack} />}
              renderRight={<TopNavigation.Close onClick={onClose} />}
              title={title}
            />
          </div>
        </>
      }
      content={
        <ul data-testid="MULTI_SELECT_OPTIONS" className="pb-20">
          {options.map((group) => (
            <li key={group.label}>
              {renderGroupLabel(group)}
              <ul>
                {group.options.map((option) => (
                  <SelectItem
                    hasTapHighlight={false}
                    isSelected={!!isSelectedOption(option)}
                    isDeselected={!!isDeselectedOption(option)}
                    onClick={handleChange(option)}
                    key={option.value}
                    data-testid={option.value}
                  >
                    {renderOption(option)}
                  </SelectItem>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      }
    />
  );
};

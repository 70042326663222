import { defineMessages } from 'react-intl';

export default defineMessages({
  search: {
    defaultMessage: 'Search',
  },
  placeholder: {
    defaultMessage: 'License plate, external ID, etc.',
  },
});

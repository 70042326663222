import { MouseEvent } from 'react';
import { MdMyLocation } from 'react-icons/md';
import { Icon } from '@cooltra/ui';
import { useIntl } from 'react-intl';

import { useMapCenter } from '~/libs/map-center';
import { useCurrentPosition } from '~/libs/current-position';

import { MapButton, MapButtonProps } from '../MapButton';

import messages from './messages';

export const GeolocateButton = ({ onClick, isActive }: MapButtonProps) => {
  const { formatMessage } = useIntl();
  const position = useCurrentPosition();

  const { updateViewState } = useMapCenter();

  const geolocateUser = (e: MouseEvent<HTMLButtonElement>) => {
    onClick && onClick(e);
    if (position.length) {
      updateViewState({
        zoom: 16,
        longitude: position[0],
        latitude: position[1],
      });
    }
  };

  return (
    <MapButton
      aria-label={formatMessage(messages.geolocate)}
      onClick={geolocateUser}
      isActive={isActive}
    >
      <Icon>
        <MdMyLocation />
      </Icon>
    </MapButton>
  );
};

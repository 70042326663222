import { OperationalState } from '@cooltra/api';

import {
  getBatteryStatus,
  VehicleBatteryStatus,
  VehicleFeatureBuilderProps,
  VehicleFeatureImageProps,
  VehicleImageType,
} from '../../../tools';

type OperationalStateLocal = OperationalState | 'RESERVED' | 'RENTED';

type OperationalMapVehicleImageName =
  `${VehicleImageType}-${OperationalStateLocal}-${VehicleBatteryStatus}`;

type OperationalMapBonusVehicleImageName =
  `BONUS-${VehicleImageType}-${OperationalStateLocal}-${VehicleBatteryStatus}`;

type OperationalMapVehicleWithTasksImageName =
  `${VehicleImageType}-${OperationalStateLocal}-${VehicleBatteryStatus}-WITH-OPEN-TASKS`;

type OperationalMapBonusVehicleWithTasksImageName =
  `BONUS-${VehicleImageType}-${OperationalStateLocal}-${VehicleBatteryStatus}-WITH-OPEN-TASKS`;

type OperationalMapImageName =
  | OperationalMapVehicleImageName
  | OperationalMapBonusVehicleImageName
  | OperationalMapVehicleWithTasksImageName
  | OperationalMapBonusVehicleWithTasksImageName;

export class OperationalVehicleFeatureImage
  implements VehicleFeatureImageProps
{
  getImageName = ({
    isABike,
    numberOfOpenTasks,
    operationalState,
    isRented,
    isReserved,
    battery,
    bonusEnabled,
    model,
  }: VehicleFeatureBuilderProps): OperationalMapImageName => {
    const bonus = bonusEnabled ? 'BONUS-' : '';
    const vehicle = isABike ? 'BIKE' : 'MOTORCYCLE';
    const withOpenTasks = numberOfOpenTasks > 0 ? '-WITH-OPEN-TASKS' : '';
    const state = isRented
      ? 'RENTED'
      : isReserved
        ? 'RESERVED'
        : operationalState;
    const batteryState = getBatteryStatus(battery, model);

    return `${bonus}${vehicle}-${state}-${batteryState}${withOpenTasks}`;
  };
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  blocking: {
    defaultMessage: 'This is a blocking task',
  },
  notBlocking: {
    defaultMessage: 'This is non-blocking task',
  },
});

import { createContext } from 'react';

export type AutomaticHonkContextShape = {
  hasHonked: boolean;
  setHasHonked: (hasHonked: boolean) => void;
};

export const AutomaticHonkContext = createContext<
  AutomaticHonkContextShape | undefined
>(undefined);

import { PropsWithChildren } from 'react';
import { NotificationsProvider as CooltraNotificationsProvider } from '@cooltra/notifications';

import { Notification } from './Notification';

export const NotificationsProvider = ({ children }: PropsWithChildren) => (
  <CooltraNotificationsProvider
    position={['16px', '16px', 'auto', '16px']}
    dismissAfter={2000}
    renderNotification={({ payload, removeNotification }) => (
      <Notification
        variant={payload.variant}
        draggable
        onClick={removeNotification}
      >
        {payload.text}
      </Notification>
    )}
  >
    {children}
  </CooltraNotificationsProvider>
);

import { AxiosError } from 'axios';
import { Button } from '@cooltra/ui';
import { MdCheckCircleOutline } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';
import { useReleaseVehicleFromLocationMutation } from '@cooltra/api';

import { useVehiclePolling } from '~/libs/polling-data';
import { useNotification } from '~/libs/notifications';

import { ActionButtonProps } from '../types';

import { getErrorMessageForTransferringVehicle } from './errors';
import messages from './messages';

export const ReleaseFromLocation = ({
  vehicleId,
  onSettled,
}: ActionButtonProps) => {
  const { activatePollingVehicle } = useVehiclePolling();
  const { formatMessage } = useIntl();

  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleOnError = (error: AxiosError) => {
    const errorMessageFromStatusCode = getErrorMessageForTransferringVehicle(
      error.response
    );

    addErrorNotification(
      errorMessageFromStatusCode.avoidTranslation
        ? errorMessageFromStatusCode.defaultMessage
        : formatMessage(errorMessageFromStatusCode)
    );
  };

  const handleOnSuccess = () => {
    addSuccessNotification(
      formatMessage(messages.successOnReleasingTheVehicleFromLocation)
    );
    activatePollingVehicle(vehicleId);
  };

  const { isPending, mutate } = useReleaseVehicleFromLocationMutation(
    vehicleId,
    {
      onError: handleOnError,
      onSuccess: handleOnSuccess,
      onSettled,
    }
  );

  const releaseVehicleFromLocation = () => mutate();

  return (
    <div className="w-1/3 flex flex-col items-center justify-center">
      <Button
        className="p-0 w-16 h-16 bg-primary-500 rounded-full btn-size-lg text-neutral-0"
        loading={isPending}
        disabled={isPending}
        onClick={releaseVehicleFromLocation}
        aria-label={formatMessage(messages.releaseFromLocation)}
        square
      >
        <MdCheckCircleOutline className="w-7 h-7" />
      </Button>
      <span className="w-24 text-center text-sm text-neutral-0 mt-1">
        <FormattedMessage {...messages.releaseFromLocation} />
      </span>
    </div>
  );
};

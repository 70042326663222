import { RentalListItem, useRentalsQuery } from '@cooltra/api';
import { MdCheckCircleOutline } from 'react-icons/md';
import { Spinner } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { ListItemErrorIcon, ListItemLink, TimeAgo } from '~/common';

import messages from './messages';

export type RentalsListItemProps = {
  vehicleId: string;
};

export const RentalsListItem = ({ vehicleId }: RentalsListItemProps) => {
  const { isError, isLoading, data } = useRentalsQuery(
    { vehicleId, limit: 5, page: 1 },
    { enabled: !!vehicleId }
  );

  const rentals = data?.pages
    ? ([] as RentalListItem[]).concat.apply([], data.pages)
    : [];

  const lastRental = rentals[0];

  const renderContent = () => {
    if (isLoading) {
      return <Spinner size="sm" />;
    }

    if (isError) {
      return <ListItemErrorIcon />;
    }

    if (!lastRental) {
      return <FormattedMessage {...messages.none} />;
    }

    if (!lastRental.dropOff) {
      return <FormattedMessage {...messages.currentlyRented} />;
    }

    return <TimeAgo ISODate={lastRental.dropOff.createdAt} />;
  };

  return (
    <li data-testid="VEHICLE_RENTALS_ITEM">
      <ListItemLink
        to={lastRental ? 'rentals' : '#'}
        leadingIcon={
          <MdCheckCircleOutline className="text-2xl text-neutral-500 mr-2" />
        }
        hasChevron
      >
        <div className="flex justify-between items-center gap-4">
          <span className="text-base font-semibold text-neutral-700">
            <FormattedMessage {...messages.lastRentals} />
          </span>
          <span className="text-sm text-neutral-500 pl-2 truncate">
            {renderContent()}
          </span>
        </div>
      </ListItemLink>
    </li>
  );
};

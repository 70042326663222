import { defineMessages } from 'react-intl';

export default defineMessages({
  operator: {
    defaultMessage: 'Operator',
  },
  operators: {
    defaultMessage: 'Operators',
  },
});

import { FormattedMessage, useIntl } from 'react-intl';
import { useOperatorsQuery } from '@cooltra/auth-api';
import { useField, useFormContext } from '@cooltra/form';
import { Modal, SelectOption } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';

import { InputFieldButton, Select, SelectItemText } from '~/common';
import {
  canAccessHomeSystem,
  isBlocked,
  isOperatorGroup,
} from '~/utils/operators';
import { useUserSystems } from '~/hooks';

import messages from './messages';

export const Operator = () => {
  const { formatMessage } = useIntl();
  const { isSubmitting } = useFormContext();

  const systems = useUserSystems();

  const [isOpen, { toggleOff, toggleOn }] = useToggle();

  const { value: operatorGroupId } = useField('operatorGroupId');
  const { data: operators = [], isLoading } = useOperatorsQuery();
  const filteredOperators = operators
    .filter(isBlocked(false))
    .filter(canAccessHomeSystem(systems))
    .filter(isOperatorGroup([operatorGroupId]));

  const { value, setValue } = useField('operatorId');

  const handleChange = (option: SelectOption) => {
    setValue(option.value);
    toggleOff();
  };

  return (
    <>
      <InputFieldButton
        disabled={isSubmitting}
        testId="OPERATOR_FIELD"
        optional
        label={<FormattedMessage {...messages.operator} />}
        value={
          filteredOperators.find((operator) => operator.externalId === value)
            ?.name
        }
        onClear={() => setValue('')}
        onClick={toggleOn}
      />
      <Modal
        hideHeader
        type="bottom"
        isOpen={isOpen}
        shouldReturnFocusAfterClose={false}
        shouldCloseOnOverlayClick={false}
        className="rounded-none"
      >
        <Select
          title={formatMessage(messages.operators)}
          value={
            filteredOperators.find((operator) => operator.externalId === value)
              ?.externalId
          }
          isSearchable
          onChange={handleChange}
          isLoading={isLoading}
          onClose={toggleOff}
          options={filteredOperators.map(({ name, externalId }) => ({
            label: name,
            value: externalId,
          }))}
          renderOption={({ label }) => <SelectItemText>{label}</SelectItemText>}
        />
      </Modal>
    </>
  );
};

import { classNames } from '@cooltra/utils';
import { ButtonHTMLAttributes } from 'react';

export type OutlineButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isActive?: boolean;
};

export const OutlineButton = ({
  isActive,
  className,
  ...rest
}: OutlineButtonProps) => (
  <button
    className={classNames(
      'h-10 rounded-lg border px-4 text-sm font-semibold transition-colors whitespace-nowrap',
      isActive
        ? 'border-primary-500 text-primary-700 ring-1 ring-primary-500'
        : 'border-neutral-100 text-neutral-500 ring-1 ring-neutral-0',
      className
    )}
    {...rest}
  />
);

import { FormattedMessage } from 'react-intl';
import { VehicleStatus } from '@cooltra/api';

import messages from './messages';
import { ConnectionBadge } from './ConnectionBadge';

export type ConnectionProps = {
  vehicleStatus: VehicleStatus;
};

export const Connection = ({ vehicleStatus }: ConnectionProps) => {
  if (vehicleStatus.offline === null) {
    return (
      <ConnectionBadge status="offline">
        <FormattedMessage {...messages.unknown} />
      </ConnectionBadge>
    );
  }

  if (vehicleStatus.offline) {
    return (
      <ConnectionBadge status="offline">
        <FormattedMessage {...messages.offline} />
      </ConnectionBadge>
    );
  }

  return (
    <ConnectionBadge status="online">
      <FormattedMessage {...messages.online} />
    </ConnectionBadge>
  );
};

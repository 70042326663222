import { ChangeEvent, useEffect, useState } from 'react';
import { Input, InputProps } from '@cooltra/ui';
import { MdClose } from 'react-icons/md';
import { useIntl } from 'react-intl';

import { IconButton } from '../InteractiveIcon';

import messages from './messages';

export type ClearableInputProps = Omit<InputProps, 'onChange'> & {
  onChange: (value: string) => void;
};

export const ClearableInput = ({
  onChange,
  value,
  ...rest
}: ClearableInputProps) => {
  const { formatMessage } = useIntl();
  const [internalValue, setInternalValue] = useState(value);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setInternalValue(target.value);
    onChange(target.value);
  };

  const handleClear = () => {
    setInternalValue('');
    onChange('');
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <Input
      autoFocus
      onChange={handleChange}
      value={internalValue}
      trailingIcon={
        internalValue && (
          <IconButton
            onClick={handleClear}
            type="button"
            className="h-full w-full"
            aria-label={formatMessage(messages.clear)}
          >
            <MdClose />
          </IconButton>
        )
      }
      {...rest}
    />
  );
};

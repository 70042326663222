import { useCallback, useState } from 'react';

const pageSize = 20;

export const usePagination = () => {
  const [page, setPage] = useState(1);

  const goToNextPage = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
  }, []);

  const getPaginatedData = useCallback(
    <T extends unknown[]>(data: T): T => data.slice(0, pageSize * page) as T,
    [page]
  );

  return {
    getPaginatedData,
    goToNextPage,
  };
};

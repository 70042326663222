import { useWorkUnitQuery, VehicleListItem } from '@cooltra/api';
import { isBike } from '@cooltra/vehicle-utils';
import { useQueryClient } from '@tanstack/react-query';

import { useUserSystems } from './useUserSystems';

export const useWorkUnitVehicle = () => {
  const systems = useUserSystems();
  const queryClient = useQueryClient();

  const vehicles = queryClient.getQueryData<VehicleListItem[]>([
    'vehicles',
    { system: systems },
  ]);

  const { data: workUnit } = useWorkUnitQuery();

  if (!workUnit || !vehicles) {
    return {
      isBike: false,
      geoLocation: [],
    };
  }

  const vehicle = vehicles.find(
    (vehicle) => vehicle.vehicleId === workUnit.vehicleId
  );

  if (!vehicle || !vehicle.status.geoLocation) {
    return {
      isBike: false,
      geoLocation: [],
    };
  }

  return {
    isBike: isBike(vehicle),
    geoLocation: [
      vehicle.status.geoLocation.longitude,
      vehicle.status.geoLocation.latitude,
    ],
  };
};

import { useEffect } from 'react';

import { useLocalStorage } from '~/hooks';

import {
  vehiclesFiltersInitialValues,
  VehiclesFiltersFormValues,
} from './vehicles-filters-form';
import { useVehiclesFilters } from './useVehiclesFilters';

export const localStorageKey = 'vehicles-filter-values';

const oldLocalStorageKeys = ['vehicles-filters'];

export const VehiclesFilterFormPersistor = () => {
  const [, setValue] = useLocalStorage<VehiclesFiltersFormValues>(
    localStorageKey,
    vehiclesFiltersInitialValues
  );
  const { values } = useVehiclesFilters();

  useEffect(() => {
    oldLocalStorageKeys.forEach((key) => localStorage.removeItem(key));
  }, []);

  useEffect(() => {
    setValue(values);
  }, [setValue, values]);

  return null;
};

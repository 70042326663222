import { Model } from '@cooltra/api';
import { chargePerLevelRanges } from '@cooltra/vehicle-utils';

export type VehicleBatteryStatus =
  | 'NONE'
  | 'DISCHARGED'
  | 'LOW'
  | 'MEDIUM'
  | 'NEUTRAL'
  | 'HIGH';

export const getBatteryStatus = (
  batteryCharge: number | undefined | null,
  model: Model
): VehicleBatteryStatus => {
  const chargePerLevelRange = chargePerLevelRanges[model];

  if (batteryCharge === undefined || batteryCharge === null) {
    return 'NONE';
  }

  if (batteryCharge == chargePerLevelRange.low.min) {
    return 'DISCHARGED';
  }

  if (batteryCharge < chargePerLevelRange.medium.min) {
    return 'LOW';
  }

  if (batteryCharge < chargePerLevelRange.neutral.min) {
    return 'MEDIUM';
  }

  if (batteryCharge < chargePerLevelRange.high.min) {
    return 'NEUTRAL';
  }

  return 'HIGH';
};

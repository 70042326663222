import { Badge } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const TakenByOperatorBadge = () => (
  <Badge className="text-xs" variant="neutral" emphasis="low">
    <FormattedMessage {...messages.takenByOperator} />
  </Badge>
);

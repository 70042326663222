import { operatorGroups } from '~/utils/operator-groups';

import { VehiclesFiltersExpression } from '../is-preset-match';

export const offlineLocationId = '3ea04fbf-cec4-4816-bbed-1c30d844be3d';
export const streetRepairLocationId = '90bba318-b556-4f04-b908-2e7991d59bb3';

export const swapper: VehiclesFiltersExpression = {
  and: [
    { notContainOperationalCondition: 'RENTED' },
    { notContainOperationalCondition: 'RESERVED' },
    {
      or: [
        { locationId: '' },
        { locationId: offlineLocationId },
        { locationId: streetRepairLocationId },
      ],
    },
    {
      or: [
        { model: 'ASKOLL_ES2' },
        { model: 'ASKOLL_ES2_B2B' },
        { model: 'NIU_N1S' },
        { model: 'NIU_N1S_25' },
      ],
    },
    {
      or: [
        {
          isTaskMatch: {
            blocking: true,
            operatorGroupId: operatorGroups.SWAPPER.operatorGroupId,
          },
        },
        { batteryRange: [0, 21] },
      ],
    },
  ],
};

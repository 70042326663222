import {
  usePatchTaskMutation,
  useTaskQuery,
  useVehicleQuery,
} from '@cooltra/api';
import { useOperatorsQuery } from '@cooltra/auth-api';
import { useToggle } from '@cooltra/hooks';
import { Modal, TopLoadingBar } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';

import { useNotification } from '~/libs/notifications';
import {
  canAccessHomeSystem,
  isBlocked,
  isOperatorGroup,
} from '~/utils/operators';
import { useUserSystems } from '~/hooks';

import { TaskTextButton } from '../TaskTextButton';
import { Select, SelectItemText, SelectOption } from '../../../Select';
import { Operator } from '../../../Operator';

import messages from './messages';

export type AssigneeItemProps = {
  operatorGroupId: string;
  operatorId?: string;
  taskId: string;
};

export const AssigneeItem = ({
  operatorId,
  operatorGroupId,
  taskId,
}: AssigneeItemProps) => {
  const { formatMessage } = useIntl();
  const systems = useUserSystems();

  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const operatorsQuery = useOperatorsQuery({
    enabled: false,
  });
  const operators = operatorsQuery.data || [];
  const filteredOperators = operators
    .filter(isBlocked(false))
    .filter(canAccessHomeSystem(systems))
    .filter(isOperatorGroup([operatorGroupId]));

  const { data: task } = useTaskQuery(taskId);
  const { data: vehicle } = useVehicleQuery(task?.vehicleId || '', {
    enabled: !!task,
  });
  const patchTaskMutation = usePatchTaskMutation(taskId);

  const patchTask = async (operatorId: string) => {
    if (task && vehicle) {
      return patchTaskMutation
        .mutateAsync({
          blocking: task.blocking,
          description: task.description,
          operatorGroupId: task.operatorGroupId,
          operatorId,
          problemReportId: task.problemReportId,
          tagIds: task.tagIds,
          title: task.title,
          vehicleExternalId: vehicle.externalId,
          vehicleId: task.vehicleId,
        })
        .then(() => {
          addSuccessNotification(formatMessage(messages.success));
        })
        .catch(() => {
          addErrorNotification();
        });
    }
  };

  const handleChange = ({ value }: SelectOption) => {
    if (task && vehicle) {
      toggleOff();
      patchTask(value);
    }
  };

  const handleClear = () => {
    patchTask('');
  };

  return (
    <>
      {(operatorsQuery.isLoading || patchTaskMutation.isPending) && (
        <TopLoadingBar />
      )}
      <div
        data-testid="ASSIGNEE_ITEM"
        className="flex justify-between items-end"
      >
        <div>
          <span className="block text-sm text-neutral-300 mb-0.5">
            <FormattedMessage {...messages.assignee} />
          </span>
          <span className="block text-sm">
            <Operator operatorId={operatorId} />
          </span>
        </div>
        <div className="flex">
          {operatorId && (
            <TaskTextButton
              variant="danger"
              onClick={handleClear}
              className="px-2"
              disabled={operatorsQuery.isLoading || patchTaskMutation.isPending}
            >
              <FormattedMessage {...messages.remove} />
            </TaskTextButton>
          )}
          <TaskTextButton
            variant="primary"
            onClick={toggleOn}
            className="px-4 -mr-3"
            disabled={operatorsQuery.isLoading || patchTaskMutation.isPending}
          >
            <FormattedMessage {...messages.edit} />
          </TaskTextButton>
        </div>
      </div>
      <Modal
        hideHeader
        testId="TASK_OPERATOR_MODAL"
        type="bottom"
        isOpen={isOpen}
        shouldReturnFocusAfterClose={false}
        shouldCloseOnOverlayClick={false}
        className="rounded-none"
      >
        <Select
          isSearchable
          title={formatMessage(messages.assignee)}
          value={operatorId}
          onChange={handleChange}
          onClose={toggleOff}
          options={filteredOperators.map(({ name, externalId }) => ({
            label: name,
            value: externalId,
          }))}
          renderOption={({ label }) => <SelectItemText>{label}</SelectItemText>}
        />
      </Modal>
    </>
  );
};

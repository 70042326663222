import { Marker } from 'react-map-gl';

import checkoutError from './assets/checkout-error.svg';

export type CheckOutFailureMarkerProps = {
  latitude: number;
  longitude: number;
};

export const CheckOutFailureMarker = ({
  latitude,
  longitude,
}: CheckOutFailureMarkerProps) => (
  <Marker latitude={latitude} longitude={longitude}>
    <img className="w-6" src={checkoutError} />
  </Marker>
);

import { Route, Routes } from 'react-router-dom';

import { LastRentals } from './LastRentals';
import { Rental } from './Rental/Rental';
import { RentalMap } from './RentalMap/RentalMap';

export type RentalsProps = {
  onClose: () => void;
  vehicleId: string;
};

export const Rentals = ({ onClose, vehicleId }: RentalsProps) => (
  <Routes>
    <Route
      path="/"
      element={<LastRentals onClose={onClose} vehicleId={vehicleId} />}
    />
    <Route
      path="/:rentalId"
      element={<Rental onClose={onClose} vehicleId={vehicleId} />}
    />
    <Route
      path="/:rentalId/timeline-map"
      element={<RentalMap onClose={onClose} />}
    />
  </Routes>
);

import { Rental, VehicleStatus } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';
import { Divider, TimelineItem } from '@cooltra/ui';
import { RouterButton } from '@cooltra/navigation';

import { LabelAndValue } from '~/common';

import { RentalDuration } from '../RentalDuration/RentalDuration';
import { RentalTotalDistance } from '../RentalTotalDistance/RentalTotalDistance';

import { RentalPhoto } from './RentalPhoto/RentalPhoto';
import { DropOff } from './DropOff/DropOff';
import { PickUp } from './PickUp/PickUp';
import messages from './messages';

export type LastDropOffProps = {
  rental: Rental;
  vehicleStatus?: VehicleStatus;
};

export const RentalContent = ({ rental, vehicleStatus }: LastDropOffProps) => {
  const { dropOff, pickUp, system } = rental;

  return (
    <div className="flex flex-col max-h-full">
      <div className="w-full flex items-center justify-between border-b border-neutral-100">
        <div className="flex gap-4 px-4 py-5">
          <div className="grid grid-cols-2 gap-2">
            <LabelAndValue
              label={<FormattedMessage {...messages.duration} />}
              value={
                <RentalDuration
                  startDate={pickUp.createdAt}
                  finishDate={dropOff?.createdAt || new Date()}
                />
              }
            />
          </div>
          <div className="grid grid-cols-2 gap-2">
            <LabelAndValue
              label={<FormattedMessage {...messages.totalDistance} />}
              value={
                <RentalTotalDistance
                  vehicleStatus={vehicleStatus}
                  startMileage={pickUp.mileage}
                  finishMileage={dropOff?.mileage}
                />
              }
            />
          </div>
        </div>
        {rental?.vehicle?.model === 'ARCADE_X2C' && (
          <RouterButton
            to="timeline-map"
            variant="primary"
            emphasis="high"
            className="mr-5"
          >
            <FormattedMessage {...messages.viewTimeline} />
          </RouterButton>
        )}
      </div>
      <div className="mt-5 mb-8">
        <TimelineItem
          data-testid="PICK_UP"
          header={
            <span className="font-semibold">
              <FormattedMessage {...messages.pickUp} />
            </span>
          }
          className="pr-14"
          contentClassName="w-full"
          timelineClassName="translate-y-2.5"
        >
          <div className="mt-1 pb-4">
            <PickUp
              vehicleStatus={vehicleStatus}
              system={system}
              pickUp={pickUp}
            />
          </div>
        </TimelineItem>
        {dropOff ? (
          <TimelineItem
            data-testid="DROP_OFF"
            header={
              <span className="font-semibold">
                <FormattedMessage {...messages.dropOff} />
              </span>
            }
            isLast
            className="pr-14"
            contentClassName="w-full"
            timelineClassName="translate-y-2.5"
          >
            <div className="mt-1">
              <DropOff
                vehicleStatus={vehicleStatus}
                system={system}
                dropOff={dropOff}
              />
            </div>
          </TimelineItem>
        ) : (
          <TimelineItem
            data-testid="DROP_OFF"
            header={
              <span className="font-semibold text-success-600">
                <FormattedMessage {...messages.inProgress} />
              </span>
            }
            isLast
            className="pr-14"
            contentClassName="w-full"
            timelineClassName="translate-y-2.5"
          >
            <div className="mt-1">
              <span className="text-sm">
                <FormattedMessage {...messages.now} />
              </span>
            </div>
          </TimelineItem>
        )}
      </div>
      <Divider />
      <div className="flex-1">
        {dropOff?.parkingPhoto && <RentalPhoto name={dropOff.parkingPhoto} />}
      </div>
    </div>
  );
};

import { useAuthClaimsQuery } from '@cooltra/auth-api';

import { operatorGroups } from '~/utils/operator-groups';

export const useRoleNames = () => {
  const { data: claims } = useAuthClaimsQuery();

  if (!claims?.roles || !claims.roles.length) {
    return [];
  }

  return claims.roles.map(
    (operatorGroupId) =>
      Object.values(operatorGroups).find(
        (operatorGroup) => operatorGroup.operatorGroupId === operatorGroupId
      )?.name
  );
};

import { classNames, equal } from '@cooltra/utils';
import { RouterBaseLink } from '@cooltra/navigation';
import { useIntl } from 'react-intl';
import { MdTune } from 'react-icons/md';

import {
  useVehiclesFilters,
  vehiclesFiltersInitialValues,
} from '~/libs/vehicles-filters';

import messages from './messages';

export type FilterLinkProps = {
  className?: string;
};

export const FilterLink = ({ className }: FilterLinkProps) => {
  const { formatMessage } = useIntl();
  const { values } = useVehiclesFilters();
  const isActive = !equal(vehiclesFiltersInitialValues, values);

  return (
    <RouterBaseLink
      to="/filters"
      aria-label={formatMessage(messages.filters)}
      className={classNames(
        'bg-neutral-0 shadow-md absolute rounded-full z-20 w-12 h-12',
        isActive ? 'text-primary-500' : 'text-neutral-500',
        className
      )}
    >
      <MdTune className="rotate-90 text-2xl" />
      {isActive && (
        <span
          aria-label={formatMessage(messages.active)}
          className="absolute w-3 h-3 inline-block rounded-full bg-primary-500 bottom-0.5 right-0"
        />
      )}
    </RouterBaseLink>
  );
};

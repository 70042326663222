import { defineMessages } from 'react-intl';

export default defineMessages({
  telematics: {
    defaultMessage: 'Sensors',
  },
  issues: {
    defaultMessage: '{issuesCount, plural, one {# issue} other {# issues}}',
  },
  noIssues: {
    defaultMessage: 'Normal',
  },
});

import { classNames } from '@cooltra/utils';
import { ReactNode } from 'react';
import './overlay.css';

export type OverlayProps = {
  isOpen: boolean;
  children: ReactNode;
  onClick: () => void;
};

export const Overlay = ({ isOpen, children, onClick }: OverlayProps) => (
  <div
    className={classNames(
      'absolute left-0 bottom-0 floating-menu-overlay z-20',
      isOpen && 'floating-menu-overlay--opened'
    )}
  >
    <div className="w-full h-full relative">
      <div className="absolute inset-0" tabIndex={0} onClick={onClick} />
      {children}
    </div>
  </div>
);

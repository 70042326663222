import { TopLoadingBar } from '@cooltra/ui';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';

import { Screen } from '../Screen/Screen';
import { TopNavigation } from '../TopNavigation';
import { ClearableInput } from '../ClearableInput/ClearableInput';
import { NoSearchResults } from '../NoSearchResults/NoSearchResults';

import { SelectItem } from './SelectItem';
import { MultiSelectBadge } from './MultiSelectBadge';
import messages from './messages';

export type MultiSelectGroupOption<
  Value extends string,
  Group extends string
> = {
  label: string;
  value: Value;
  group: Group;
};

export type MultiSelectGroup<Value extends string, Group extends string> = {
  label: string;
  group: Group;
  options: MultiSelectGroupOption<Value, Group>[];
};

export type GroupedMultiSelectProps<
  Value extends string,
  Group extends string
> = {
  isLoading?: boolean;
  isSearchable?: boolean;
  searchPlaceholder?: string;
  onChange: (values: MultiSelectGroupOption<Value, Group>[]) => void;
  onClose: () => void;
  onBack?: () => void;
  options: MultiSelectGroup<Value, Group>[];
  renderOption: (option: MultiSelectGroupOption<Value, Group>) => ReactNode;
  renderGroupLabel: (groupLabel: MultiSelectGroup<Value, Group>) => ReactNode;
  title: ReactNode;
  value: Value[];
};

export function GroupedMultiSelect<Value extends string, Group extends string>({
  isLoading = false,
  isSearchable = false,
  searchPlaceholder,
  onChange,
  onClose,
  onBack,
  options,
  renderOption,
  renderGroupLabel,
  title,
  value,
}: GroupedMultiSelectProps<Value, Group>) {
  const { formatMessage } = useIntl();

  const [searchInput, setSearchInput] = useState('');

  const flattenedOptions = options.map(({ options }) => options).flat();

  const selectedOptions = value
    .map((value) => flattenedOptions.find((option) => option.value === value))
    .filter((option) => !!option) as MultiSelectGroupOption<Value, Group>[];

  const handleChange = (option: MultiSelectGroupOption<Value, Group>) => () => {
    const hasBeenSelected = !!selectedOptions.find(
      (selected) => selected.value === option.value
    );

    const updatedSelection = hasBeenSelected
      ? selectedOptions.filter(({ value }) => option.value !== value)
      : selectedOptions.concat(option);

    onChange(updatedSelection);
  };

  const filteredOptions = isSearchable
    ? options
        .map((group) => ({
          label: group.label,
          options: group.options.filter(
            (option) =>
              !value.includes(option.value) &&
              option.label.toLowerCase().includes(searchInput.toLowerCase())
          ),
          group: group.group,
        }))
        .filter((group) => !!group.options.length)
    : options;

  const handleRemovingBadge = (badgeValue: string) => () => {
    const updatedSelection = selectedOptions.filter(
      ({ value }) => badgeValue !== value
    );
    onChange(updatedSelection);
  };

  return (
    <Screen
      header={
        <>
          {isLoading && <TopLoadingBar />}
          <div className="bg-neutral-0 border-b border-neutral-100">
            <TopNavigation.Bar
              renderLeft={onBack && <TopNavigation.Back onClick={onBack} />}
              renderRight={<TopNavigation.Close onClick={onClose} />}
              title={title}
            />
            {isSearchable && (
              <div className="px-4 pb-4 pt-1">
                <ClearableInput
                  aria-label={formatMessage(messages.search)}
                  autoFocus
                  placeholder={searchPlaceholder}
                  value={searchInput}
                  onChange={setSearchInput}
                />
              </div>
            )}
            {isSearchable && !!value.length && (
              <ul
                className="flex gap-3 flex-wrap px-4 pb-4"
                data-testid="MULTI_SELECT_VALUES"
              >
                {value.map((val) => (
                  <li key={val}>
                    <MultiSelectBadge onClick={handleRemovingBadge(val)}>
                      {
                        flattenedOptions.find((option) => option.value === val)
                          ?.label
                      }
                    </MultiSelectBadge>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </>
      }
      content={
        <>
          {!isLoading && isSearchable && !filteredOptions.length && (
            <NoSearchResults />
          )}
          <ul data-testid="MULTI_SELECT_OPTIONS" className="pb-20">
            {filteredOptions.map((group) => (
              <li key={group.label}>
                {renderGroupLabel(group)}
                <ul>
                  {group.options.map((option) => (
                    <SelectItem
                      hasTapHighlight={isSearchable}
                      isSelected={value.includes(option.value)}
                      onClick={handleChange(option)}
                      key={option.value}
                      data-testid={option.value}
                    >
                      {renderOption(option)}
                    </SelectItem>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </>
      }
    />
  );
}

import { HTMLAttributes } from 'react';
import { classNames } from '@cooltra/utils';
import { VehicleStatus } from '@cooltra/api';

import { OngoingRentalTotalDistance } from './OngoingRentalTotalDistance';

export type RentalTotalDistanceProps = HTMLAttributes<HTMLSpanElement> & {
  startMileage: number;
  finishMileage?: number;
  vehicleStatus?: VehicleStatus;
};

export const RentalTotalDistance = ({
  vehicleStatus,
  startMileage,
  finishMileage,
  className,
}: RentalTotalDistanceProps) => {
  if (!finishMileage) {
    return (
      <OngoingRentalTotalDistance
        vehicleStatus={vehicleStatus}
        startMileage={startMileage}
      />
    );
  }

  const totalMileageInMts = finishMileage - startMileage;

  if (totalMileageInMts < 1000) {
    return (
      <span className={classNames('block tabular-nums', className)}>
        {totalMileageInMts.toFixed(1)} mts
      </span>
    );
  }

  const totalMileageInKms = totalMileageInMts / 1000;

  return (
    <span className={classNames('block tabular-nums', className)}>
      {totalMileageInKms.toFixed(1)} kms
    </span>
  );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  lastRentals: {
    defaultMessage: 'Last rentals',
  },
  none: {
    defaultMessage: 'None',
  },
  currentlyRented: {
    defaultMessage: 'In a rental',
  },
});

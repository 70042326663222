import { Rental, VehicleStatus } from '@cooltra/api';
import { MdLocationOn } from 'react-icons/md';

import { DateTime, GoogleMapsLink } from '~/common';

type DropOffProps = Pick<Rental, 'dropOff' | 'system'> & {
  vehicleStatus?: VehicleStatus;
};

export const DropOff = ({ vehicleStatus, system, dropOff }: DropOffProps) => {
  if (!vehicleStatus || !dropOff) {
    return <></>;
  }

  return (
    <div className="flex flex-col">
      <span className="flex items-center gap-1 text-sm text-neutral-800 mb-1">
        <MdLocationOn className="opacity-40" />
        <DateTime date={dropOff.createdAt} />
      </span>
      <div className="block break-words">
        <GoogleMapsLink
          className="block font-normal break-words"
          geoLocation={{
            latitude: dropOff.latitude,
            longitude: dropOff.longitude,
            updatedAt: dropOff.createdAt,
          }}
          system={system}
        />
      </div>
    </div>
  );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  sync: {
    defaultMessage: 'Sync',
  },
  syncSuccess: {
    defaultMessage: 'Vehicle has been successfully synced',
  },
});

import { FormattedMessage, useIntl } from 'react-intl';
import { useDisableAlarmMutation } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { AxiosError } from 'axios';
import { MdOutlineNotificationsOff } from 'react-icons/md';

import { useNotification } from '~/libs/notifications';
import { useVehiclePolling } from '~/libs/polling-data';
import { VehicleActionButton } from '~/common/FloatingMenu/VehicleActionButton/VehicleActionButton';

import { errorMessages } from '../error-messages';
import { ActionButtonProps } from '../types';

import messages from './messages';

export const DisableAlarm = ({ vehicleId, onSettled }: ActionButtonProps) => {
  const { formatMessage } = useIntl();
  const { activatePollingVehicle } = useVehiclePolling();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleOnSuccess = () => {
    addSuccessNotification(formatMessage(messages.successOnDisableAlarm));
    activatePollingVehicle(vehicleId);
  };

  const handleOnError = (error: AxiosError) => {
    let errorNotification;

    switch (getErrorStatus(error)) {
      case 409:
        errorNotification = formatMessage(errorMessages.conflictInAction);
        break;
      case 422:
        errorNotification = formatMessage(
          errorMessages.notProperlyConfiguredVehicle
        );
        break;
      case 502:
        errorNotification = formatMessage(
          errorMessages.issueWithTelematicsProvider
        );
        break;
      case 504:
        errorNotification = formatMessage(errorMessages.vehicleIsOffline);
        break;
    }

    addErrorNotification(errorNotification);
  };

  const { isPending, mutate } = useDisableAlarmMutation(vehicleId, {
    onError: handleOnError,
    onSuccess: handleOnSuccess,
    onSettled,
  });

  return (
    <VehicleActionButton
      loading={isPending}
      icon={<MdOutlineNotificationsOff className="w-7 h-7" />}
      onClick={() => mutate()}
      ariaLabel={formatMessage(messages.disableAlarm)}
    >
      <FormattedMessage {...messages.disableAlarm} />
    </VehicleActionButton>
  );
};

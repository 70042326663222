import { useLocationsQuery } from '@cooltra/api';
import { FormattedMessage, useIntl } from 'react-intl';

import { SelectItemText, TopNavigation } from '~/common';
import { useUserSystems } from '~/hooks';

import { FiltersFormMultiSelect } from '../FiltersFormMultiSelect';
import { FilterRouteProps } from '../types';

import messages from './messages';

export const LocationFilter = ({ onClose, onBack }: FilterRouteProps) => {
  const { formatMessage } = useIntl();
  const systems = useUserSystems();
  const { data: locations = [], isLoading } = useLocationsQuery(
    { system: systems },
    {
      enabled: false,
    }
  );

  return (
    <FiltersFormMultiSelect
      onBack={onBack}
      onClose={onClose}
      name="location"
      isSearchable
      searchPlaceholder={formatMessage(messages.searchPlaceholder)}
      isLoading={isLoading}
      title={
        <TopNavigation.Title>
          <FormattedMessage {...messages.title} />
        </TopNavigation.Title>
      }
      renderOption={({ label }) => <SelectItemText>{label}</SelectItemText>}
      options={locations
        .map(({ name, locationId }) => ({
          value: locationId,
          label: name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))}
    />
  );
};

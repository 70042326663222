import { operatorGroups } from '~/utils/operator-groups';

export type OperatorGroupProps = {
  operatorGroupId: string;
};

export const OperatorGroup = ({ operatorGroupId }: OperatorGroupProps) => {
  const operatorGroupName = Object.values(operatorGroups).find(
    ({ operatorGroupId: operatorGroupIdToCompare }) =>
      operatorGroupIdToCompare === operatorGroupId
  )?.name;

  return <>{operatorGroupName || '-'}</>;
};

import { useToggle } from '@cooltra/hooks';
import { FormattedMessage } from 'react-intl';
import { Button, InfiniteList, TopLoadingBar } from '@cooltra/ui';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useWorkUnitQuery, VehicleListItem } from '@cooltra/api';
import { Outlet } from 'react-router-dom';

import { Error, NoSearchResults, FloatingMenu } from '~/common';
import { useSelectVehicle } from '~/libs/select-vehicle';
import { usePagination } from '~/hooks';
import { useFilteredVehiclesQuery } from '~/libs/vehicles-filters';
import { isTestingEnv, isTestingJestEnv } from '~/utils/e2e';

import { VehicleMapCard } from '../VehicleMapCard/VehicleMapCard';
import { VehicleListSheet } from '../VehicleListSheet/VehicleListSheet';
import { VehicleCard } from '../VehicleCard/VehicleCard';
import { VehiclesMultiViewMap } from '../VehiclesMultiViewMap/VehiclesMultiViewMap';

import { RoundRouterLink } from './RoundRouterLink';
import messages from './messages';
import { FilterLink } from './FilterLink/FilterLink';
import { WorkUnitVehicleLink } from './WorkUnitVehicleLink/WorkUnitVehicleLink';
import { AutomaticHonk } from './AutomaticHonk/AutomaticHonk';

const renderRow = (vehicle: VehicleListItem) => (
  <VehicleCard vehicle={vehicle} />
);

export const Vehicles = () => {
  const { user } = useAuth0();

  const { getPaginatedData, goToNextPage } = usePagination();

  const [isOpen, { toggleOn, toggleOff }] = useToggle(false);
  const { vehicleId } = useSelectVehicle();

  const {
    data: { vehicles, filteredVehicles, filteredVehiclesTotal },
    isLoading,
    isError,
  } = useFilteredVehiclesQuery();

  const { data: workUnit } = useWorkUnitQuery();

  useEffect(() => {
    if (vehicleId) {
      toggleOff();
    }
  }, [toggleOff, vehicleId]);

  if (isLoading) {
    return <TopLoadingBar />;
  }

  if (isError) {
    return (
      <div
        data-testid="VEHICLES_SCREEN_ERROR"
        className={isTestingEnv() ? 'fixed h-full w-full' : 'h-full relative'}
      >
        <RoundRouterLink className="top-4 right-4" to="/profile">
          <img src={user?.picture} alt={user?.name} className="max-w-full" />
        </RoundRouterLink>
        <Error />
      </div>
    );
  }

  return (
    <>
      <div className="h-full relative" data-testid="VEHICLES_SCREEN">
        <FilterLink className="top-4 left-4" />
        <RoundRouterLink className="top-4 right-4" to="/profile">
          <img src={user?.picture} className="max-w-full" alt={user?.name} />
        </RoundRouterLink>
        <WorkUnitVehicleLink />
        <AutomaticHonk />
        {!isTestingJestEnv() && <VehiclesMultiViewMap vehicles={vehicles} />}
        <VehicleMapCard vehicleId={vehicleId} />
        {vehicleId && <FloatingMenu vehicleId={vehicleId} />}
        <VehicleListSheet isOpen={isOpen}>
          <InfiniteList
            className="gap-3 px-4"
            loadingRows={0}
            isLoading={false}
            isFetchingNextPage={false}
            fetchMore={goToNextPage}
            idProp="vehicleId"
            data={getPaginatedData(filteredVehicles)}
            scrollableTarget="scrollable-vehicle-list"
            renderEmptyMessage={<NoSearchResults />}
            renderRow={renderRow}
          />
        </VehicleListSheet>
        {!workUnit && !vehicleId && (
          <Button
            emphasis="high"
            className="fab-button"
            onClick={isOpen ? toggleOff : toggleOn}
          >
            {isOpen ? (
              <FormattedMessage {...messages.map} />
            ) : (
              <FormattedMessage {...messages.list} />
            )}
            <span className="ml-1 font-light">{`(${filteredVehiclesTotal})`}</span>
          </Button>
        )}
      </div>
      <Outlet />
    </>
  );
};

import { useVehicleStatusQuery, Vehicle } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { MdBattery4Bar } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import {
  BatteryIcon,
  BatteryIconLabel,
  ListItemLink,
  NotAvailable,
} from '~/common';

import messages from './messages';

export type BatteryListItemProps = {
  vehicleId: string;
} & Pick<Vehicle, 'model'>;

export const BatteryListItem = ({ vehicleId, model }: BatteryListItemProps) => {
  const { data: status, error } = useVehicleStatusQuery(vehicleId, {
    retry: false,
  });

  const renderBatteryStatus = () => {
    if (getErrorStatus(error) === 404) {
      return <NotAvailable />;
    }

    if (!status) {
      return <></>;
    }

    if (status.batteryCharge === null) {
      return <NotAvailable />;
    }

    return (
      <div className="flex items-center">
        <BatteryIconLabel
          charge={status.batteryCharge.value}
          model={model}
          className="mr-1.5 mt-px text-xs"
        />
        <div className="w-6 h-3">
          <BatteryIcon model={model} charge={status.batteryCharge.value} />
        </div>
      </div>
    );
  };

  return (
    <li data-testid="VEHICLE_BATTERY_LIST_ITEM">
      <ListItemLink
        to="battery"
        leadingIcon={
          <MdBattery4Bar className="text-2xl text-neutral-500 mr-2" />
        }
        hasChevron
      >
        <div className="flex justify-between items-center gap-4">
          <span className="text-base font-semibold text-neutral-700">
            <FormattedMessage {...messages.battery} />
          </span>
          {renderBatteryStatus()}
        </div>
      </ListItemLink>
    </li>
  );
};

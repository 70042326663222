import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { FormCheckboxField } from '@cooltra/form';

import messages from './messages';

export const Blocking = () => (
  <FormCheckboxField
    id="blocking-task"
    label={
      <InputLabel emphasis="low" htmlFor="blocking-task">
        <FormattedMessage {...messages.blocking} />
      </InputLabel>
    }
    name="blocking"
  />
);

import { differenceInSeconds } from 'date-fns';
import { classNames } from '@cooltra/utils';
import { HTMLAttributes } from 'react';

export type RentalDurationProps = HTMLAttributes<HTMLSpanElement> & {
  startDate: Date | string;
  finishDate: Date | string;
};

export const RentalDuration = ({
  startDate,
  finishDate,
  className,
}: RentalDurationProps) => {
  const durationInSeconds = differenceInSeconds(
    new Date(finishDate),
    new Date(startDate)
  );

  const hours = Math.floor(durationInSeconds / (60 * 60));
  const minutes = Math.floor(durationInSeconds / 60) - hours * 60;
  const seconds = durationInSeconds % 60;

  const padWith0 = (num: number) => num.toString().padStart(2, '0');

  return (
    <span className={classNames('block tabular-nums', className)}>
      {`${padWith0(hours)}:${padWith0(minutes)}:${padWith0(seconds)}`}
    </span>
  );
};

import messages from './messages';

export const getErrorMessageFromStatusCode = (statusCode: number) => {
  switch (statusCode) {
    case 401:
      return messages.notAuthorizedToStartWorkUnit;
    case 422:
      return messages.cannotStartWorkUnit;
    default:
      return messages.somethingWentWrong;
  }
};

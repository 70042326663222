import { defineMessages } from 'react-intl';

export default defineMessages({
  alarm: {
    defaultMessage: 'Alarm',
  },
  deactivated: {
    defaultMessage: 'Deactivated',
  },
  activated: {
    defaultMessage: 'Activated',
  },
});

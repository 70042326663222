import { HTMLAttributes } from 'react';
import { classNames } from '@cooltra/utils';
import { VehicleListItem } from '@cooltra/api';
import { chargePerLevelRanges } from '@cooltra/vehicle-utils';

export type BatteryIconLabelProps = Omit<
  HTMLAttributes<HTMLSpanElement>,
  'children'
> &
  Pick<VehicleListItem, 'model'> & {
    charge: number;
  };

export const BatteryIconLabel = ({
  charge,
  model,
  className,
  ...rest
}: BatteryIconLabelProps) => {
  let textClassName;
  const chargePerLevelRange = chargePerLevelRanges[model];

  switch (true) {
    case charge <= chargePerLevelRange.low.max:
      textClassName = 'text-danger-400';
      break;
    case charge <= chargePerLevelRange.medium.max:
      textClassName = 'text-warning-600';
      break;
    case charge <= chargePerLevelRange.neutral.max:
      textClassName = 'text-neutral-alternative-800';
      break;
    default:
      textClassName = 'text-success-500';
      break;
  }

  return (
    <span className={classNames(textClassName, className)} {...rest}>
      {charge}%
    </span>
  );
};

import { VehicleStatus } from '@cooltra/api';

export const countIssues = (vehicleStatus: VehicleStatus) => {
  if (!vehicleStatus) {
    return 0;
  }

  let count = 0;

  if (vehicleStatus.centralStandUp?.value) {
    count++;
  }
  if (vehicleStatus.helmetPresent && !vehicleStatus.helmetPresent.value) {
    count++;
  }
  if (vehicleStatus.locked && !vehicleStatus.locked.value) {
    count++;
  }
  if (vehicleStatus.ignition?.value) {
    count++;
  }
  if (vehicleStatus.topCaseOpen?.value) {
    count++;
  }

  return count;
};

import { FormattedMessage } from 'react-intl';
import { Button, Modal } from '@cooltra/ui';
import { useWorkUnitQuery } from '@cooltra/api';
import { RouterButton } from '@cooltra/navigation';
import { useNavigate } from 'react-router-dom';

import messages from './messages';

export type EndShiftWithOngoingWorkUnitModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const EndShiftWithOngoingWorkUnitModal = ({
  isOpen,
  onClose,
}: EndShiftWithOngoingWorkUnitModalProps) => {
  const { data: workUnit } = useWorkUnitQuery();
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
    navigate('/');
  };

  return (
    <Modal isOpen={isOpen} hideHeader className="mx-10 rounded-3xl">
      <div className="p-6 w-80" data-testid="CONFIRM_END_SHIFT_MODAL">
        <h3 className="text-neutral-1000 font-semibold text-base text-center mb-4">
          <FormattedMessage {...messages.title} />
        </h3>
        <p className="text-sm text-neutral-500 text-center mb-6">
          <FormattedMessage {...messages.beforeEnd} />
        </p>
        <div className="flex flex-col gap-2.5">
          {workUnit && (
            <RouterButton
              className="rounded-full"
              emphasis="high"
              to={`/vehicles/${workUnit.vehicleId}`}
            >
              <FormattedMessage {...messages.seeWorkUnit} />
            </RouterButton>
          )}
          <Button className="rounded-full" onClick={handleClose}>
            <FormattedMessage {...messages.cancel} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

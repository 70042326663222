import type { SVGProps } from 'react';

export const Motorcycle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13 3a1 1 0 0 0 0 2h2.323l1.553 3.883L13.465 14H10V9a1 1 0 0 0-1-1H2a1 1 0 0 0-.65 1.76l2.887 2.475a3.501 3.501 0 1 0 4.728 3.764L9 16h5a1 1 0 0 0 .832-.445l4-6a1 1 0 0 0 .096-.926L18.279 7h1.125c.16 0 .299-.056.418-.168A.52.52 0 0 0 20 6.441V4.567a.525.525 0 0 0-.284-.475.59.59 0 0 0-.582-.028l-1.708.808-.497-1.243A1 1 0 0 0 16 3h-3Zm-8.297 7L8 12.826V10H4.703ZM19.5 14a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM16 15.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Zm-12 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
      clipRule="evenodd"
    />
  </svg>
);

import { captureMessage } from '@sentry/react';
import { User } from '@auth0/auth0-react';

import { isUserAgentData } from '~/utils/user-agent';

export const getDeviceModel = async (user: User | undefined) => {
  const userAgentData = window.navigator && window.navigator.userAgentData;

  if (!isUserAgentData(userAgentData)) {
    return '';
  }

  return userAgentData
    .getHighEntropyValues(['model'])
    .then(({ model }) => model || '')
    .catch(() =>
      captureMessage('Error getting model', {
        tags: { user: user?.sub },
      })
    );
};

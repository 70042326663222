import { useCallback } from 'react';
import { useRentalQuery } from '@cooltra/api';
import { shortenId } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { Error, Screen, TopNavigation } from '~/common';

import { RentalMapContent } from './RentalMapContent';
import messages from './messages';

type RentalProps = {
  onClose: () => void;
};

export const RentalMap = ({ onClose }: RentalProps) => {
  const navigate = useNavigate();
  const { rentalId = '' } = useParams<'rentalId'>();
  const { vehicleId = '' } = useParams<'vehicleId'>();

  const {
    isError,
    isLoading,
    data: rental,
  } = useRentalQuery(rentalId, { enabled: !!vehicleId && !!rentalId });
  const onBack = () => navigate(`/vehicles/${vehicleId}/rentals/${rentalId}`);

  const renderContent = useCallback(() => {
    if (isError) {
      return <Error />;
    }

    if (isLoading) {
      return <></>;
    }

    if (!rental) {
      return <></>;
    }

    return <RentalMapContent rental={rental} />;
  }, [isError, isLoading, rental]);

  return (
    <Screen
      data-testid="RENTAL_MAP_SCREEN"
      header={
        <>
          <div className="bg-neutral-0 border-b border-neutral-100">
            <TopNavigation.Bar
              renderLeft={<TopNavigation.Back onClick={onBack} />}
              renderRight={<TopNavigation.Close onClick={onClose} />}
              title={
                <TopNavigation.Title>
                  <FormattedMessage
                    {...messages.title}
                    values={{ rentalId: `#${shortenId(rentalId)}` }}
                  />
                </TopNavigation.Title>
              }
            />
          </div>
        </>
      }
      content={renderContent()}
    />
  );
};

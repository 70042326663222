import { useState } from 'react';
import {
  FetchTasksRequestParams,
  TaskListItem,
  useTasksQuery,
  useTasksTotal,
} from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { InfiniteList, TopLoadingBar } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useToggle } from '@cooltra/hooks';

import {
  TopNavigation,
  Screen,
  Error,
  TaskCard,
  CloseTaskModal,
  NoTasksFound,
} from '~/common';
import { useRoleNames } from '~/hooks';

import messages from './messages';

export type MyGroupTasksProps = {
  onClose: () => void;
};

export const MyGroupTasks = ({ onClose }: MyGroupTasksProps) => {
  const { data: claims } = useAuthClaimsQuery();
  const navigate = useNavigate();

  const [isOpen, { toggleOff, toggleOn }] = useToggle();
  const [selectedTask, selectTask] = useState('');

  const roleNames = useRoleNames();
  const hasAnyRole = !!roleNames.length;

  const params: FetchTasksRequestParams = {
    open: 'true',
    operatorGroupId: claims?.roles,
  };

  const { data, isLoading, fetchNextPage, isFetchingNextPage, isError } =
    useTasksQuery(params, {
      enabled: hasAnyRole,
    });

  const tasks = data?.pages
    ? ([] as TaskListItem[]).concat.apply([], data.pages)
    : [];

  const tasksTotal = useTasksTotal(params) || 0;

  const handleDeselectingTask = () => selectTask('');

  const handleSelectingTask = (taskId: string) => {
    toggleOn();
    selectTask(taskId);
  };

  const goBack = () => navigate('/profile');

  const onTaskCloseSuccess = () => navigate('/profile/group-tasks');

  const renderContent = () => {
    if (isError) {
      return <Error />;
    }

    return (
      <div className="pb-16 overflow-x-hidden">
        <InfiniteList
          hasMore={tasks.length < tasksTotal}
          className="gap-3"
          loadingRows={0}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          fetchMore={fetchNextPage}
          idProp="id"
          data={tasks}
          scrollableTarget="scrollable-task-list"
          renderEmptyMessage={<NoTasksFound />}
          renderRow={(task) => (
            <TaskCard
              to={`/profile/tasks/${task.id}`}
              onCloseTask={handleSelectingTask}
              task={task}
            />
          )}
        />
      </div>
    );
  };

  if (!hasAnyRole) {
    return <></>;
  }

  return (
    <>
      <CloseTaskModal
        onSuccess={onTaskCloseSuccess}
        isOpen={isOpen}
        onClose={toggleOff}
        onAfterClose={handleDeselectingTask}
        taskId={selectedTask}
      />
      <Screen
        contentId="scrollable-task-list"
        data-testid="MY_GROUP_TASKS_SCREEN"
        header={
          <>
            {isLoading && <TopLoadingBar />}
            <div className="bg-neutral-0 border-b border-neutral-100">
              <TopNavigation.Bar
                renderLeft={<TopNavigation.Back onClick={goBack} />}
                renderRight={<TopNavigation.Close onClick={onClose} />}
                title={
                  <div className="flex flex-col">
                    <TopNavigation.Title>
                      {roleNames.length === 1 ? (
                        <FormattedMessage
                          {...messages.singleRoleTitle}
                          values={{
                            total: tasksTotal,
                            operatorGroupName: roleNames[0],
                          }}
                        />
                      ) : (
                        <FormattedMessage
                          {...messages.multipleRolesTitle}
                          values={{
                            total: tasksTotal,
                          }}
                        />
                      )}
                    </TopNavigation.Title>
                    {roleNames.length > 1 && (
                      <span className="text-xs text-neutral-500">
                        {roleNames.join(', ')}
                      </span>
                    )}
                  </div>
                }
              />
            </div>
          </>
        }
        content={renderContent()}
      />
    </>
  );
};

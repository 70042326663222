import { singleVehiclePointLayer } from './higher-layers';
import {
  singleVehicleExpandedBigLayer,
  singleVehicleExpandedLayer,
} from './lower-layers';

export const layers = [
  String(singleVehicleExpandedLayer.id),
  String(singleVehicleExpandedBigLayer.id),
  String(singleVehiclePointLayer.id),
];

import { createContext } from 'react';
import { ViewState } from 'react-map-gl';

export type BaseViewState = Pick<ViewState, 'latitude' | 'longitude' | 'zoom'>;

export type CurrentPositionContextShape = number[];

export const CurrentPositionContext = createContext<
  CurrentPositionContextShape | undefined
>(undefined);

import { FormattedMessage, useIntl } from 'react-intl';
import { useUnlockBatteryMutation, useWorkUnitQuery } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { AxiosError } from 'axios';
import { MdBattery4Bar } from 'react-icons/md';

import { useNotification } from '~/libs/notifications';
import { useVehiclePolling } from '~/libs/polling-data';
import { VehicleActionButton } from '~/common/FloatingMenu/VehicleActionButton/VehicleActionButton';

import { errorMessages } from '../error-messages';
import { ActionButtonProps } from '../types';

import messages from './messages';

export const UnlockBattery = ({ vehicleId, onSettled }: ActionButtonProps) => {
  const { formatMessage } = useIntl();
  const { activatePollingVehicle } = useVehiclePolling();
  const { data: workUnit, isLoading } = useWorkUnitQuery();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleOnSuccess = () => {
    addSuccessNotification(formatMessage(messages.batteryUnlockedSuccessfully));
    activatePollingVehicle(vehicleId);
  };

  const handleOnError = (error: AxiosError) => {
    let errorNotification;

    switch (getErrorStatus(error)) {
      case 409:
        errorNotification = formatMessage(errorMessages.conflictInAction);
        break;
      case 422:
        errorNotification = formatMessage(
          errorMessages.notProperlyConfiguredVehicle
        );
        break;
      case 502:
        errorNotification = formatMessage(
          errorMessages.issueWithTelematicsProvider
        );
        break;
      case 504:
        errorNotification = formatMessage(errorMessages.vehicleIsOffline);
        break;
    }

    addErrorNotification(errorNotification);
  };

  const { isPending, mutate } = useUnlockBatteryMutation(vehicleId, {
    onError: handleOnError,
    onSuccess: handleOnSuccess,
    onSettled,
  });

  const unlockBattery = () => {
    if (!workUnit) {
      addErrorNotification(formatMessage(messages.workUnitNotFound));
      return;
    }

    if (workUnit.vehicleId !== vehicleId) {
      addErrorNotification(
        formatMessage(messages.workUnitStartedWithOtherVehicle)
      );
      return;
    }

    mutate();
  };

  return (
    <VehicleActionButton
      loading={isPending || isLoading}
      icon={<MdBattery4Bar className="w-7 h-7" />}
      onClick={unlockBattery}
      ariaLabel={formatMessage(messages.unlockBattery)}
    >
      <FormattedMessage {...messages.unlockBattery} />
    </VehicleActionButton>
  );
};

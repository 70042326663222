import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: "Oops, something's wrong!",
  },
  content: {
    defaultMessage:
      'Try logging in and out of your account, or contact support if the problem persists',
  },
  logout: {
    defaultMessage: 'Log out',
  },
});

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { stringifyParams } from '@cooltra/utils';

import { getEnvVariable } from '~/utils/environment';

type ReverseGeocodeParams = {
  latitude: number;
  longitude: number;
};

type ReverseGeocodeResponse = {
  features: {
    place_name: string;
  }[];
};

export const reverseGeocode = ({
  latitude,
  longitude,
}: ReverseGeocodeParams) => {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?${stringifyParams(
    {
      limit: 1,
      types: 'address',
      access_token: getEnvVariable('MAPBOX_ACCESS_TOKEN'),
    }
  )}`;

  return axios
    .get<ReverseGeocodeResponse>(url)
    .then(({ data }) => data.features[0]?.place_name || '');
};

export const useReverseGeocode = (
  { longitude, latitude }: ReverseGeocodeParams,
  options: Omit<
    UseQueryOptions<string, AxiosError, string, [string, number, number]>,
    'queryKey'
  > = {}
) =>
  useQuery({
    queryKey: ['reverse-geocode', longitude, latitude],
    queryFn: () => reverseGeocode({ longitude, latitude }),
    ...options,
  });

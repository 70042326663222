import { useAuth0 } from '@auth0/auth0-react';
import {
  closeWorkUnit,
  lockVehicle,
  useWorkUnitFutureStateQuery,
} from '@cooltra/api';
import { getRequestConfig } from '@cooltra/axios';
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { useCurrentPosition } from '~/libs/current-position';

export type CloseWorkUnitPayload = {
  comment: string;
};

export const useLockAndCloseMutation = (
  vehicleId: string,
  workUnitId?: string,
  {
    onSuccess,
    ...options
  }: UseMutationOptions<
    [AxiosResponse, AxiosResponse],
    AxiosError,
    CloseWorkUnitPayload
  > = {}
) => {
  const queryClient = useQueryClient();

  const currentPosition = useCurrentPosition();
  const { getAccessTokenSilently } = useAuth0();

  const { data: workUnitFutureState } = useWorkUnitFutureStateQuery(
    workUnitId || '',
    { enabled: false }
  );

  return useMutation({
    mutationFn: async (values) => {
      const accessToken = await getAccessTokenSilently();

      const config = getRequestConfig(accessToken);
      const configWithGeoposition = getRequestConfig(
        accessToken,
        currentPosition.length
          ? [currentPosition[1], currentPosition[0]]
          : undefined
      );

      if (!workUnitId) {
        return Promise.reject('Missing workUnitId');
      }

      if (!workUnitFutureState) {
        return Promise.reject('Missing work unit future state');
      }

      return Promise.all([
        closeWorkUnit(
          {
            batteryCharge: workUnitFutureState.batteryCharge,
            operationalState: workUnitFutureState.operationalState,
            ...values,
          },
          configWithGeoposition
        ),
        lockVehicle(vehicleId, config),
      ]);
    },
    onSuccess: (data, values, context) => {
      queryClient.invalidateQueries({ queryKey: ['vehicle', vehicleId] });
      queryClient.invalidateQueries({ queryKey: ['work-unit'] });
      onSuccess && onSuccess(data, values, context);
    },
    ...options,
  });
};

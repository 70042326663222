import { useContext } from 'react';

import { CurrentPositionContext } from './CurrentPositionContext';

export const useCurrentPosition = () => {
  const currentPositionContext = useContext(CurrentPositionContext);

  if (!currentPositionContext) {
    throw new Error(
      'useCurrentPosition can be only used within <CurrentPositionProvider />'
    );
  }

  return currentPositionContext;
};

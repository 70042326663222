import { FormattedMessage } from 'react-intl';
import { Button, Modal } from '@cooltra/ui';
import { RouterButton } from '@cooltra/navigation';
import { useNavigate } from 'react-router-dom';

import messages from './messages';

export type EndShiftWithOngoingTakenVehiclesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  myTakenVehiclesCount: number;
};

export const EndShiftWithOngoingTakenVehiclesModal = ({
  isOpen,
  onClose,
  myTakenVehiclesCount,
}: EndShiftWithOngoingTakenVehiclesModalProps) => {
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
    navigate('/');
  };

  return (
    <Modal isOpen={isOpen} hideHeader className="mx-10 rounded-3xl">
      <div className="p-6 w-80" data-testid="CONFIRM_END_SHIFT_MODAL">
        <h3 className="text-neutral-1000 font-semibold text-base text-center mb-4">
          <FormattedMessage
            {...messages.title}
            values={{ myTakenVehiclesCount }}
          />
        </h3>
        <p className="text-sm text-neutral-500 text-center mb-6">
          <FormattedMessage {...messages.areYouSure} />
        </p>
        <div className="flex flex-col gap-2.5">
          <RouterButton
            to="my-taken-vehicles"
            className="rounded-full"
            emphasis="high"
          >
            <FormattedMessage {...messages.endShift} />
          </RouterButton>
          <Button className="rounded-full" onClick={handleClose}>
            <FormattedMessage {...messages.cancel} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

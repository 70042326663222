import { getKeys } from '@cooltra/utils';

import { IdleFrom } from '~/libs/vehicles-filters';

export const useIdleFrom = () => {
  const idleFromLabels: Record<IdleFrom, string> = {
    '1-20h': '1-20h',
    '20-24h': '20-24h',
    '1-2d': '1-2d',
    '2-3d': '2-3d',
    '3-4d': '3-4d',
    '4-5d': '4-5d',
    '5-10d': '5-10d',
    '+10d': '+10d',
  };

  return getKeys(idleFromLabels).map((idleFromLabels) => ({
    value: idleFromLabels,
    label: idleFromLabels,
  }));
};

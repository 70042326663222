import { MdClose } from 'react-icons/md';
import { Badge, Icon } from '@cooltra/ui';
import { ReactNode } from 'react';

export type MultiSelectBadgeProps = {
  onClick: () => void;
  children: ReactNode;
};

export const MultiSelectBadge = ({
  onClick,
  children,
}: MultiSelectBadgeProps) => (
  <button
    className="rounded-full overflow-hidden tap-highlight"
    onClick={onClick}
  >
    <Badge>
      <span className="flex gap-1 items-center">
        {children}
        <Icon className="text-xs text-neutral-400">
          <MdClose />
        </Icon>
      </span>
    </Badge>
  </button>
);

import { useVehicleTasksQuery } from '@cooltra/api';
import { MdCheckCircleOutline } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { ListItemErrorIcon, ListItemLink, OpenTasksCount } from '~/common';

import messages from './messages';

export type OpenTaskListItemProps = {
  vehicleId: string;
};

export const OpenTasksListItem = ({ vehicleId }: OpenTaskListItemProps) => {
  const {
    data: tasks = [],
    isLoading,
    isError,
  } = useVehicleTasksQuery(
    vehicleId,
    { open: 'true' },
    { enabled: !!vehicleId }
  );

  const blockingTasks = tasks.filter(({ blocking }) => blocking);

  return (
    <li data-testid="VEHICLE_TASKS_LIST_ITEM">
      <ListItemLink
        to="tasks"
        leadingIcon={
          <MdCheckCircleOutline className="text-2xl text-neutral-500 mr-2" />
        }
        hasChevron
      >
        <div className="flex justify-between items-center gap-4">
          <span className="text-base font-semibold text-neutral-700">
            <FormattedMessage {...messages.openTasks} />
          </span>
          <span className="text-sm text-neutral-500 pl-2 truncate">
            {isError ? (
              <ListItemErrorIcon />
            ) : isLoading ? null : (
              <OpenTasksCount
                totalTasks={tasks.length}
                totalBlockingTasks={blockingTasks.length}
              />
            )}
          </span>
        </div>
      </ListItemLink>
    </li>
  );
};

import { FormattedMessage } from 'react-intl';
import { Button, Icon } from '@cooltra/ui';
import { MdErrorOutline } from 'react-icons/md';

import messages from './messages';

export type ErrorProps = {
  title?: string;
};

export const Error = ({ title }: ErrorProps) => {
  const reload = () => window.location.reload();

  return (
    <div className="pt-32 px-4 text-center">
      <Icon className="mb-6 text-2xl p-4 rounded-full text-neutral-500 bg-neutral-50">
        <MdErrorOutline />
      </Icon>
      <h6 className="text-lg font-semibold mb-1">
        {title || <FormattedMessage {...messages.title} />}
      </h6>
      <p className="text-sm text-neutral-700 mb-6">
        <FormattedMessage {...messages.content} />
      </p>
      <Button emphasis="high" onClick={reload}>
        <FormattedMessage {...messages.reload} />
      </Button>
    </div>
  );
};

import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type MyTakenVehiclesCountProps = {
  totalTakenVehicles?: number;
};

export const MyTakenVehiclesCount = ({
  totalTakenVehicles,
}: MyTakenVehiclesCountProps) => {
  if (!totalTakenVehicles) {
    return <FormattedMessage {...messages.noTakenVehicles} />;
  }

  if (totalTakenVehicles === 1) {
    return (
      <FormattedMessage
        {...messages.oneTakenVehicle}
        values={{ totalTakenVehicles }}
      />
    );
  }

  if (totalTakenVehicles > 1) {
    return (
      <FormattedMessage
        {...messages.totalTakenVehicles}
        values={{ totalTakenVehicles }}
      />
    );
  }

  return <FormattedMessage {...messages.noTakenVehicles} />;
};

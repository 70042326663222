import { useLocationsQuery, Vehicle } from '@cooltra/api';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { TopLoadingBar } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';
import { useRouteState } from '@cooltra/navigation';

import {
  TopNavigation,
  Screen,
  NoLocation,
  ListItemButton,
  ClearableInput,
  NoSearchResults,
} from '~/common';
import { useLocationName, useUserSystems } from '~/hooks';

import messages from './messages';
import { ChangeLocationModal } from './ChangeLocationModal/ChangeLocationModal';

export type LocationProps = {
  onClose: () => void;
  vehicle: Vehicle;
};

export const Location = ({
  onClose,
  vehicle: { vehicleId, locationId },
}: LocationProps) => {
  const navigate = useNavigate();
  const routeState = useRouteState<'from'>();

  const { formatMessage } = useIntl();

  const onBack = () => navigate(`/vehicles/${vehicleId}`);

  const [isOpen, { toggleOff, toggleOn }] = useToggle();

  const systems = useUserSystems();

  const { data: locations, isLoading } = useLocationsQuery({
    system: systems,
  });

  const locationName = useLocationName(locationId);

  const [keyword, setKeyword] = useState('');
  const [changeLocationModalState, setChangeLocationModalState] = useState({
    newLocationId: '',
  });

  const handleDeselectingLocation = () => {
    if (routeState?.from === '/') {
      onClose();
    } else {
      navigate(`/vehicles/${vehicleId}`);
    }
  };

  const handleSelectingLocation = (locationId: string) => () => {
    toggleOn();
    setChangeLocationModalState({
      newLocationId: locationId,
    });
  };
  const filteredLocations =
    locations?.filter((location) => location.name.includes(keyword)) || [];

  const noLocationsFound = !isLoading && !filteredLocations?.length;

  return (
    <Screen
      data-testid="VEHICLE_LOCATION_SCREEN"
      header={
        <>
          {isLoading && <TopLoadingBar />}
          <div className="border-b border-neutral-100">
            <TopNavigation.Bar
              renderLeft={<TopNavigation.Back onClick={onBack} />}
              renderRight={<TopNavigation.Close onClick={onClose} />}
              title={
                <TopNavigation.Title>
                  <FormattedMessage {...messages.title} />
                </TopNavigation.Title>
              }
            />
            <span
              data-testid="VEHICLE_CURRENT_LOCATION"
              className="h-5 mb-12 block text-center text-sm text-neutral-600"
            >
              {isLoading ? (
                <FormattedMessage {...messages.loading} />
              ) : (
                locationName || <NoLocation />
              )}
            </span>
            <div className="px-4 mb-6">
              <span className="mb-3 block text-center text-sm text-neutral-1000 font-semibold">
                <FormattedMessage {...messages.changeLocation} />
              </span>
              <ClearableInput
                autoFocus
                onChange={setKeyword}
                placeholder={formatMessage(messages.placeholder)}
                aria-label={formatMessage(messages.search)}
                value={keyword}
              />
            </div>
          </div>
        </>
      }
      content={
        <div className="pb-20">
          <ChangeLocationModal
            isOpen={isOpen}
            closeModal={toggleOff}
            onAfterClose={handleDeselectingLocation}
            newLocationId={changeLocationModalState.newLocationId}
          />
          {noLocationsFound && <NoSearchResults />}
          <ul>
            {filteredLocations.map((location) => (
              <li key={location.locationId}>
                <ListItemButton
                  hasTapHighlight
                  onClick={handleSelectingLocation(location.locationId)}
                >
                  <span className="text-base text-neutral-600">
                    {location.name}
                  </span>
                </ListItemButton>
              </li>
            ))}
          </ul>
        </div>
      }
    />
  );
};

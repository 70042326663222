import { defineMessages } from 'react-intl';

export default defineMessages({
  centralStand: {
    defaultMessage: 'Central stand',
  },
  up: {
    defaultMessage: 'Up',
  },
  down: {
    defaultMessage: 'Down',
  },
});

import { useFormContext } from '@cooltra/form';
import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const SubmitButton = () => {
  const { isSubmitting } = useFormContext();
  return (
    <Button
      className="rounded-full fab-button"
      emphasis="high"
      type="submit"
      loading={isSubmitting}
    >
      <FormattedMessage {...messages.addTask} />
    </Button>
  );
};

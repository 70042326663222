import { useProblemReportQuery } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { TopLoadingBar } from '@cooltra/ui';
import { shortenId } from '@cooltra/utils';

import { useVehicleQuery } from '~/hooks';
import { Error, ModelImage, Screen, TopNavigation } from '~/common';

import { ProblemReportContent } from '../ProblemReportContent/ProblemReportContent';

import messages from './messages';

export type ProblemReportProps = {
  onClose: () => void;
};

export const ProblemReport = ({ onClose }: ProblemReportProps) => {
  const navigate = useNavigate();
  const { problemReportId = '' } = useParams<'problemReportId'>();

  const {
    data: problemReport,
    isError,
    isLoading,
  } = useProblemReportQuery(problemReportId);

  const vehicleId = problemReport?.vehicleId || '';
  const { data: vehicle } = useVehicleQuery(vehicleId, {
    enabled: !!vehicleId,
  });

  const goBack = () => navigate(-1);

  if (isError) {
    return (
      <Screen
        data-testid="PROBLEM_REPORT_SCREEN"
        header={
          <div className="border-b border-neutral-100">
            <TopNavigation.Bar
              renderLeft={<TopNavigation.Back onClick={goBack} />}
              renderRight={<TopNavigation.Close onClick={onClose} />}
              title={
                <TopNavigation.Title>
                  <FormattedMessage {...messages.title} />
                </TopNavigation.Title>
              }
            />
          </div>
        }
        content={<Error />}
      />
    );
  }

  return (
    <Screen
      data-testid="PROBLEM_REPORT_SCREEN"
      header={
        <div className="border-b border-neutral-100">
          {isLoading && <TopLoadingBar />}
          <TopNavigation.Bar
            renderLeft={<TopNavigation.Back onClick={goBack} />}
            renderRight={<TopNavigation.Close onClick={onClose} />}
            title={
              <TopNavigation.Title>
                <FormattedMessage {...messages.title} />
              </TopNavigation.Title>
            }
          />
          {problemReport && vehicle && (
            <div className="pt-5 pb-6 border-t border-neutral-100">
              <span className="block font-semibold text-center text-lg">
                #{shortenId(problemReport.problemReportId)}
              </span>
              <div className="flex items-center justify-center mt-4">
                <ModelImage
                  model={vehicle.model}
                  identificationNumber={vehicle.identificationNumber}
                  className="h-6 mr-1"
                />
                <span className="text-sm font-semibold">
                  {vehicle.externalId}
                </span>
              </div>
            </div>
          )}
        </div>
      }
      content={
        problemReport && <ProblemReportContent problemReport={problemReport} />
      }
    />
  );
};

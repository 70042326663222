import { FormattedMessage } from 'react-intl';
import { MdOutlineLocationOn } from 'react-icons/md';
import { Vehicle } from '@cooltra/api';

import { ListItemLink, NoLocation } from '~/common';
import { useLocationName } from '~/hooks';

import messages from './messages';

export type LocationListItemProps = Pick<Vehicle, 'locationId'>;

export const LocationListItem = ({ locationId }: LocationListItemProps) => {
  const locationName = useLocationName(locationId);

  return (
    <li data-testid="VEHICLE_LOCATION_LIST_ITEM">
      <ListItemLink
        to="location"
        leadingIcon={
          <MdOutlineLocationOn className="text-2xl text-neutral-500 mr-2" />
        }
        hasChevron
      >
        <div className="flex justify-between items-center gap-4">
          <span className="text-base font-semibold text-neutral-700 flex-1 whitespace-nowrap">
            <FormattedMessage {...messages.location} />
          </span>
          <span className="text-sm text-neutral-500 pl-2 truncate">
            {locationName || <NoLocation />}
          </span>
        </div>
      </ListItemLink>
    </li>
  );
};

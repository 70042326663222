import { classNames } from '@cooltra/utils';
import { forwardRef, HTMLAttributes } from 'react';

export type CircleProps = HTMLAttributes<HTMLDivElement>;

export const Circle = forwardRef<HTMLDivElement, CircleProps>(
  ({ className, ...rest }, ref) => (
    <div
      className={classNames(
        'flex justify-center items-center rounded-full',
        className
      )}
      ref={ref}
      {...rest}
    />
  )
);

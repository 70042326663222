import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Tags',
  },
  searchPlaceholder: {
    defaultMessage: 'Search tags',
  },
});

import { useIntl } from 'react-intl';
import { Form, FormHelpers, FormProvider } from '@cooltra/form';
import { useTaskCommentMutation } from '@cooltra/api';

import { useNotification } from '~/libs/notifications';

import { CommentField } from './CommentField';
import messages from './messages';
import { SendButton } from './SendButton';

export type CommentFormValues = {
  comment: string;
};

export const commentInitialFormValues: CommentFormValues = {
  comment: '',
};

export type CommentFormProps = {
  taskId: string;
};

export const CommentForm = ({ taskId }: CommentFormProps) => {
  const intl = useIntl();
  const { addErrorNotification } = useNotification();

  const { mutateAsync, isPending } = useTaskCommentMutation(taskId);

  const handlePostingComment = (
    values: CommentFormValues,
    { resetForm }: FormHelpers<CommentFormValues>
  ) => {
    return mutateAsync(values)
      .then(async () => {
        resetForm(commentInitialFormValues);
      })
      .catch(() => addErrorNotification());
  };

  return (
    <FormProvider<CommentFormValues>
      initialValues={commentInitialFormValues}
      onSubmit={handlePostingComment}
    >
      <Form>
        <div className="flex gap-2">
          <div className="flex-1">
            <CommentField
              disabled={isPending}
              label={intl.formatMessage(messages.comment)}
              trailingIcon={<SendButton />}
            />
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  disableAlarm: {
    defaultMessage: 'Disable alarm',
  },
  successOnDisableAlarm: {
    defaultMessage: 'Disable alarm action has been triggered successfully',
  },
});

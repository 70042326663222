import { FormattedMessage } from 'react-intl';

import { TelematicsListItem } from '../TelematicsListItem/TelematicsListItem';
import { TelematicsFieldProps } from '../types';

import messages from './messages';

const CentralStandLabel = () => (
  <span className="text-base font-semibold">
    <FormattedMessage {...messages.centralStand} />
  </span>
);

export const CentralStand = ({ vehicleStatus }: TelematicsFieldProps) => {
  if (vehicleStatus.centralStandUp === null) {
    return (
      <TelematicsListItem
        data-testid="CENTRAL_STAND"
        variant="neutral"
        title={<CentralStandLabel />}
      />
    );
  }

  if (vehicleStatus.centralStandUp.value) {
    return (
      <TelematicsListItem
        data-testid="CENTRAL_STAND"
        variant="danger"
        title={<CentralStandLabel />}
        value={
          <span className="text-sm block">
            <FormattedMessage {...messages.up} />
          </span>
        }
      />
    );
  }

  return (
    <TelematicsListItem
      data-testid="CENTRAL_STAND"
      variant="success"
      title={<CentralStandLabel />}
      value={
        <span className="text-sm block">
          <FormattedMessage {...messages.down} />
        </span>
      }
    />
  );
};

import { Vehicle } from '@cooltra/api';
import { useRouteState } from '@cooltra/navigation';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { CreateTask } from './CreateTask/CreateTask';
import { Task } from './Task/Task';
import { Tasks } from './Tasks/Tasks';
import { TaskTemplates } from './TaskTemplates/TaskTemplates';

export type VehicleTasksProps = {
  vehicle: Vehicle;
  onClose: () => void;
};

export const VehicleTasks = ({ vehicle, onClose }: VehicleTasksProps) => {
  const [comesFromMap, setComesFromMap] = useState(false);
  const routeState = useRouteState<'from'>();

  useEffect(() => {
    setComesFromMap(routeState?.from === '/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={<Tasks vehicleId={vehicle.vehicleId} onClose={onClose} />}
      />
      <Route
        path="/templates"
        element={
          <TaskTemplates vehicleId={vehicle.vehicleId} onClose={onClose} />
        }
      />
      <Route
        path="/new"
        element={
          <CreateTask
            vehicle={vehicle}
            onClose={onClose}
            comesFromMap={comesFromMap}
          />
        }
      />
      <Route
        path="/:taskId"
        element={<Task vehicle={vehicle} onClose={onClose} />}
      />
    </Routes>
  );
};

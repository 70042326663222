import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ReactGa from 'react-ga4';

export const GoogleAnalytics = () => {
  const { user } = useAuth0();

  const location = useLocation();

  useEffect(() => {
    ReactGa.initialize([
      {
        trackingId: 'G-RYW6Y8TXM1',
        gaOptions: {
          userId: user?.sub,
        },
      },
    ]);
  }, [user?.sub]);

  useEffect(() => {
    ReactGa.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location]);

  return null;
};

import { VehiclesFiltersExpression } from '../is-preset-match';

export const swapper: VehiclesFiltersExpression = {
  and: [
    { notContainOperationalCondition: 'BLOCKED' },
    { notContainOperationalCondition: 'IN_LOCATION' },
    { notContainOperationalCondition: 'RENTED' },
    { notContainOperationalCondition: 'RESERVED' },
    { batteryRange: [0, 15] },
  ],
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  comment: {
    defaultMessage: 'Comment',
  },
  addComment: {
    defaultMessage: 'Add comment',
  },
  send: {
    defaultMessage: 'Send',
  },
});

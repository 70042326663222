import { defineMessages } from 'react-intl';

export default defineMessages({
  areYouSureToCloseVehicle: {
    defaultMessage: 'Are you sure you want to close the task {taskTitle}?',
  },
  taskClosedSuccessfully: {
    defaultMessage: 'Task has been closed successfully!',
  },
  somethingWentWrong: {
    defaultMessage: 'Something went wrong',
  },
  acceptClosingTask: {
    defaultMessage: 'Yes, of course',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
});

import { useIntl } from 'react-intl';

import { BatteryIcon, SelectItem } from '~/common';
import { useVehiclesFilters } from '~/libs/vehicles-filters';

import messages from './messages';

export const UnbalancedFilter = () => {
  const { formatMessage } = useIntl();
  const { values, setValues } = useVehiclesFilters();

  const isSelected = values.unbalanced === 'true';

  const handleChange = () => {
    setValues({
      ['unbalanced']: !isSelected ? 'true' : '',
    });
  };
  return (
    <SelectItem onClick={handleChange} isSelected={isSelected}>
      <div className="flex items-center text-neutral-600 text-sm">
        <div className="w-6 h-3 mr-4">
          <BatteryIcon model="ASKOLL_ES2" charge={-100} />
        </div>
        {formatMessage(messages.unbalanced)}
      </div>
    </SelectItem>
  );
};

import { Expression } from 'mapbox-gl';

export const getCircleColorExpressionByOperationalState = (): Expression => [
  'case',
  ['==', ['get', 'isRented'], true],
  '#0967D3',
  ['==', ['get', 'isReserved'], true],
  '#49A4F3',
  ['==', ['get', 'vehicleOperationalState'], 'OPERATIONAL'],
  '#189A1C',
  ['==', ['get', 'vehicleOperationalState'], 'IN_MAINTENANCE'],
  '#F0B428',
  ['==', ['get', 'vehicleOperationalState'], 'NOT_OPERATIONAL'],
  '#E12D39',
  '#94A5B8',
];

import { MdClose } from 'react-icons/md';
import { useIntl } from 'react-intl';

import { IconButton, IconButtonProps } from '../../InteractiveIcon';

import messages from './messages';

export type CloseProps = Pick<IconButtonProps, 'onClick'>;

export const Close = (props: CloseProps) => {
  const { formatMessage } = useIntl();
  return (
    <IconButton
      aria-label={formatMessage(messages.close)}
      className="h-full px-4"
      {...props}
    >
      <MdClose />
    </IconButton>
  );
};

import { useToggle } from '@cooltra/hooks';
import { FormattedMessage } from 'react-intl';
import { Button } from '@cooltra/ui';

import { Overlay } from '~/common';

import * as Actions from '../Actions';

import messages from './messages';

export const FloatingMenu = () => {
  const [isOpen, { toggleOff, toggle }] = useToggle();

  return (
    <>
      <Overlay isOpen={isOpen} onClick={toggleOff}>
        <div
          className="h-full flex flex-col justify-between items-center pb-36"
          data-testid="MY_TAKEN_VEHICLES_ACTIONS"
        >
          <h3 className="text-lg font-semibold text-neutral-0 mt-4">
            <FormattedMessage {...messages.actions} />
          </h3>
          <div className="flex flex-col gap-4 items-center">
            <Actions.TransferAll />
            <Actions.ReleaseAll onReleaseSuccessfullyAll={toggleOff} />
          </div>
        </div>
      </Overlay>
      <div className="bottom-5 absolute right-1/2 translate-x-1/2 flex gap-2">
        <Button
          emphasis="high"
          className="rounded-full shadow-xl z-30"
          onClick={toggle}
        >
          <FormattedMessage {...messages.actions} />
        </Button>
      </div>
    </>
  );
};

import { Input } from '@cooltra/ui';
import { useField, useFormContext } from '@cooltra/form';
import { useIntl } from 'react-intl';
import { ReactNode } from 'react';

import messages from './messages';

export type CommentFieldProps = {
  label: string;
  disabled: boolean;
  trailingIcon: ReactNode;
};

export const CommentField = ({
  label,
  disabled,
  trailingIcon,
}: CommentFieldProps) => {
  const { formatMessage } = useIntl();
  const { isSubmitting } = useFormContext();
  const { value, setValue } = useField('comment');

  return (
    <Input
      aria-label={label}
      placeholder={formatMessage(messages.addComment)}
      onChange={({ target: { value } }) => setValue(value)}
      trailingIcon={trailingIcon}
      value={value}
      disabled={disabled || isSubmitting}
    />
  );
};

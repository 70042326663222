import { FormattedMessage } from 'react-intl';
import { FormNativeSelectField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';

import { operatorGroups } from '~/utils/operator-groups';

import messages from './messages';

export const Assignee = () => (
  <FormNativeSelectField
    name="operatorGroupId"
    id="operator-group-id"
    label={
      <InputLabel htmlFor="operator-group-id">
        <FormattedMessage {...messages.assignee} />
      </InputLabel>
    }
    options={[
      { label: '', value: '' },
      ...Object.values(operatorGroups).map(({ name, operatorGroupId }) => ({
        label: name,
        value: operatorGroupId,
      })),
    ]}
  />
);

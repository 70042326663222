import { systemGeolocations } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { PropsWithChildren, useMemo, useState } from 'react';
import { MapProvider } from 'react-map-gl';

import { isTestingJestEnv } from '~/utils/e2e';

import { MapCenterContext, BaseViewState } from './MapCenterContext';

export const MapCenterProvider = ({ children }: PropsWithChildren) => {
  const { data: claims } = useAuthClaimsQuery();
  const system = claims?.systems[0] || 'Barcelona';

  const { latitude, longitude } =
    systemGeolocations[system] || systemGeolocations.Barcelona;

  const [viewState, updateViewState] = useState<BaseViewState>({
    longitude,
    latitude,
    zoom: 16,
  });

  const value = useMemo(
    () => ({
      viewState,
      updateViewState,
    }),
    [viewState]
  );

  if (isTestingJestEnv()) {
    return <>{children}</>;
  }

  return (
    <MapProvider>
      <MapCenterContext.Provider value={value}>
        {children}
      </MapCenterContext.Provider>
    </MapProvider>
  );
};

import { classNames } from '@cooltra/utils';
import { RouterBaseLink, RouterBaseLinkProps } from '@cooltra/navigation';

export const RoundRouterLink = ({
  className,
  ...rest
}: RouterBaseLinkProps) => (
  <RouterBaseLink
    className={classNames(
      'bg-neutral-0 shadow-md absolute rounded-full z-20 w-12 h-12 overflow-hidden',
      className
    )}
    {...rest}
  />
);

import { useAuth0 } from '@auth0/auth0-react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useVehiclesFilters } from '~/libs/vehicles-filters';
import {
  GroupedMultiSelect,
  MultiSelectGroupOption,
  SelectItemText,
  TopNavigation,
} from '~/common';
import { operatorGroups } from '~/utils/operator-groups';

import { FilterRouteProps } from '../types';

import messages from './messages';

export const AssigneeFilter = ({ onClose, onBack }: FilterRouteProps) => {
  const { formatMessage } = useIntl();

  const { values, setValues } = useVehiclesFilters();

  const { user } = useAuth0();

  const handleChange = (
    selection: MultiSelectGroupOption<string, string>[]
  ) => {
    setValues({
      preset: undefined,
      taskOperatorId: selection
        .filter(({ group }) => group === 'taskOperatorId')
        .map(({ value }) => value),
      taskOperatorGroupId: selection
        .filter(({ group }) => group === 'taskOperatorGroupId')
        .map(({ value }) => value),
    });
  };

  return (
    <GroupedMultiSelect
      onBack={onBack}
      onClose={onClose}
      value={[...values.taskOperatorId, ...values.taskOperatorGroupId]}
      onChange={handleChange}
      searchPlaceholder={formatMessage(messages.searchPlaceholder)}
      title={
        <TopNavigation.Title>
          <FormattedMessage {...messages.title} />
        </TopNavigation.Title>
      }
      renderOption={({ label }) => <SelectItemText>{label}</SelectItemText>}
      renderGroupLabel={({ label }) => (
        <span className="block px-4 py-2 text-neutral-500 text-xs border-b border-neutral-100 uppercase bg-neutral-50">
          {label}
        </span>
      )}
      options={[
        {
          label: formatMessage(messages.operators),
          group: 'taskOperatorId',
          options: [
            {
              label: 'Me',
              value: user?.sub || '',
              group: 'taskOperatorId',
            },
          ],
        },
        {
          label: formatMessage(messages.operatorGroups),
          group: 'taskOperatorGroupId',
          options: Object.values(operatorGroups).map(
            ({ name, operatorGroupId }) => ({
              label: name,
              value: operatorGroupId,
              group: 'taskOperatorGroupId',
            })
          ),
        },
      ]}
    />
  );
};

import { createRoot } from 'react-dom/client';
import { init } from '@sentry/react';

import { getEnvVariable } from '~/utils/environment';

import App from './components/App/App';
import { isTestingEnv } from './utils/e2e';

if (
  getEnvVariable('SENTRY_DSN') &&
  getEnvVariable('NODE_ENV') !== 'development' &&
  !isTestingEnv()
) {
  init({
    dsn: getEnvVariable('SENTRY_DSN'),
    environment: getEnvVariable('ENVIRONMENT'),
    // integrations: [new Replay()],
    // replaysOnErrorSampleRate: 0.2,
    ignoreErrors: ['Non-Error promise rejection captured'],
  });
}

const container = document.getElementById('app');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(<App />);

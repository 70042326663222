import { useLocationsQuery } from '@cooltra/api';

import { useUserSystems } from './useUserSystems';

export const useLocationName = (locationId: string) => {
  const systems = useUserSystems();

  const { data: locations } = useLocationsQuery(
    { system: systems },
    { enabled: false }
  );

  return locations?.find((location) => location.locationId === locationId)
    ?.name;
};

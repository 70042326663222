import { VehicleStatus } from '@cooltra/api';
import { System } from '@cooltra/auth-api';
import { MdOpenInNew } from 'react-icons/md';
import { Icon } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';

import { isTestingEnv } from '~/utils/e2e';

import messages from './messages';
import { useReverseGeocode } from './useReverseGeocodeQuery';

export type GoogleMapsLinkProps = {
  system: System;
  className?: string;
} & Pick<VehicleStatus, 'geoLocation'>;

export const GoogleMapsLink = ({
  system,
  className,
  geoLocation,
}: GoogleMapsLinkProps) => {
  const latitude = geoLocation?.latitude || 0;
  const longitude = geoLocation?.longitude || 0;

  const hasGeolocation = !!longitude && !!latitude;

  const { data: address } = useReverseGeocode(
    { latitude, longitude },
    { enabled: hasGeolocation && !isTestingEnv() }
  );

  const href = hasGeolocation
    ? system === 'Paris'
      ? `https://www.waze.com/ul?ll=${latitude},${longitude}&navigate=yes&zoom=17`
      : `https://maps.google.com/maps?daddr=${latitude},${longitude}`
    : undefined;

  return (
    <a
      className={classNames(
        'font-semibold text-sm max-w-full',
        href ? 'text-primary-500' : 'text-neutral-300',
        className
      )}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {address ? (
        <span className="flex max-w-full">
          <span className="block truncate max-w-full">{address}</span>
          <Icon className="text-lg ml-1">
            <MdOpenInNew />
          </Icon>
        </span>
      ) : (
        <>
          <FormattedMessage {...messages.googleMaps} />
          <Icon className="text-lg ml-1">
            <MdOpenInNew />
          </Icon>
        </>
      )}
    </a>
  );
};

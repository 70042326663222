import { classNames } from '@cooltra/utils';
import { RouterBaseLink, RouterBaseLinkProps } from '@cooltra/navigation';

export const RoundRouterLink = ({
  className,
  ...rest
}: RouterBaseLinkProps) => (
  <RouterBaseLink
    className={classNames(
      'absolute z-20 w-12 h-12 rounded-full bg-neutral-50 border-2 border-neutral-0 flex items-center justify-center font-bold text-neutral-600',
      className
    )}
    {...rest}
  />
);

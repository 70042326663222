import { defineMessages } from 'react-intl';

export default defineMessages({
  singleRoleTitle: {
    defaultMessage: '{operatorGroupName} tasks ({total})',
  },
  multipleRolesTitle: {
    defaultMessage: 'Group tasks ({total})',
  },
});

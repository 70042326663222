import { TopLoadingBar } from '@cooltra/ui';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTaskTemplatesQuery } from '@cooltra/api';
import { useNavigate } from 'react-router-dom';
import { RouterButton } from '@cooltra/navigation';

import {
  Screen,
  TopNavigation,
  ClearableInput,
  NoSearchResults,
  ListItemButton,
  Error,
} from '~/common';
import { useUserSystems } from '~/hooks';

import messages from './messages';

export type TaskTemplatesProps = {
  onClose: () => void;
  vehicleId: string;
};

export const TaskTemplates = ({ onClose, vehicleId }: TaskTemplatesProps) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const systems = useUserSystems();
  const {
    isLoading,
    data = [],
    isError,
  } = useTaskTemplatesQuery({
    // use the first home system because should be the same for the HomeSystems of the role
    system: systems[0],
    type: 'task',
  });

  const [searchInput, setSearchInput] = useState('');

  const filteredTemplates = data.filter(({ title }) =>
    title.toLowerCase().includes(searchInput.toLowerCase())
  );

  const onBack = () => navigate(`/vehicles/${vehicleId}/tasks`);

  const selectTemplate = (title: string) => () =>
    navigate(`/vehicles/${vehicleId}/tasks/new`, {
      state: {
        title,
      },
    });

  return (
    <Screen
      header={
        <>
          {isLoading && <TopLoadingBar />}
          <div className="bg-neutral-0 border-b border-neutral-100">
            <TopNavigation.Bar
              renderLeft={<TopNavigation.Back onClick={onBack} />}
              renderRight={<TopNavigation.Close onClick={onClose} />}
              title={
                <TopNavigation.Title>
                  <FormattedMessage {...messages.heading} />
                </TopNavigation.Title>
              }
            />
            {!isError && (
              <div className="px-4 pb-4 pt-1">
                <ClearableInput
                  aria-label={formatMessage(messages.label)}
                  placeholder={formatMessage(messages.placeholder)}
                  autoFocus
                  value={searchInput}
                  onChange={setSearchInput}
                />
              </div>
            )}
          </div>
        </>
      }
      content={
        <>
          {!isError && !isLoading && !filteredTemplates.length && (
            <div className="text-center">
              <NoSearchResults />
              <RouterButton
                className="rounded-full"
                to={`/vehicles/${vehicleId}/tasks/new`}
                state={{
                  title: searchInput,
                }}
              >
                <FormattedMessage {...messages.addTask} />
              </RouterButton>
            </div>
          )}
          {isError && <Error />}
          <ul className="pb-20">
            {filteredTemplates.map((template) => (
              <li key={template.title}>
                <ListItemButton
                  hasTapHighlight
                  onClick={selectTemplate(template.title)}
                  data-testid={template.title}
                >
                  <span className="text-neutral-600 text-sm text-wrap">
                    {template.title}
                  </span>
                </ListItemButton>
              </li>
            ))}
          </ul>
        </>
      }
    />
  );
};

import { useState } from 'react';
import {
  FetchTasksRequestParams,
  TaskListItem,
  useTasksQuery,
  useTasksTotal,
} from '@cooltra/api';
import { InfiniteList, TopLoadingBar } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useToggle } from '@cooltra/hooks';
import { useAuth0 } from '@auth0/auth0-react';

import {
  TopNavigation,
  Screen,
  Error,
  TaskCard,
  CloseTaskModal,
  NoTasksFound,
} from '~/common';

import messages from './messages';

export type MyTasksProps = {
  onClose: () => void;
};

export const MyTasks = ({ onClose }: MyTasksProps) => {
  const { user } = useAuth0();
  const navigate = useNavigate();

  const [isOpen, { toggleOff, toggleOn }] = useToggle();
  const [selectedTask, selectTask] = useState('');

  const params: FetchTasksRequestParams = {
    open: 'true',
    operatorId: user?.sub || '',
  };
  const { data, isLoading, fetchNextPage, isFetchingNextPage, isError } =
    useTasksQuery(params);

  const tasks = data?.pages
    ? ([] as TaskListItem[]).concat.apply([], data.pages)
    : [];

  const tasksTotal = useTasksTotal(params) || 0;

  const handleDeselectingTask = () => selectTask('');

  const handleSelectingTask = (taskId: string) => {
    toggleOn();
    selectTask(taskId);
  };

  const goBack = () => navigate('/profile');

  const onTaskCloseSuccess = () => navigate('/profile/tasks');

  const renderContent = () => {
    if (isError) {
      return <Error />;
    }

    return (
      <div className="pb-16 overflow-x-hidden">
        <InfiniteList
          className="gap-3"
          loadingRows={0}
          hasMore={tasks.length < tasksTotal}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          fetchMore={fetchNextPage}
          idProp="id"
          data={tasks}
          scrollableTarget="scrollable-task-list"
          renderEmptyMessage={<NoTasksFound />}
          renderRow={(task) => (
            <TaskCard
              to={`/profile/tasks/${task.id}`}
              onCloseTask={handleSelectingTask}
              task={task}
            />
          )}
        />
      </div>
    );
  };

  return (
    <>
      <CloseTaskModal
        onSuccess={onTaskCloseSuccess}
        isOpen={isOpen}
        onClose={toggleOff}
        onAfterClose={handleDeselectingTask}
        taskId={selectedTask}
      />
      <Screen
        contentId="scrollable-task-list"
        data-testid="MY_TASKS_SCREEN"
        header={
          <>
            {isLoading && <TopLoadingBar />}
            <div className="bg-neutral-0 border-b border-neutral-100">
              <TopNavigation.Bar
                renderLeft={<TopNavigation.Back onClick={goBack} />}
                renderRight={<TopNavigation.Close onClick={onClose} />}
                title={
                  <TopNavigation.Title>
                    <FormattedMessage
                      {...messages.title}
                      values={{ total: tasksTotal }}
                    />
                  </TopNavigation.Title>
                }
              />
            </div>
          </>
        }
        content={renderContent()}
      />
    </>
  );
};

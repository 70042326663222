import { Button, Modal, ModalProps } from '@cooltra/ui';
import {
  useLocationsQuery,
  useMyTakenVehicles,
  useTransferVehiclesMutation,
} from '@cooltra/api';
import { AxiosError } from 'axios';
import { FormattedMessage, useIntl } from 'react-intl';

import { useNotification } from '~/libs/notifications';
import { useUserSystems } from '~/hooks';

import messages from './messages';
import { getErrorMessageForMovingVehicles } from './errors';

export type ConfirmationModalProps = Pick<ModalProps, 'isOpen'> & {
  newLocationId: string;
  closeModal: () => void;
  onFinishSuccessfully: () => void;
};

export const ConfirmationModal = ({
  isOpen,
  newLocationId,
  closeModal,
  onFinishSuccessfully,
}: ConfirmationModalProps) => {
  const systems = useUserSystems();
  const { formatMessage } = useIntl();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const { data: myVehiclesTakenIds, isLoading: isMyTakenVehiclesLoading } =
    useMyTakenVehicles();
  const { mutate, isPending } = useTransferVehiclesMutation();
  const { data: locations } = useLocationsQuery(
    { system: systems },
    { enabled: false }
  );

  const selectedLocation = locations?.find(
    (location) => location.locationId === newLocationId
  );

  const transferAllToNewLocation = () => {
    const handleOnError = (error: AxiosError) => {
      const errorMessageFromStatusCode = getErrorMessageForMovingVehicles(
        error.response
      );

      if (!errorMessageFromStatusCode) {
        addErrorNotification();
        return;
      }

      addErrorNotification(formatMessage(errorMessageFromStatusCode));
    };

    const handleOnSuccess = () => {
      addSuccessNotification(
        formatMessage(messages.locationChangedSuccessfully)
      );
      onFinishSuccessfully();
    };

    mutate(
      {
        vehicleIds: myVehiclesTakenIds || [],
        locationId: newLocationId,
        ignoreTakenVehicles: false,
      },
      {
        onError: handleOnError,
        onSuccess: handleOnSuccess,
        onSettled: closeModal,
      }
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      shouldReturnFocusAfterClose={false}
      hideHeader
      className="mx-10 rounded-3xl"
    >
      <div className="py-10 p-7" data-testid="TRANSFER_ALL_CONFIRMATION_MODAL">
        <p className="mb-7 text-center">
          <FormattedMessage
            {...messages.moveVehicle}
            values={{
              allTakenVehicles: (
                <span className="font-semibold">
                  <FormattedMessage {...messages.allTakenVehicles} />
                </span>
              ),
              destination: (
                <span className="font-semibold">{selectedLocation?.name}</span>
              ),
            }}
          />
        </p>
        <div className="flex flex-col gap-5">
          <Button
            loading={isMyTakenVehiclesLoading || isPending}
            className="rounded-full"
            onClick={transferAllToNewLocation}
            emphasis="high"
          >
            <FormattedMessage {...messages.changeLocation} />
          </Button>
          <Button
            className="rounded-full"
            onClick={closeModal}
            emphasis="medium"
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

import { useHonkMutation, useWorkUnitQuery } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { useCallback, useEffect } from 'react';
import { distanceTo } from 'geolocation-utils';

import { useCurrentPosition } from '~/libs/current-position';
import { useWorkUnitVehicle } from '~/hooks';
import { useAutomaticHonk } from '~/libs/automatic-honk';

export const AutomaticHonk = () => {
  const { hasHonked, setHasHonked } = useAutomaticHonk();

  const currentPosition = useCurrentPosition();
  const { data: workUnit } = useWorkUnitQuery({
    enabled: false,
  });
  const workUnitVehicle = useWorkUnitVehicle();

  const { hasPermission } = useAuthClaimsQuery();

  const { mutate } = useHonkMutation(workUnit ? workUnit.vehicleId : '');

  const isWithin20Meters = useCallback(
    () =>
      distanceTo(
        {
          longitude: currentPosition[0],
          latitude: currentPosition[1],
        },
        {
          longitude: workUnitVehicle.geoLocation[0],
          latitude: workUnitVehicle.geoLocation[1],
        }
      ) < 20,
    [currentPosition, workUnitVehicle]
  );

  useEffect(() => {
    if (
      workUnit &&
      !workUnitVehicle.isBike &&
      workUnitVehicle.geoLocation.length &&
      currentPosition.length &&
      !hasHonked &&
      isWithin20Meters()
    ) {
      setHasHonked(true);
      mutate();
    }
  }, [
    currentPosition.length,
    hasHonked,
    hasPermission,
    isWithin20Meters,
    mutate,
    setHasHonked,
    workUnit,
    workUnitVehicle,
  ]);

  return null;
};

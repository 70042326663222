import { defineMessages } from 'react-intl';

export default defineMessages({
  online: {
    defaultMessage: 'Connected',
  },
  offline: {
    defaultMessage: 'Offline',
  },
  unknown: {
    defaultMessage: 'Unknown',
  },
});
